import React, { useEffect } from 'react'
import OfferVoucherissuedRedeemed from './OfferVoucherissuedRedeemed'
import OfferstatsVenderResponsive from './OfferstatsVenderResponsive'
import { offerStatsTAB } from '../../Redux/Slice/Offer';
import { useDispatch } from 'react-redux';

function OfferStatsInnerPage() {
    const dispatch = useDispatch();
  
    useEffect(() => {
      dispatch(offerStatsTAB("redeemed"));
    }, []);
    return (
        <>
            <section className='offerstatsinnerPage'>
                <div className='cust_container'>
                    <OfferstatsVenderResponsive />
                    <OfferVoucherissuedRedeemed />
                </div>
            </section>
        </>
    )
}

export default OfferStatsInnerPage