import React, { useRef, useState } from 'react'
import crosssimgiocn from "../../src/Images/crosssimgiocn.png";
import threedowttsimhgicon from "../../src/Images/threedowttsimhgicon.png";
import beqautyproproduct from "../../src/Images/beqautyproproduct.png";
import prodlikeicon from "../../src/Images/prodlikeicon.png";
import commenytstproodiuificon from "../../src/Images/commenytstproodiuificon.png";
import cmtprofileoneimg from "../../src/Images/cmtprofileoneimg.png";
import cmtprofiletwoimg from "../../src/Images/cmtprofiletwoimg.png";
import msgiocn from "../../src/Images/msgiocn.png"
const Otherproductmodal = ({ closemodal }) => {
    const divRef = useRef(null);
    const [text, setText] = useState("");
    const [commentboxmodal, setCommentboxmodal] = useState(false);
    const [loadmoremodala,setLoadmoremodala]=useState(false);
    const commentsboxoscopemnn = () => {
        setCommentboxmodal(!commentboxmodal);
    }
    const lodamoisrrdedivbmodal=()=>{
        setLoadmoremodala(!loadmoremodala)
    }
    return (
        <div className='otherprofudvtmodalbackbox'>
            <div className='otherprofileproductbgmain'>

                <div className='threedorttsdcoroodivflx'>
                    <div className='imnmnproduucttdthghecrs'>
                        <img src={threedowttsimhgicon} alt='...' />
                    </div>
                    <div className='imnmnproduuccrtisocross' onClick={() => closemodal()}>
                        <img src={crosssimgiocn} alt='...' />
                    </div>
                </div>

                <div className='produtitleimggsdescrdiv'>
                    <p className='breyuuayproosttxppp'>Beauty Product</p>

                    <div className='beaiuttisprofuductimh'>
                        <img src={beqautyproproduct} alt='...' />
                    </div>

                    <div className=''>
                        <p className='producttdescttxpppyip'>Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies, drive more connections, and build new networks and leads. Neoo search business netwrok lets you discover and access a whole new world of business opportunities from across the world. Having a business of our own, we know the value of being able to share tips and information, develop relationships with other businesses, and have somewhere to look for information.</p>
                    </div>

                    <div className=''>
                        <p className='timttksopooiisuttxpp'>
                            1 hour age
                        </p>
                    </div>
                </div>
                <div className={commentboxmodal ? 'heijggsctrdjpprodfuiiciv actv' : "heijggsctrdjpprodfuiiciv"}>
                    <div className='producudtlikecomrnttsennsuiquerydiv'>
                        <div className='prodctlikecmntenquerydiv'>
                            <div className='produucvtsdlikeinmncomnfdivvhjjd'>
                                <div className='produucvtsdlikeinmncomnf'>
                                    <img src={prodlikeicon} alt='' />

                                </div>
                                <p className='likkskjsbtcomnbvtyoipuxtt'>Like <span>100</span></p>
                            </div>

                            <div className='produucvtsdlikeinmncomnfdivvhjjd' onClick={commentsboxoscopemnn}>
                                <div className='produucvtsdlikeinmncomnf'>
                                    <img src={commenytstproodiuificon} alt='' />

                                </div>
                                <p className='likkskjsbtcomnbvtyoipuxtt'>Comment </p>
                            </div>


                            <div className='produucvtsdlikeinmncomnfdivvhjjd'>
                                <div className='produucvtsdlikeinmncomnf'>
                                    <img src={msgiocn} alt='' />

                                </div>
                                <p className='likkskjsbtcomnbvtyoipuxtt'>Send Equiry </p>
                            </div>
                        </div>
                    </div>

                    {commentboxmodal && <div className='producytppostcommnentdiv'>
                        <div className='commuisonnporttsidivrrls'>
                            <div
                                ref={divRef}
                                contentEditable
                                className='textardibbrdrdivmain'

                            >
                                {text}
                            </div>

                            <div className='posyynbmrmodiuvvmain'>
                                <button className='posyynbmrmodiuvsujusvbntn'>Post</button>
                            </div>

                        </div>
                    </div>}

                    <div className='commenytstthowitxtpehht'>
                        <div className='commentshpwfrsttcmontdiv'>
                            <div className='profikellcmnjhhrrddivflxttst'>
                                <div className='profikellcmntimgdivflxttst'>
                                    <div className='commentproofilleimg'>
                                        <img src={cmtprofileoneimg} alt='...' />
                                    </div>
                                    <p className='commporosttxttnamepp'>John Doe</p>
                                </div>
                                <p className='commporosttxttnamepp'>1 hour ago</p>
                            </div>
                            <p className='commsoprpodttctxppp'>
                                I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                            </p>
                        </div>
                        <div className='commentshpwfrsttcmontdiv'>
                            <div className='profikellcmnjhhrrddivflxttst'>
                                <div className='profikellcmntimgdivflxttst'>
                                    <div className='commentproofilleimg'>
                                        <img src={cmtprofiletwoimg} alt='...' />
                                    </div>
                                    <p className='commporosttxttnamepp'>John Doe</p>
                                </div>
                                <p className='commporosttxttnamepp'>1 hour ago</p>
                            </div>
                            <p className='commsoprpodttctxppp'>
                                I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                            </p>
                        </div>

                        {loadmoremodala &&  <div className=''>
                            <div className='commentshpwfrsttcmontdiv'>
                                <div className='profikellcmnjhhrrddivflxttst'>
                                    <div className='profikellcmntimgdivflxttst'>
                                        <div className='commentproofilleimg'>
                                            <img src={cmtprofileoneimg} alt='...' />
                                        </div>
                                        <p className='commporosttxttnamepp'>John Doe</p>
                                    </div>
                                    <p className='commporosttxttnamepp'>1 hour ago</p>
                                </div>
                                <p className='commsoprpodttctxppp'>
                                    I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                </p>
                            </div>
                            <div className='commentshpwfrsttcmontdiv'>
                                <div className='profikellcmnjhhrrddivflxttst'>
                                    <div className='profikellcmntimgdivflxttst'>
                                        <div className='commentproofilleimg'>
                                            <img src={cmtprofiletwoimg} alt='...' />
                                        </div>
                                        <p className='commporosttxttnamepp'>John Doe</p>
                                    </div>
                                    <p className='commporosttxttnamepp'>1 hour ago</p>
                                </div>
                                <p className='commsoprpodttctxppp'>
                                    I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                </p>
                            </div>
                        </div>}

                        <button className='lodmrebtnmainndiv' onClick={lodamoisrrdedivbmodal}>Load More</button>

                       

                    </div>

                </div>


            </div>
        </div>
    )
}

export default Otherproductmodal
