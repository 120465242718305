import React from 'react'

const Otherservicesblmprofile = () => {
    return (
        <div className='uploadbannerblmdiv'>
            <div className='uploadbannerdivnewmain'>
                <div className="consttactdetatlsarroedyrdiv">
                    <p className="cinnattddttdtsttspo">Services</p>
                 
                </div>
                <div className='adversytfservicexedib'>
                    <span className='servicdesdetatcxttxpp'>
                        Advertising
                    </span>
                    <span className='servicdesdetatcxttxpp'>
                        Content Strategy
                    </span>
                    <span className='servicdesdetatcxttxpp'>
                        Event Marketing
                    </span>
                    <span className='servicdesdetatcxttxpp'>
                        Brand Marketing
                    </span>
                    <span className='servicdesdetatcxttxpp'>
                        Advertising
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Otherservicesblmprofile
