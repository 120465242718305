import React, { useState } from "react";
import Frame from "../../Images/Frame.png";
import framelike from "../../Images/framelike.png";
import profileimg from "../../Images/profileimg.png";
import whatsapp from "../../Images/whatsapp.png";
import "./Memberownprofile.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Rectangle from "../../Images/Rectangle.png";
import prdct from "../../Images/prdct.png";
import pdf from "../../Images/pdf.png";
import coverpic from "../../Images/cover img.jpg";
import ProductModal from "../../Modal/ProductModal";
import QRshareModal from "../../Modal/QRshareModal";
import ProfileShare from "../../Modal/ProfileShare";
import HttpClient from "../../utils/HttpClient";
import { useEffect } from "react";
import CustomLoader from "../../CustomComponents/loader/CustomLoader";
import ReactPlayer from "react-player";
import { WhatsappShareButton } from "react-share";
import {
  HitLike,
  getOwnProfile,
  getSingleUserProfile,
} from "../../Redux/Slice/PostSlice";
import { useDispatch, useSelector } from "react-redux";
import verified from "../../Images/Verify responsive.png";
import GiveRattingModal from "../../Modal/GiveRattingModal";
import StarRatings from "react-star-ratings";
import tticon from "../../Images/taptorate.png";
import call from "../../Images/Call.png";
import likec from "../../Images/like/like-c.png";
import likew from "../../Images/like/like-w.png";
import toast from "react-hot-toast";
import { OnetoOneChatIdname } from "../../Redux/Slice/Chat";
import Messageicon from "../../Images/Message-icon.png";
import { child, get, ref, set } from "firebase/database";
import { db } from "../../Firebase/FirebaseConfig";

const MemberOtherSavedProfile = () => {
  const navigate = useNavigate();
  const [giveratting, setGiveratting] = useState(false);
  const popupclick = () => {
    setGiveratting(!giveratting);
  };
  const [UserMemberData, setUserMemberData] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [contactdetail, setContactdetail] = useState(true);
  const [aboutdetail, setAboutdetail] = useState(true);
  const [servicedetail, setServicedetail] = useState(true);
  const [productdetail, setProductdetail] = useState(true);
  const [servicepdfdetail, setServicepdfdetail] = useState(true);
  const [modala, setModala] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [sharemodal, setSharemodal] = useState(false);
  const [ProductItem, setProductItem] = useState("");
  const [savedContacts, setsavedContacts] = useState([]);
  const [videoPlayed, setVideoPlayed] = useState(false);

  const dispatch = useDispatch();
  const { SingleUserData, isTilted, UserProfileID } = useSelector(
    (state) => state.app
  );
  const location = useLocation();
  const params = useParams();
  const whatsappNumber =
    SingleUserData?.[0]?.whats_code + SingleUserData?.[0]?.whatsappNo;
  const message = "Hi, I am Using Neoo Search Platform."; // Replace with your desired message

  console.log(whatsappNumber, "whatsappNumber");
  // Encode the message and WhatsApp number for URL
  const encodedMessage = encodeURIComponent(message);
  const encodedWhatsAppNumber = encodeURIComponent(whatsappNumber);

  // Construct the WhatsApp URL with phone number and message
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodedWhatsAppNumber}&text=${encodedMessage}`;

  // variable for points for api start
  const whatsappPoint = {
    profileId: SingleUserData?.[0]?._id,
    type: "whatsapp",
  };
  const callPoint = {
    profileId: SingleUserData?.[0]?._id,
    type: "call",
  };
  const pdfPoint = {
    profileId: SingleUserData?.[0]?._id,
    type: "pdf",
  };
  const websiteVisitPoint = {
    profileId: SingleUserData?.[0]?._id,
    type: "websiteVisit",
  };
  const socialMediaPoint = {
    profileId: SingleUserData?.[0]?._id,
    type: "socialMedia",
  };
  const productViewPoint = {
    profileId: SingleUserData?.[0]?._id,
    type: "productView",
  };
  const profileVideoPoint = {
    profileId: SingleUserData?.[0]?._id,
    type: "profileVideo",
  };
  // variable for points for api end

  // console.log(SingleUserData?.[0], "SingleUserData");
  // console.log(whatsappPoint, "whatsappPoint");
  // console.log(isTilted, "isTilted");
  // console.log(location?.state?._id === SingleUserData?.[0]?._id, "location");
  console.log(location?.state, "locationstate");
  // console.log(JSON.stringify(params), "params");

  const handledetailClick = () => {
    setContactdetail(!contactdetail);
  };
  const handleaboutClick = () => {
    setAboutdetail(!aboutdetail);
  };
  const handleserviceClick = () => {
    setServicedetail(!servicedetail);
  };
  const handleproductClick = () => {
    setProductdetail(!productdetail);
  };
  const handleservicepdfClick = () => {
    setServicepdfdetail(!servicepdfdetail);
  };
  const handlemodalClick = async (item) => {
    if (SingleUserData?.[0]?._id) {
      const res = await HttpClient.requestData(
        "getting-rewards",
        "POST",
        productViewPoint
      );
      if (res && res?.status) {
        toast.success("Viewing Product...");
      } else {
        console.log("Cant add points");
      }
    }
    setModala(!modala);
    setProductItem(item);
  };
  const modhandleclick = () => {
    setShowmodal(!showmodal);
  };
  const shareClick = () => {
    setSharemodal(!sharemodal);
  };

  const getUserProfile = async () => {
    setisLoading(true);
    const res = await HttpClient.requestData("get-profile", "GET", {});
    console.log(res, "user profile");
    if (res && res?.status) {
      setisLoading(false);
      setUserMemberData(res?.data);
    } else {
      setisLoading(false);
    }
  };
  //Saved contactlist
  const savedContactList = async () => {
    const res = await HttpClient.requestData("member-contacts", "GET", {});
    console.log(res, "resData");
    if (res && res?.status) {
      setsavedContacts(res?.data);
    }
  };

  // whatsApp Point
  const handleWhatsappClick = async () => {
    if (SingleUserData?.[0]?._id) {
      const res = await HttpClient.requestData(
        "getting-rewards",
        "POST",
        whatsappPoint
      );
      if (res && res?.status) {
        toast.success("Redirecting to the WhatsApp App...");
      } else {
        console.log("Cant add points");
      }

      window.open(whatsappUrl, "_blank");
    }
  };

  // Call point
  const handleButtonClick = async () => {
    if (SingleUserData?.[0]?._id) {
      const res = await HttpClient.requestData(
        "getting-rewards",
        "POST",
        callPoint
      );
      if (res && res?.status) {
        toast.success("Calling the user...");
      } else {
        console.log("Cant add points");
      }
      window.location.href = `tel:${SingleUserData?.[0]?.phone_code}${SingleUserData?.[0]?.mobileNo}`;
    }
  };
  //doc pdf point
  const DocPointButton = async (item) => {
    if (SingleUserData?.[0]?._id) {
      const res = await HttpClient.requestData(
        "getting-rewards",
        "POST",
        pdfPoint
      );
      if (res && res?.status) {
        toast.success("Dowloading Document...");
      } else {
        console.log("Cant add points");
      }

      window.open(item, "_blank");
    }
  };
  // website Visit point
  const websiteVisitPointGive = async () => {
    if (SingleUserData?.[0]?._id) {
      const res = await HttpClient.requestData(
        "getting-rewards",
        "POST",
        websiteVisitPoint
      );
      if (res && res?.status) {
        toast.success("Dowloading Document...");
      } else {
        console.log("Cant add points");
      }

      window.open(SingleUserData?.[0]?.websiteLink, "_blank");
    }
  };
  //Scocial media points
  const SocialMediaPointGive = async (item) => {
    console.log(item, "socialMediaPoint");
    if (SingleUserData?.[0]?._id) {
      const res = await HttpClient.requestData(
        "getting-rewards",
        "POST",
        socialMediaPoint
      );
      if (res && res?.status) {
        toast.success("Visiting site...");
      } else {
        console.log("Cant add points");
      }

      window.open(item, "_blank");
    }
  };

  //Video point
  const handleVideoPlay = () => {
    setVideoPlayed(true);
  };

  const handleVideoPlaypoint = async () => {
    const res = await HttpClient.requestData(
      "getting-rewards",
      "POST",
      profileVideoPoint
    );
    if (res && res?.status) {
      toast.success("Video started playing!");
    } else {
      console.log("Cant add points");
    }
  };

  // give likes
  // give likes
  const hitLike = async (id) => {
    const data = {
      id: SingleUserData?.[0]?._id,
    };
    // Dispatch HitLike action to update like count
    await dispatch(HitLike(data));

    // Dispatch action to fetch single user profile
    dispatch(getSingleUserProfile(id));
  };

  const chkChatlistAndCreate = () => {
    dispatch(OnetoOneChatIdname(SingleUserData?.[0]?._id));
    navigate("/chat");
  };

  // custom icon for like if already liked
  const CustomIcon = () => (
    <img
      src={likec} // Use the imported image
      alt="Thumbs up"
      style={{
        marginRight: "5px",
        width: "20px", // Set the width of the image
        height: "20px", // Set the height of the image
        // backgroundColor: '#67308F', // Set the background color of the icon
        borderRadius: "25%", // Make it circular if needed
      }}
    />
  );
  useEffect(() => {
    savedContactList();
    getUserProfile();
  }, []);

  useEffect(() => {
    dispatch(getSingleUserProfile(location?.state?._id));
  }, [location?.state?._id]);

  //Video Point
  useEffect(() => {
    if (videoPlayed) {
      // Call API when the video starts playing
      handleVideoPlaypoint();
      // Display toast message
    }
  }, [videoPlayed]);
  // fetch own profile

  useEffect(() => {
    dispatch(getOwnProfile());
  }, []);

  return (
    <>
      <div className="memberownprfl">
        <CustomLoader loading={isLoading} />

        <div className="cust_container">
          <div className="bg-wht">
            <div className="bggradient">
              <img src={SingleUserData?.[0]?.banner} alt="img" />
            </div>
            <div className="persondetailsdiv">
              <div className="img_profileflx">
                <div className="imgbrdr">
                  <img src={SingleUserData?.[0]?.profilePhoto} alt="..." />
                </div>
                <div className="dtlsprflnmmph">
                  <div className="left">
                    <p className="profiledtlstxt">
                      {/* {SingleUserData?.[0]?.gender}{" "} */}
                      {SingleUserData?.[0]?.personName}
                    </p>
                    <p className="prodtlmarkettxt">
                      {SingleUserData?.[0]?.designation}
                    </p>
                    <div className="likeCount_all">
                      <p className="text">
                        <strong>{SingleUserData?.[0]?.likeCount}</strong>
                        <span>Likes</span>
                      </p>
                      <p className="text">
                        <strong>{SingleUserData?.[0]?.memberCount}</strong>
                        <span>Connections</span>
                      </p>
                    </div>
                  </div>
                  <div className="Verified">
                    <img src={verified} className="img-fluid" alt="icon" />
                  </div>
                </div>
              </div>
              <div className="likespinerdivflx mx-3">
                {/* <Link className="shredivflx" onClick={shareClick}>
                  <span className="">Share</span>
                  <i className="fa-solid fa-share-from-square"></i>
                </Link> */}

                {/* Message button for member to member */}
                {UserProfileID?.memberStatus && (
                  <button
                    type="button"
                    className="Messagebtn_ "
                    onClick={chkChatlistAndCreate}
                  >
                    <img src={Messageicon} className="img-fluid" alt=".." />
                    Message
                  </button>
                )}

                <button
                  type="button"
                  className="likespiner "
                  style={{ cursor: "pointer" }}
                  // onClick={() => hitLike(SingleUserData?.[0]?._id)}
                >
                  {SingleUserData?.[0]?.mylikesStatus ? (
                    <img
                      src={likec}
                      className={`img-fluid ${isTilted ? "tilt-shake " : ""}`}
                      onClick={() =>
                        toast("Already liked!", {
                          icon: <CustomIcon />,
                          style: {
                            borderRadius: "10px",
                            // background: "#67308F",
                            color: "#67308F",
                            position: "bottom-center",
                          },
                        })
                      }
                      alt=".."
                    />
                  ) : (
                    <img
                      src={likew}
                      className={`img-fluid tilt-shake ${
                        isTilted ? "tilt-shaking" : ""
                      }`}
                      onClick={() => hitLike(SingleUserData?.[0]?._id)}
                      alt=".."
                    />
                  )}

                  {SingleUserData?.[0]?.mylikesStatus ? (
                    <span className="">Liked</span>
                  ) : (
                    <span className="">Like</span>
                  )}
                </button>

                {/* <Link to="/Owncontactlist" className="likespiner">
                  <img src={Frame} alt="..." />
                  <span className="">{SingleUserData?.[0]?.memberCount}</span>
                </Link> */}

                <div
                  onClick={handleWhatsappClick}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={whatsapp}
                    alt="..."
                    className="whatsappMemberOtherProfile"
                  />
                </div>
                <div
                  className="callButton"
                  onClick={handleButtonClick}
                  style={{ cursor: "pointer" }}
                >
                  <figure className="drctrCntctIcon responsiveshow">
                    <img src={call} alt="img" className="img-fluid" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="infolabbrdr">
              <div className="infoflx justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="rctdivimg" onClick={modhandleclick}>
                    <img src={SingleUserData?.[0]?.qrCode} alt="QR" />
                  </div>
                  <div className="groupdiv">
                    <div>
                      <h4 className="ocentxt">
                        {SingleUserData?.[0]?.companyName}
                      </h4>
                      <p className="pcndgtxt">
                        {SingleUserData?.[0]?.TypeOfCompany}
                      </p>
                      <div className=" d-flex">
                        <p className="dbytxtp mr-1">
                          {SingleUserData?.[0]?.emirate}
                        </p>
                        <p className="dbytxtp">
                          {SingleUserData?.[0]?.country}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="d-flex" style={{ gap: '12px' }}>
                                    <Link to='/Invitation' className='invitebtn'>Invite Member</Link>
                                    <Link to='/Memberinvitation' className='invitebtn'>Invite Non-member</Link>
                                </div> */}
              </div>

              <div className="mainrattingarea">
                <div className="rating_box">
                  {/* <i className="fa-regular fa-star" />
                      <i className="fa-regular fa-star" />
                      <i className="fa-regular fa-star" />
                      <i className="fa-regular fa-star" />
                      <i className="fa-regular fa-star" /> */}

                  <StarRatings
                    rating={SingleUserData?.[0]?.avgRating}
                    starRatedColor="#F3660B"
                    starEmptyColor="grey"
                    numberOfStars={5}
                    name="UserProfileRating" // Set to a generic name for product ratings
                    starDimension="20px"
                    starSpacing="5px"
                    className="fa-regular fa-star"
                  />
                </div>

                <div className="giveratting_btn">
                  <button className="btn" onClick={popupclick}>
                    <img src={tticon} className="img-fluid" alt=".." />
                    {/* <i className="fa-solid fa-star"></i> */}
                    Tap to Rate
                  </button>
                </div>
              </div>
            </div>

            <div className="socialdivflx">
              <div
                onClick={() =>
                  SocialMediaPointGive(SingleUserData?.[0]?.facebookLink)
                }
                // href={SingleUserData?.[0]?.facebookLink}
                // target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </div>
              <div
                onClick={() =>
                  SocialMediaPointGive(SingleUserData?.[0]?.instaLink)
                }
                // href={SingleUserData?.[0]?.instaLink}
                // target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-instagram"></i>
              </div>
              <div
                onClick={() =>
                  SocialMediaPointGive(SingleUserData?.[0]?.linkedInLink)
                }
                // href={SingleUserData?.[0]?.linkedInLink}
                // target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-linkedin-in"></i>
              </div>

              <div
                onClick={() =>
                  SocialMediaPointGive(SingleUserData?.[0]?.youtubeLink)
                }
                // href={SingleUserData?.[0]?.youtubeLink}
                // target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-youtube"></i>
              </div>
              {/* <a
                href={SingleUserData?.[0]?.dribbbleLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-solid fa-basketball"></i>
              </a> */}
              <div
                onClick={() =>
                  SocialMediaPointGive(SingleUserData?.[0]?.dribbbleLink)
                }
                // href={SingleUserData?.[0]?.dribbbleLink}
                // target="_blank"
                className="bgsocial"
              >
                <i class="fa-brands fa-x-twitter"></i>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={websiteVisitPointGive}
                // href={SingleUserData?.[0]?.websiteLink}
                // target="_blank"
                className="bgsocial"
              >
                <i class="fa-solid fa-globe"></i>
              </div>
            </div>

            <div className="cntntbrdr">
              <div className="flxcntnt">
                <p className="cntncttxt">Contact Details</p>
                <div className="ArrwiconDiv" onClick={handledetailClick}>
                  <span
                    className={
                      contactdetail
                        ? "fa-solid fa-chevron-up"
                        : "fa-solid fa-chevron-down"
                    }
                  ></span>
                </div>
              </div>
              {contactdetail && (
                <div className="">
                  <div className="divcnctflx">
                    <div className="brdrdivdtls">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-phone"></i>
                        {SingleUserData?.[0]?.telephoneNo}
                      </span>
                    </div>
                    <div className="brdrdivdtls">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-mobile-screen-button"></i>
                        {SingleUserData?.[0]?.phone_code}&nbsp;
                        {SingleUserData?.[0]?.mobileNo}
                      </span>
                    </div>
                    <div className="brdrdivdtls">
                      <span className="txtdtlmn">
                        <i className="fa-brands fa-whatsapp"></i>
                        {SingleUserData?.[0]?.whats_code}&nbsp;
                        {SingleUserData?.[0]?.whatsappNo}
                      </span>
                    </div>
                  </div>
                  <div className="dibmailflx">
                    <div className="envelopdiv">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-envelope"></i>
                        {SingleUserData?.[0]?.emailId}
                      </span>
                    </div>
                    <div className="envelopdiv">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-globe"></i>
                        {SingleUserData?.[0]?.websiteLink}
                      </span>
                    </div>
                  </div>
                  <div className="divloctin">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-location-dot"></i>
                      {SingleUserData?.[0]?.address} ,{" "}
                      {SingleUserData?.[0]?.location}
                    </span>
                  </div>

                  {/* <div className='mapwidth'>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14728.727405628732!2d88.33364660000001!3d22.64700745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1706076991023!5m2!1sen!2sin"
                                        // width={900}
                                        heigbwFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />

                                </div> */}

                  <div className="getdrctnBtnDiv d-flex justify-content-between divloctin">
                    <p className="txtdtlmn">
                      {SingleUserData?.[0]?.emirate},
                      {SingleUserData?.[0]?.country}
                    </p>
                    <a
                      href={SingleUserData?.[0]?.locationLink}
                      className="getdirectionbtn"
                      target="_blank"
                    >
                      Get Direction
                      <i class="fa-solid fa-diamond-turn-right"></i>
                    </a>
                  </div>
                </div>
              )}
            </div>

            <div className="abtbrsdr">
              <div className="flxcntnt">
                <p className="cntncttxt">About Us</p>
                <div className="ArrwiconDiv" onClick={handleaboutClick}>
                  <span
                    className={
                      aboutdetail
                        ? "fa-solid fa-chevron-up"
                        : "fa-solid fa-chevron-down"
                    }
                  ></span>
                </div>
              </div>
              {aboutdetail && (
                <div className="">
                  <p className="txtabtmain">
                    {SingleUserData?.[0]?.companyDescription}
                  </p>
                </div>
              )}
            </div>

            {SingleUserData?.[0]?.service?.length > 0 && (
              <div className="servcbrdr">
                <div className="flxcntnt">
                  <p className="cntncttxt">Services</p>
                  <div className="ArrwiconDiv" onClick={handleserviceClick}>
                    <span
                      className={
                        servicedetail
                          ? "fa-solid fa-chevron-up"
                          : "fa-solid fa-chevron-down"
                      }
                    ></span>
                  </div>
                </div>
                {servicedetail && (
                  <div className="srvcboxbrdr">
                    {SingleUserData?.[0]?.service?.map((item, i) => {
                      return (
                        <>
                          <div className="brdrmaindesign" key={i}>
                            {" "}
                            {item}
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

            {SingleUserData?.[0]?.productData.length > 0 && (
              <div className="prdrtbrdr">
                <div className="flxcntnt">
                  <p className="cntncttxt">Products</p>
                  <div className="ArrwiconDiv" onClick={handleproductClick}>
                    <span
                      className={
                        productdetail
                          ? "fa-solid fa-chevron-up"
                          : "fa-solid fa-chevron-down"
                      }
                    ></span>
                  </div>
                </div>
                {productdetail && (
                  <div className="">
                    <div className="prdrtflxdivmain">
                      {SingleUserData?.[0]?.productData?.map((item, index) => {
                        return (
                          <div
                            className="brdrprdctdiv"
                            key={index}
                            onClick={() => handlemodalClick(item)}
                          >
                            <div className="prdcgimg">
                              <img src={item?.image} alt="..." />
                            </div>
                            <div className="textparamain">
                              <p className="txtdiv">{item?.name}</p>
                              <p className="txtpara">{item?.description}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* <div className='vdoighh'>
                                    <iframe
                                        width={560}
                                        height={315}
                                        src="https://www.youtube-nocookie.com/embed/My0jYiN5JDU"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    />
                                </div> */}
                  </div>
                )}
              </div>
            )}

            <div className="brdrsrvc">
              <div className="flxcntnt">
                <p className="cntncttxt">Profile</p>
                <div className="ArrwiconDiv" onClick={handleservicepdfClick}>
                  <span
                    className={
                      servicepdfdetail
                        ? "fa-solid fa-chevron-up"
                        : "fa-solid fa-chevron-down"
                    }
                  ></span>
                </div>
              </div>
              {servicepdfdetail && (
                <>
                  <div>
                    {SingleUserData?.[0]?.videoUrl && (
                      <div className="vdoighh">
                        {/* <iframe
                      width={560}
                      height={315}
                      src="https://www.youtube.com/embed/WAyzEOeeBZw"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    /> */}
                        <ReactPlayer
                          // width={}
                          // height={415}
                          // width={100}
                          // height={415}
                          title="YouTube video player"
                          className="ReactPlayerdiv"
                          url={SingleUserData?.[0]?.videoUrl}
                          onPlay={handleVideoPlay}
                        />
                      </div>
                    )}
                    {SingleUserData?.[0]?.doc.length > 0 && (
                      <div className="pdrmainflx">
                        {SingleUserData?.[0]?.doc?.map((item, i) => {
                          return (
                            <div className="pdfflxDiv">
                              <div className="pdfimg">
                                <img src={pdf} alt="..." />
                                <div className="inpuduv">
                                  {/* <input type="file" id="myfile" name="myfile" /> */}
                                  <a href={item?.doc}></a>
                                </div>
                              </div>
                              <div className="prflmainflx">
                                <p className="prfltxtprdct">
                                  {" "}
                                  {item?.docName
                                    ? item.docName
                                    : `Document${i + 1}`}
                                </p>
                                <div
                                  onClick={() => DocPointButton(item?.pdf)}
                                  // href={item?.pdf}
                                  // target="_blank"
                                  style={{ cursor: "pointer" }}
                                  rel="noopener noreferrer"
                                  // download="list-of-countries-and-capitals-and-currency.pdf"
                                  className=" text-primary mt-2"
                                >
                                  Open PDF <i class="fa-solid fa-download"></i>
                                </div>
                                <p className="prfldtlsprdct">
                                  Lorem Ipsum has been the industry's standard
                                </p>
                              </div>
                            </div>
                          );
                        })}
                        {/* <div className="pdfflxDiv">
                      <div className="pdfimg">
                        <img src={pdf} alt="..." />
                        <div className="inpuduv">
                          <input type="file" id="myfile" name="myfile" />
                        </div>
                      </div>
                      <div className="prflmainflx">
                        <p className="prfltxtprdct">Profile Name</p>
                        <p className="prfldtlsprdct">
                          Lorem Ipsum has been the industry's standard
                        </p>
                      </div>
                    </div>
                    <div className="pdfflxDiv">
                      <div className="pdfimg">
                        <img src={pdf} alt="..." />
                        <div className="inpuduv">
                          <input type="file" id="myfile" name="myfile" />
                        </div>
                      </div>
                      <div className="prflmainflx">
                        <p className="prfltxtprdct">Profile Name</p>
                        <p className="prfldtlsprdct">
                          Lorem Ipsum has been the industry's standard
                        </p>
                      </div>
                    </div> */}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {modala && (
          <ProductModal closemodal={setModala} ProductItem={ProductItem} />
        )}
        {showmodal && (
          <QRshareModal
            closeModal={setShowmodal}
            Qrimage={SingleUserData?.[0]?.qrCode}
          />
        )}
        {sharemodal && (
          <ProfileShare
            whatsApp={SingleUserData?.[0]?.whatsappNo}
            linkedInLink={SingleUserData?.[0]?.linkedInLink}
            facebookLink={SingleUserData?.[0]?.facebookLink}
            userLink={SingleUserData?.[0]?.userLink}
            instaLink={SingleUserData?.[0]?.instaLink}
            emailId={SingleUserData?.[0]?.emailId}
            closeModal={setSharemodal}
          />
        )}
      </div>

      {giveratting && (
        <GiveRattingModal
          closemodal={setGiveratting}
          UserID={SingleUserData?.[0]?._id}
        />
      )}
    </>
  );
};

export default MemberOtherSavedProfile;
