import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css'
import MainLayout from './Layout/MainLayout';
import Invitation from './View/Invitation/Invitation';
import Contactlist from './View/ContactList/Contactlist';
import Owncontactlist from './View/OwnContactlist/Owncontactlist';
import Owncontactlist2 from "./View/OwnContactlist/Owncontactlist2";
import Memberinvitation from './View/Memberinvitation/Memberinvitation';
import Memberownprofile from './View/MemberownProfile/Memberownprofile';
import Memberuserprofile from './View/MemberUserprofile/Memberuserprofile';
import NonMemberuserprofile from './View/NonMemberuserprofile/NonMemberuserprofile';
import Home from './View/Homepage/Home';
import LoginNew from './Authentication/LoginNew';
import ForgotPassword from './Authentication/ForgotPassword';
import ResetPassword from './Authentication/ResetPassword';
import HomeLayout from './Layout/HomeLayout';
import LoginLayout from './Layout/LoginLayout';
import PrivateRouter from './privateRouter/PrivateRouter';
import { Toaster } from 'react-hot-toast';
import Loader from './Component/Loader';
import Reward from "../src/View/Reward/Index";
import MemberOtherProfile from './View/MemberownProfile/MemberOtherProfile';
import RewardPointsSummary from './View/Reward/RewardPointsSummary';
import NonMemberuserOtherprofile from './View/NonMemberuserprofile/NonMemberuserOtherprofile';
import MemberOtherSavedProfile from './View/MemberownProfile/MemberOtherSavedProfile';
import NonMemberuserSavedProfile from './View/NonMemberuserprofile/NonMemberuserSavedProfile';
import OffersCategories from "../src/View/Reward/OffersCategories";
import OffersCategoriesDetails from './View/Reward/OffersCategoriesDetails';
import LetsgetinTouch from "./View/OthersPages/LetsgetinTouch";
import OurPartners from "./View/Reward/OurPartners";
import RedeemyourPoints from './View/Reward/RedeemyourPoints';
import MyWalletPage from "./View/Reward/MyWalletPage";
import RewardsAbout from "./View/Reward/RewardsAbout";
import HowWorkRewards from './View/Reward/HowWorkRewards';
import HowRedeem from './View/Reward/HowRedeem';
import VenderDashboard from "./View/VenderDashboard/Index";
import OfferStatsInnerPage from './View/VenderDashboard/OfferStatsInnerPage';
import RedeemedStatsInnerPage from './View/VenderDashboard/RedeemedStatsInnerPage';
import AboutNeooSearch from './View/OthersPages/AboutNeooSearch';
import TermsofServices from './View/OthersPages/TermsofServices';
import PrivacyPolicy from './View/OthersPages/PrivacyPolicy';
import UserVoucherCode from './View/VenderDashboard/UserVoucherCode';
import { useEffect, useState } from 'react';
import HttpClient from './utils/HttpClient';
import LandingPage from './View/Homepage/LandingPage';
import CategoryWiseAllOffersDetals from './View/Reward/CategoryWiseAllOffersDetals';
import OfferVoucherIssuedStats from './View/VenderDashboard/OfferVoucherIssuedStats';
import OfferViewStats from './View/VenderDashboard/OfferViewStats';
import ProductsAndServices from './View/OthersPages/ProductsAndServices';
import RewardHomePage from './View/RewardsHomePage/RewardHomePage';
import ReferAndEarnHome from './View/ReferAndEarnPage/ReferAndEarnHome';
import Explore from './View/Explore/Explore';
import ChatIndex from './View/Chatpage/ChatIndex';
import HowEarningRewards from './View/Reward/HowEarningRewards';
import { reactLocalStorage } from 'reactjs-localstorage';
import PeopleAcceleratorProgram from './View/PeopleAccelerator/Index';
import SingleChatArea from './View/Chatpage/SingleChatArea';
import { useDispatch, useSelector } from 'react-redux';
import { getOwnProfile } from './Redux/Slice/PostSlice';
import { onDisconnect, ref, serverTimestamp, set, update } from 'firebase/database';
import { db } from './Firebase/FirebaseConfig';
import ReferEarnDashboard from "./View/ReferEarnDashboard/Index";
import GenerateLinkPage from './View/ReferEarnDashboard/GenerateLinkPage';
import TodayEarningsPage from './View/ReferEarnDashboard/TodayEarningsPage';
import MonthlyEarningsPage from './View/ReferEarnDashboard/MonthlyEarningsPage';
import JoinedMembersPage from './View/ReferEarnDashboard/JoinedMembersPage';
import JoinedNonMembersPage from "./View/ReferEarnDashboard/JoinedNonMembersPage";
import WithdrawHome from './View/WIthdraw/WithdrawHome';
import TotalEarned from './View/WIthdraw/TotalEarned';
import AvailableBalance from './View/WIthdraw/AvailableBalance';
import TransferNow from './View/WIthdraw/TransferNow';
import SuccessWithdraw from './View/WIthdraw/SuccessWithdraw';
import BankAccountUpdate from './View/WIthdraw/BankAccountUpdate';
import Logedsearch from './View/LogedSearch/Logedsearch';
import HomeSearchLayout from "./Layout/HomeSearchLayout"
import Searchhome from './View/SearchHome/Searchhome';
import SearchLogin from './View/SearchHome/SearchLogin';
import Searchloginform from './View/SearchHome/Searchloginform';
import Listbusinessform from './View/SearchHome/Listbusinessform';
import BusinessInfoform from './View/SearchHome/BusinessInfoform';
import Ownblmprofile from './View/SearchHome/Ownblmprofile';
import Otherblmprofile from './View/SearchHome/Otherblmprofile';
import Ownpostpage from './View/SearchHome/Ownpostpage';
import Ownsearchresult from './View/SearchHome/Ownsearchresult';
import Businessdirectory from './View/SearchHome/Businessdirectory';
import Membermasterdashbord from './View/SearchHome/Membermasterdashbord';
import Blmemberlist from './View/SearchHome/Blmemberlist';
function App() {
  const [contact, setsavedContacts] = useState([])
  const dispatch = useDispatch()

  const { UserProfileID } = useSelector(state => state?.app)
  const { chatListData, singleChatData, RecieverID } = useSelector(
    (state) => state?.chat
  );
  let user = reactLocalStorage.get("userDataNeoCard");
  // console.log(user, "token")
  console.log(user, "awfafawedawd")
  console.log(UserProfileID, "awjabeigbsegbif35u3bhbfuidfh")
  // console.log(RecieverID, "awfafawedawd")


  const savedContactList = async () => {
    const res = await HttpClient.requestData("member-contacts", "GET", {});
    console.log(res, "appjs",);
    if (res && res?.status) {
      setsavedContacts(res?.data);

    }
  };
  useEffect(() => {
    savedContactList()
    dispatch(getOwnProfile())
  }, [])
  // firebase 

  // firebase account creation

  /////////////////////////////////////////
  // www. problem solved this code  is for routing in react application please comment this
  // useEffect while working on localhost and make it uncomment for the proper effect  of the website
  /////////////////////////////////////////



  // useEffect(() => {
  //   const handleUrlChange = () => {
  //     const currentUrl = window.location.href;
  //     const protocolIndex = currentUrl.indexOf('://');
  //     if (protocolIndex !== -1) {
  //       const afterProtocol = currentUrl.substring(protocolIndex + 3);
  //       if (!afterProtocol.startsWith('www.')) {
  //         const newUrl = `https://www.${afterProtocol}`;
  //         window.location.href = newUrl;
  //       }
  //     }
  //   };

  //   // Listen for changes in the URL
  //   window.addEventListener('load', handleUrlChange);

  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener('load', handleUrlChange);
  //   };
  // }, []);



  return (
    <>
      <BrowserRouter>
        <Toaster />
        <Routes>

          <Route path='/' element={<HomeLayout />}>
            <Route path='/' element={<LandingPage />} />
            <Route path='/user-login' element={<Home />} />
          </Route>

          <Route path='/' element={<LoginLayout />}>
            <Route path="/Login" element={<LoginNew />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />

            <Route path='/lets-get-intouch' element={<LetsgetinTouch />} />
            <Route path='/about-neoo-search' element={<AboutNeooSearch />} />
            <Route path='/terms-of-services' element={<TermsofServices />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />

            <Route path='/products-and-services' element={<ProductsAndServices />} />
            <Route path='/reward-home-page' element={<RewardHomePage />} />
            <Route path='/refer-and-earn' element={<ReferAndEarnHome />} />
            <Route path='/explore' element={<Explore />} />
            <Route path='/people-accelerator-program' element={<PeopleAcceleratorProgram />} />
            <Route path='/logedsearch' element={<Logedsearch/>}/> 
          </Route>

          <Route element={<PrivateRouter />}>
            <Route path='/' element={<MainLayout />}>
              <Route path="/Invitation" element={<Invitation />} />

              <Route path="/profile/:name" element={<Memberownprofile />} />
              <Route path="/MemberOtherProfile/:name" element={<MemberOtherProfile />} />
              <Route path="/MemberOtherSavedProfile/:name" element={<MemberOtherSavedProfile />} />
              <Route path="/Memberuserprofile" element={<Memberuserprofile />} />
              <Route path="/NonMemberuserprofile/:name" element={<NonMemberuserprofile />} />
              <Route path="/NonMemberuserOtherprofile/:name" element={<NonMemberuserOtherprofile />} />
              <Route path="/NonMemberuserSavedProfile/:name" element={<NonMemberuserSavedProfile />} />
              <Route path="/Contactlist" element={<Contactlist />} />
              <Route path="/Owncontactlist2" element={<Owncontactlist />} />
              <Route path="/Owncontactlist" element={<Owncontactlist2 />} />
              <Route path='/chat' element={<ChatIndex />} />
              <Route path='/single-chat' element={<SingleChatArea />} />
              <Route path='/reward' element={<Reward />} />
              <Route path='/reward-points-summary' element={<RewardPointsSummary />} />
              <Route path='/offers-categories' element={<OffersCategories />} />
              <Route path='/offers-categories-details/:name/:id' element={<OffersCategoriesDetails />} />
              <Route path='/category-wise-offers/:name/:id' element={<CategoryWiseAllOffersDetals />} />
              <Route path='/our-partners' element={<OurPartners />} />
              <Route path='/redeemyour-points' element={<RedeemyourPoints />} />
              <Route path='/my-wallet' element={<MyWalletPage />} />
              <Route path='/rewards-about' element={<RewardsAbout />} />
              <Route path='/how-work-rewards' element={<HowWorkRewards />} />
              <Route path='/how-earning-rewards' element={<HowEarningRewards />} />
              <Route path='/how-redeem' element={<HowRedeem />} />

              <Route path='/vender-dashboard' element={<VenderDashboard />} />

              <Route path='/refer-earn-dashboard' element={<ReferEarnDashboard />} />
              <Route path='/generate-Link' element={<GenerateLinkPage />} />
              <Route path='/today-earnings' element={<TodayEarningsPage />} />
              <Route path='/monthly-earnings' element={<MonthlyEarningsPage />} />
              <Route path='/joined-members' element={<JoinedMembersPage />} />
              <Route path='/joined-non-members' element={<JoinedNonMembersPage />} />

              <Route path='/withdraw' element={<WithdrawHome />} />
              <Route path='/total-earned' element={<TotalEarned />} />
              <Route path='/available-balance' element={<AvailableBalance />} />
              <Route path='/transfer-now' element={<TransferNow />} />
              <Route path="/success" element={<SuccessWithdraw />} />
              <Route path='/bank-account-update' element={<BankAccountUpdate />} />


              {/* VoucherRedeemed stats page */}
              <Route path='/offer-voucher-redeemed' element={<OfferStatsInnerPage />} />
              {/* voucher issued stats */}
              <Route path='/offer-voucher-issued' element={<OfferVoucherIssuedStats />} />
              {/* Offer views */}
              <Route path='/offer-view' element={<OfferViewStats />} />
              <Route path='/redeemed-stats-inner' element={<RedeemedStatsInnerPage />} />
              <Route path='/user-voucher-code' element={<UserVoucherCode />} />
            </Route>
          </Route>

          <Route path='/' element={<HomeSearchLayout/>}>
              <Route path='/searchhome' element={<Searchhome/>}/>
              <Route path='/searchlogin' element={<SearchLogin/>}/>
              <Route path='/searchloginform' element={<Searchloginform/>}/>
              <Route path='/listbusiness' element={<Listbusinessform/>}/>
              <Route path='/businesinfoform' element={<BusinessInfoform/>}/>
              <Route path='/ownblmprofile' element={<Ownblmprofile/>}/>
              <Route path="/otherblmprofile" element={<Otherblmprofile/>}/>
              <Route path='/ownpostpage' element={<Ownpostpage/>}/>
              <Route path='/ownsearchresult' element={<Ownsearchresult/>}/>
              <Route path='/businessdirectory' element={<Businessdirectory/>}/>
              <Route path="/masterdashbord" element={<Membermasterdashbord/>}/>
              <Route path="/blmamberlist" element={<Blmemberlist/>}/>
          </Route>


          <Route path="/Memberinvitation/:name" element={<Memberinvitation />} />
          <Route path='/Loader' element={<Loader />} />

        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
