import React, { useEffect } from "react";
import icon from "../Images/thik.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  OfferCodeShowModal,
  PartnersOfferPurchase,
  congratsModalView,
} from "../Redux/Slice/Offer";

function CongratsModal({ closemodal2 }) {
  const { offerCodeModal } = useSelector((state) => state.offer);
  console.log(offerCodeModal, "offerCodeModal");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigateWallet = () => {
    dispatch(congratsModalView(false));
    navigate("/my-wallet");
  };

  return (
    <>
      <section className="addvoucherWallet_Modal">
        <div className="addvoucherWallet_body">
          <div className="close_btn">
            <button
              className="btn"
              onClick={() => {
                dispatch(congratsModalView(false));
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>

          <div className="icon">
            <img src={icon} className="img-fluid" alt="..." />
          </div>

          <h4 className="heading">Congrats !</h4>

          <h6 className="subheding">
            Your reward voucher has been issued to your wallet. Please get it
            now.
          </h6>

          <p className="vcode">{offerCodeModal?.offerCode}</p>
          <p className="Valid">Valid for 48 Hrs</p>

          <div className="bottom_area">
            <button className="cancel_btn">Cancel</button>
            <div onClick={handleNavigateWallet} className="addtowallet_btn">
              Get voucher
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CongratsModal;
