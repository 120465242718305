import React from 'react';
import logo from "../Images/Neoo search logo white.png";
import { Link } from 'react-router-dom';

const LoginHeader = () => {
    return (
        <>
            <section className="loginHeader">
                <div className="container">
                    <div className="homeHeadInn">
                        <Link to='/'>
                            <figure className="logoneeo">
                                <img src={logo} alt="logo" />
                            </figure>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginHeader