import React from 'react'
import "./Withdraw.css"
import WithdrawTopHeading from './WithdrawTopHeading'
import successIcon from "../../Images/Refer and Earn/success-icon.png"
import errorIcon from "../../Images/Refer and Earn/error.png"

const SuccessWithdraw = () => {
    return (
        <>
            <div className='successWithdraw_page'>
                <div className='cust_container'>
                    <WithdrawTopHeading />

                    {/***success ***/}
                    <div className='Withdrawstatus_wrap'>
                        <div className='Withdrawstatus_body'>
                            <div className='icon'>
                                <img src={successIcon} className='img-fluid' alt='..' />
                            </div>
                            <h4 className='heading' style={{color: "#27AE60"}}>SUCCESS</h4>
                            <h6 className='subheading'>Thank you for your request.
                                We are working hard to find the best service and deals for you. </h6>
                            <p className='desc'>Within 3–4 working days, you will get a confirmation
                                in your email or notification.</p>
                            <div className='activebtn_btn'>
                                <button className='btn succ'>Done</button>
                            </div>
                        </div>
                    </div>

                    {/***ERROR! ***/}
                    <div className='Withdrawstatus_wrap'>
                        <div className='Withdrawstatus_body'>
                            <div className='icon'>
                                <img src={errorIcon} className='img-fluid' alt='..' />
                            </div>
                            <h4 className='heading' style={{color: "#F21E1E"}}>ERROR!</h4>
                            <h6 className='subheading'>Thank you for your request.
                                We are unable to continue the process.</h6>
                            <p className='desc'>Please try again to complete the request.</p>
                            <div className='activebtn_btn'>
                                <button className='btn erro'>Try Again</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SuccessWithdraw