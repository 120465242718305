import React, { useEffect } from "react";
import giftcard from "../../Images/gift-icon.png";
import img1 from "../../Images/boxcard1.png";
import img2 from "../../Images/boxcard2.png";
import img3 from "../../Images/boxcard3.png";
import img4 from "../../Images/boxcard4.png";
import img5 from "../../Images/boxcard5.png";
import img6 from "../../Images/boxcard6.png";
import img7 from "../../Images/boxcard7.png";
import img8 from "../../Images/boxcard8.png";
import img9 from "../../Images/boxcard9.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OfferCategories, OwnWalletDetails } from "../../Redux/Slice/Offer";
import toast from "react-hot-toast";

function OffersCategories() {
  const { WalletData, OfferCategoriesData } = useSelector(
    (state) => state.offer
  );
  const nonPurchaseWalletOffer = WalletData?.filter(
    (item) => !item?.voucherReedem
  );
  const dispatch = useDispatch();
  console.log(OfferCategoriesData, "OfferCategoriesData");
  // const Categor = [
  //   {
  //     id: 1,
  //     linkpage: "/offers-categories-details",
  //     src: img1,
  //     text: "Dining",
  //     count: "(10)",
  //   },
  //   {
  //     id: 2,
  //     linkpage: "/offers-categories-details",
  //     src: img2,
  //     text: "Automobiles",
  //     count: "(20)",
  //   },
  //   {
  //     id: 3,
  //     linkpage: "/offers-categories-details",
  //     src: img3,
  //     text: "Education",
  //     count: "(50)",
  //   },
  //   {
  //     id: 4,
  //     linkpage: "/offers-categories-details",
  //     src: img4,
  //     text: "Beauty & Wellness",
  //     count: "(20)",
  //   },
  //   {
  //     id: 5,
  //     linkpage: "/offers-categories-details",
  //     src: img5,
  //     text: "Fitness",
  //     count: "(50)",
  //   },
  //   {
  //     id: 6,
  //     linkpage: "/offers-categories-details",
  //     src: img6,
  //     text: "Food & Beverage",
  //     count: "(90)",
  //   },
  //   {
  //     id: 7,
  //     linkpage: "/offers-categories-details",
  //     src: img7,
  //     text: "Fashion & Retail",
  //     count: "(50)",
  //   },
  //   {
  //     id: 8,
  //     linkpage: "/offers-categories-details",
  //     src: img8,
  //     text: "Hotels & Resorts",
  //     count: "(14)",
  //   },
  //   {
  //     id: 9,
  //     linkpage: "/offers-categories-details",
  //     src: img9,
  //     text: "Entertainment & Leisure",
  //     count: "(14)",
  //   },
  // ];

  useEffect(() => {
    dispatch(OwnWalletDetails());
    dispatch(OfferCategories());
  }, []);
  return (
    <>
      <section className="OffersCategories_page">
        <div className="cust_container">
          <div className="top_area">
            <Link className="backbtn" to="/reward">
              <i className="fa-solid fa-arrow-left-long" />
            </Link>
            <h4 className="heading">Offers categories</h4>
            <Link to="/my-wallet">
              <div className="icon">
                <img src={giftcard} className="img-fluid" alt="....." />
                {nonPurchaseWalletOffer?.length > 0 && (
                  <span className="notification_count">
                    {nonPurchaseWalletOffer?.length}
                  </span>
                )}
              </div>
            </Link>
          </div>

          <div className="bottom_area row">
            {OfferCategoriesData?.map((item, index) => (
              <div className="col-lg-4 col-md-6 col-12 mb-4" key={index}>
                {item.Offers && item.Offers.length > 0 ? (
                  <Link
                    to={`/category-wise-offers/${item.categoryName}/${item._id}`}
                  >
                    <div className="categor_card">
                      <img
                        src={item.categoryImage}
                        className="img-fluid"
                        alt="img"
                      />
                      <div className="overlay_text">
                        <span>{item.categoryName}</span>
                        <span>({item.Offers.length})</span>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div
                    className="categor_card"
                    onClick={() =>
                      toast("No Offers Available!", {
                        style: {
                          borderRadius: "10px",
                          // background: "#67308F",
                          color: "#67308F",
                          position: "bottom-center",
                        },
                      })
                    }
                    alt=".."
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={item.categoryImage}
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="overlay_text">
                      <span>{item.categoryName}</span>
                      <span>({item.Offers.length})</span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default OffersCategories;
