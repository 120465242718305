import React from 'react'
import { useState } from 'react'
import noicon from "../Images/Refer and Earn/notification.png"
import profilepic from "../Images/profle.png"

const NotificationBar = ({ show, closeNotifiBar }) => {

    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const smsnotification = [
        { id: 1, },
        { id: 2, },
        { id: 3, },
        { id: 4, },
        { id: 5, },
        { id: 6, },
    ];

    return (
        <>
            <section className={`NotificationBar_wrap ${show ? 'show' : ''}`}>
                <div className='top_area'>
                    <h4 className='heading'>Notification</h4>
                    <button className='closebtn' onClick={closeNotifiBar}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className='content_wrap'>

                    {/*****All Notification***/}
                    {smsnotification.map((items, index) => (

                        // <div className='smsbox_wrap unread' key={index}>
                        <div className='smsbox_wrap' key={index}>
                            <div className='left'>
                                <div className='profile'>
                                    <img src={profilepic} className='img-fluid' alt='...' />
                                </div>
                                <p className='text'>
                                    <b><span>Congratulations!</span> You have earned $27.23 cash reward
                                        Ashwin Bose,</b> your friend, has joined by your referral code.
                                    Do more invitations to earn more.
                                </p>
                            </div>
                            <div className='right'>
                                <button className='more_btn' onClick={toggleDropdown}>
                                    <i className="fa-solid fa-ellipsis"></i>
                                    {isOpen && (
                                        <div className='morebtn_dropdown'>
                                            <ul>
                                                <li className='item'>
                                                    <i className="fa-solid fa-trash-can"></i>
                                                    Delete notification
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </button>

                                <p className='datetime'>4 days ago</p>
                                <p className='status'><i className="fa-solid fa-circle"></i></p>
                            </div>
                        </div>
                    ))}



                    {/*****No Notification***/}
                    <div className='no_Notification'>
                        <div className='icon'>
                            <img src={noicon} className='img-fluid' alt='...' />
                        </div>

                        <h4 className='name'>No Notification</h4>
                        <p className='desc'> We’ll let you know when there will be
                            something . to update you.</p>

                    </div>



                </div>
            </section>
        </>
    )
}

export default NotificationBar