import React from 'react'

const MaximiseYourEarnings = () => {
    return (
        <>
            <section className='MaximiseYourEarnings_sec'>
                <div className='cust_container'>
                    <h2 className='heading'>Maximise your <span>Earnings</span> with Neoo search membership</h2>
                    <p className='text'>This refer and earn program is only for Neoo search members;
                        you can earn as much as you want by referring as many members as you want</p>

                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div className='Gainfreedom_wrap'>
                                <h2 className='Gainheading'>Gain freedom and possibly earn </h2>
                                <h4 className='Gainsubhead'>6000 AED per month, on your circumstances!</h4>
                                <h6 className='Gainpara'>If you are not a Neoo search member, Register now and Start your earnings.</h6>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div className='GainApplication_Form'>
                                <h4 className='head'>Application Form</h4>
                                <div className='desc_box'>
                                    <p className='desc'>Our team will contact RCG applicants via WhatsApp; please submit your phone number on WhatsApp.</p>
                                </div>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="">Your full name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Mobile number with Whatsapp</label>
                                        <div style={{ position: "relative", zIndex: "1" }}>
                                            <input
                                                type="number"
                                                className="form-control paddingleft"
                                                id=""
                                                placeholder="055 3670071"
                                            />

                                            <select className="numbercode">
                                                <option>+971</option>
                                                <option>+971</option>
                                                <option>+971</option>
                                                <option>+971</option>
                                                <option>+971</option>
                                                <option>+971</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Email id</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Country</label>
                                        <select className="form-control" id="" placeholder="Country">
                                            <option>Country</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>

                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                        <label className="form-check-label" htmlFor="exampleCheck1">
                                            Yes, I am over 18 years old and interested in becoming a REP member.
                                        </label>
                                    </div>
                                    <button type="submit" className="applynow_btn">
                                        Apply Now
                                    </button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MaximiseYourEarnings