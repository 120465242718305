import React, { useState } from 'react'
import editicon from "../../Images/editicon.png";
import cameralogo from "../../Images/cameralogo.png";
import likec from "../../Images/like/like-c.png";
import logootherblm from "../../Images/logootherblm.png"
const Otherblmprofileedit = () => {

    return (
        <div className='editprofileblm ' >
           
            <div className='editprofile'>
                <div className='cameralogodivimgabslt'>
                 
                    <div className='cameralogodivimg'>
                        <img src={cameralogo} alt='...' />
                    </div>
                    <p className='uplsdttxttppshji'>Upload Logo</p>
                    <p className='pixelsttxttppio'>(Pixel Size : 000 x 000 PNG/JPEG )</p>
                </div>
                <div className='profileimhaggemaindib'>
                    <img src={logootherblm} alt='...' />
                </div>
            </div>
            <div className=''>
                <p className='ocensttxttpppp'>Planet kids gallery</p>
                <p className='adverttstyusuppp'>Publishing</p>
            </div>
            <div className='likesfolwoerdivfxls'>
                <span className='likstrtxttpp'>10 Likes</span>

                <span className='likstrtxttpp'> 20 followers</span>
            </div>

            <div className='likedfollowotherdivflx'>
                <button className='likedfollowlike'>
                    <img src={likec} alt='...' className='img-fluid' />
                    Like
                </button>
                <button className='foolowndnbyhbnbtn'>
                    Follow
                </button>
            </div>

            
        </div>
    )
}

export default Otherblmprofileedit
