import React, { useRef, useState } from "react";
import "../../View/OthersPages/OthersPages.css";
import video from "../../Images/video/Intro-Short.mp4";
import Keyproducts1 from "../../Images/Keyproducts1.png";
import Keyproducts2 from "../../Images/Keyproducts2.png";
import Keyproducts3 from "../../Images/Keyproducts3.png";
import Keyproducts4 from "../../Images/Keyproducts4.png";
import { Link } from "react-router-dom";

const ProductsAndServices = () => {
  const videoref = useRef(null);
  const [isplaying, setIsplaying] = useState(false);
  const togglePlayPause = () => {
    if (videoref.current.paused) {
      videoref.current.play();
      setIsplaying(true);
    } else {
      videoref.current.pause();
      setIsplaying(false);
    }
  };

  const Keyproducts = [
    {
      id: 1,
      imglink: Keyproducts1,
      name: "Business network",
      list: [
        "Build a business network.",
        "Create a business or personal profile.",
        "Showcase your products and services",
        "Add unlimited contacts in business directory.",
        "B2B Lead generation",
        "SEO and social media-friendly",
      ],
    },

    {
      id: 2,
      imglink: Keyproducts2,
      name: "Business pack",
      list: [
        "BNFC Smart business card",
        "Smartly meet and exchange your contact",
        "Cost effective and Sustainable",
        "Close together, instant connection",
        "Tap and Scan",
        "Showcase Your Brand",
        "Save unlimited contacts on one card.",
        "Business or personal profile for life with a dedicated web name",
      ],
    },
    {
      id: 3,
      imglink: Keyproducts3,
      name: "Business reward program",
      list: [
        "Business reward with offers",
        "Cross promotions for members",
        "Digital vouchers",
        "Redeem offers",
        "An influx of new leads",
        "Increased brand awareness",
        "Increased sales",
        "Lower advertising costs",
        "Improved reputation.",
      ],
    },
    {
      id: 4,
      imglink: Keyproducts4,
      name: "Refer & Earn",
      list: [
        "Refer and earn per membership.",
        "Cash rewards",
        "Additional source of passive income",
        "Refer friends or families",
        "Audience growth;",
      ],
    },
  ];

  return (
    <>
      <section className="ProductsAndServices_sec">
        <div className="cust_container">
          <div className="mainwrap">
            <h2 className="pageheading">Products and services</h2>

            <div className="vdo_main">
              <video poster width="100%" ref={videoref}>
                <source src={video} type="video/mp4"></source>
              </video>

              <div className="vdo_btn">
                <button className="btn_play" onClick={togglePlayPause}>
                  {isplaying ? (
                    <i class="fa-solid fa-pause"></i>
                  ) : (
                    <i class="fa-solid fa-play"></i>
                  )}
                </button>
              </div>
            </div>

            <p className="textpara">
              Your products and services need to attract attention, so create
              profiles and share them with current customers and your target
              audience in the right way. Innovation is advertised as much as new
              products. For that, and to meet new people and get instant
              connects, neoo search will be very useful for you.
            </p>

            <h4 className="pagesub_heading">Key products and services.</h4>

            {Keyproducts.map((items, index) => (
              <div className="Keyproducts_card" key={index}>
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-5 col-12 leftside">
                    <div className="imagewrap">
                      <img
                        src={items.imglink}
                        className="img-fluid"
                        alt="..."
                      />
                    </div>
                    <h6 className="image_name">{items.name}</h6>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-7 col-12">
                    
                    <ul className="Keyproducts_list" >
                      {items?.list?.map((item, innerIndex) => (
                          <li>{item}</li>
                      ))}
                    </ul>

                    {index == 0 && (
                      <Link to="#" className="Companyprofile_link">
                        Company profile{" "}
                        <i className="fa-solid fa-arrow-right-long"></i>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsAndServices;
