import React from 'react'
import bannImg from "../../Images/heir_referral.png"

const ReferAndEarnBanner = () => {
  return (
    <>
        <section className='ReferAndEarnBanner_sec'>
            <div className='cust_container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <div className='content_wrap'>
                            <h2 className='heading'>Refer and Earn up to <span>AED 6000</span> every month.</h2>

                            <p className='text'>You can earn for every successful registration by
                             inviting and referring your friends or family members who are looking
                              for their business promotions. <a href='#'>(T&C apply)</a></p>

                            <p className='text'>If you are a Neoo search member, you will get your
                             referral link from your dashboard.</p>

                            <button className='Getyourlink_btn'>Get your link</button>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <div className='bannerimg_wrap'>
                            <img src={bannImg} className='img-fluid' alt='...' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ReferAndEarnBanner