import React from 'react'
import icon1 from "../../Images/explore/technology1.png"
import icon2 from "../../Images/explore/technology2.png"
import icon3 from "../../Images/explore/technology3.png"
import icon4 from "../../Images/explore/technology4.png"

const InnovativeTechnology = () => {
    const technocard = [
        { id: 1, imglink: icon1, name: "Profile", },
        { id: 2, imglink: icon2, name: "Share", },
        { id: 3, imglink: icon3, name: "Connect", },
        { id: 4, imglink: icon4, name: "Rewards", },
    ];
    
    return (
        <>
            <section className='innovativetechnology_sec'>
                <div className='cust_container'>
                    <h2 className='heding'>Neoo search Innovative technology meets strong network..</h2>
                    <p className='text'>Share and exchange the business information you want when you meet new people in real life—with just one tap!</p>
                    <div className='row'>
                        {technocard.map((items, index) => (
                            <div className='col-lg-3 col-md-6 col-6' key={index}>
                                <div className='technology_card'>
                                    <div className='icon_wrap'>
                                        <img src={items.imglink} className='img-fluid' alt='...' />
                                    </div>
                                    <p className='name'>{items.name}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
        </>
    )
}

export default InnovativeTechnology