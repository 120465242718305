import React from 'react'
import "./Withdraw.css";
import WithdrawTopHeading from './WithdrawTopHeading';
import EarnedBalanceTransfer from './EarnedBalanceTransfer';
import TransactionhistoryHome from './TransactionhistoryHome';

const WithdrawHome = () => {
    return (
        <>
            <div className='Withdraw_home'>
                <div className='cust_container'>
                    <WithdrawTopHeading />
                    <EarnedBalanceTransfer />
                    <TransactionhistoryHome />
                </div>
            </div>
        </>
    )
}

export default WithdrawHome