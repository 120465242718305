import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { getOwnProfile } from "../Redux/Slice/PostSlice";

function ExchangedYourContactModal({UserProfileID}) {
 

  return (
    <>
      <section className="ExchangedYourContactModal">
        <div className="ExchangedYourContact_body">
          <div className="icondiv">
            <i className="fa-solid fa-circle-check"></i>
            <span>Success !</span>
          </div>

          <h4 className="heading">Exchanged Your Contacts.</h4>
          {UserProfileID?.memberStatus && (
            <p className="text">
              You earned 500 reward point.
              <br /> Add more contacts and earn more point.
            </p>
          )}

          <div className="done_btn">
            <Link to="/" className="btn">
              Done
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default ExchangedYourContactModal;
