import React, { useEffect, useRef } from "react";

const ChatBody = ({ chatHistory, OwnId, renderMessage, deleteMessage }) => {
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    // Automatically scroll to the bottom when chat history updates
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  return (
    <div className="chat-container" ref={containerRef}>
      <div className="message-inner-container">
        {chatHistory?.map((item, i) => (
          <div key={i}>
            {(i === 0 || chatHistory[i - 1].timestamp !== item.timestamp) && (
              <div className="messagedate_box">
                <time className="date">{item?.timestamp}</time>
              </div>
            )}
            <div
              className={`message-box ${
                item?.senderID === OwnId ? "my-message" : "friend-message"
              }`}
            >
              <p className="message-text">
                {/* Render the message content */}
                {renderMessage(item, i, chatHistory)}

                <div className="d-flex align-items-center justify-content-end">
                  <span>{item?.time}</span>
                  {item?.senderID === OwnId && (
                    <span className="ml-2">
                      <i className="fa-solid fa-check"></i>
                      {item?.seen && (
                        <i
                          className="fa-solid fa-check"
                          style={{ marginLeft: "0px" }}
                        ></i>
                      )}
                    </span>
                  )}
                </div>
              </p>
            </div>
          </div>
        ))}
        {/* Ensure scrolling to the bottom */}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ChatBody;
