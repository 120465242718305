import React from 'react'

const WhatNeooSearchbusiness = () => {
    return (
        <>
            <section className='WhatNeooSearchbusiness_sec'>
                <div className='mainwrap'>
                    <h6 className='whatis'>What is Neoo search business reward cards ?</h6>
                    <ul className='whatis_list'>
                        <li>It is a dual-benefit program for Neoo search cardholders.</li>
                        <li>Instant discounts and offers with partners</li>
                        <li>Reward Points Collection</li>
                        <li>Promoting your business</li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default WhatNeooSearchbusiness