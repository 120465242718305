import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SearchModal, addSearch } from "../Redux/Slice/PostSlice";

function SearchBarmodal() {
  const { inputSearch } = useSelector((state) => state.app);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(addSearch(searchTerm));
    navigate("/Owncontactlist", { state: inputSearch });
    setSearchTerm(inputSearch);
    // dispatch(SearchModal(true));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission
      const currentSearchTerm = searchTerm; // Save the current value of searchTerm
      dispatch(addSearch(currentSearchTerm)); // Dispatch the action with the current searchTerm
      navigate("/Owncontactlist", { state: currentSearchTerm }); // Navigate with the current searchTerm
      dispatch(SearchModal(false));
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(addSearch(searchTerm));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, dispatch]);

  return (
    <section className="searchBar_modal">
      <div className="cust_container">
        <div className="searchBar_content">
          <div className="inpyuj">
            <div className="searchwrap_box">
              <div
                tabIndex="0"
                className="input_container"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>

            <div
              className="ghjhhsrch"
              onClick={(e) => handleClick(e)}
              tabIndex="0"
              role="button"
            >
              <i className="fa-solid fa-magnifying-glass" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SearchBarmodal;
