import React from 'react'
import Delete from "../../src/Images/Delete.png";
import crosssimgiocn from "../../src/Images/crosssimgiocn.png";
import bannerimgeditget from "../../src/Images/bannerimgeditget.png"
const Editbannerimagemodal = ({ closemodal }) => {
    return (
        <div className='uploabannermodalbgshadow'>
            <div className='editabannermodalkbg'>
                <div className='editabannerideletcrrsdivhjdsj'>
                    <div className='diesdrddeytecroosdivffzl'>
                        <div className='dettetstyyiocnnhui'>
                            <img src={Delete} alt='...' />
                        </div>

                        <div className='dettetstyyiocnnhui' onClick={() => closemodal()}>
                            <img src={crosssimgiocn} alt='...' />
                        </div>
                    </div>

                    <div className=''>
                        <p className='edittsbannerttxtppp'>Edit Banner Image</p>
                    </div>
                </div>
                <div className='bannerimgeditgetmain'>
                    <img src={bannerimgeditget} alt='...' />
                </div>
                <div className='browoefilesabbddivflx'>
                    <button className='brwdffioevbtgbjhbtn'>
                        <div className='inkspottsrrdivdiv'>
                            <input type='file' className='inkspottsrrdiv' />
                        </div>
                        Browse File

                    </button>
                    <button className='svavtatvvbtnnm'>Save</button>
                </div>
            </div>
        </div>
    )
}

export default Editbannerimagemodal
