import React, { useState } from 'react'
import editicon from "../../Images/editicon.png";
import galleryimg from "../../Images/galleryimg.png";
import productdesign from "../../Images/productdesign.png";
import Advertising from "../../Images/Advertising.png";
import DigitalMarketing from "../../Images/DigitalMarketing.png";
import Photography from "../../Images/Photography.png"
import Galleryeditmodal from '../../Modal/Galleryeditmodal';
import Editgalleryproductmodal from '../../Modal/Editgalleryproductmodal';
import Otherproductmodal from '../../Modal/Otherproductmodal';
const Galleryblmprofile = () => {
    const [galleymodala, setGalleymodala] = useState(false);
    const [editshoeopen, setEditshoeopen] = useState(false);
    const [editgallerproductmodal,setEditgallerproductmodal]=useState(false)
    const [beautyproductmodal, setBeautyproductmodal] = useState(false)
    const productmodalotherclcik = () => {
        setBeautyproductmodal(!beautyproductmodal)
    }
    const gallerclickmodal = () => {
        setGalleymodala(!galleymodala)
    }

    const edishowclickmm = () => {
        setEditshoeopen(!editshoeopen)
    }

    const editgalleryssclick=()=>{
        setEditgallerproductmodal(!editgallerproductmodal)
    }
    return (
        <>

            <div className='uploadbannerblmdiv'>
                <div className='uploadbannerdivnewmain'>
                    <div className="consttactdetatlsarroedyrdiv">
                        <p className="cinnattddttdtsttspo">Gallery</p>
                        <div className="editeiconimg" onClick={edishowclickmm}>
                            <img
                                src={editicon}
                                alt="..."
                            />
                        </div>
                    </div>
                    <div className='uploadimagedivflx'>
                        <div className='uploadimagebg'  >
                            <div className='uploadimagebgmain' >

                                {editshoeopen ?

                                    <div className="editeiconimghideenalda"  onClick={editgalleryssclick}>
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </div>

                                    :


                                    <div className="editeiconimghideen" >
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </div>
                                }


                                <div className='cameralogodivimgabslt' onClick={gallerclickmodal}>
                                    <div className='cameralogodivimg'>
                                        <img src={galleryimg} alt='...' />
                                    </div>
                                    <p className='uplsdttxttppshji' >Upload image</p>
                                </div>

                                <div className='gagelttysimgdivmain'>
                                    <img src={productdesign} alt='...' />
                                </div>
                            </div>
                            <p className="imagetitrtlstrtrxpp" onClick={productmodalotherclcik}>Product design</p>
                        </div>

                        <div className='uploadimagebg'  >
                            <div className='uploadimagebgmain'>

                                {editshoeopen ?

                                    <div className="editeiconimghideenalda"  onClick={editgalleryssclick}>
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </div>

                                    :


                                    <div className="editeiconimghideen" >
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </div>
                                }
                                <div className='cameralogodivimgabslt'>
                                    {/* <div className='filedibbhsjj'>
                            <input type='file' className='filedibbhsjjdiflediv'/>
                        </div> */}
                                    <div className='cameralogodivimg'>
                                        <img src={galleryimg} alt='...' />
                                    </div>
                                    <p className='uplsdttxttppshji'>Upload image</p>

                                </div>
                                <div className='gagelttysimgdivmain'>
                                    <img src={Advertising} alt='...' />
                                </div>
                            </div>

                            <p className="imagetitrtlstrtrxpp" onClick={productmodalotherclcik}>Advertising</p>
                        </div>

                        <div className='uploadimagebg'  >
                            <div className='uploadimagebgmain'>

                                {editshoeopen ?

                                    <div className="editeiconimghideenalda" onClick={editgalleryssclick}>
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </div>

                                    :


                                    <div className="editeiconimghideen" >
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </div>
                                }
                                <div className='cameralogodivimgabslt'>
                                    {/* <div className='filedibbhsjj'>
                            <input type='file' className='filedibbhsjjdiflediv'/>
                        </div> */}
                                    <div className='cameralogodivimg'>
                                        <img src={galleryimg} alt='...' />
                                    </div>
                                    <p className='uplsdttxttppshji'>Upload image</p>

                                </div>
                                <div className='gagelttysimgdivmain'>
                                    <img src={DigitalMarketing} alt='...' />
                                </div>
                            </div>

                            <p className="imagetitrtlstrtrxpp" onClick={productmodalotherclcik}>Digital marketing</p>
                        </div>

                        <div className='uploadimagebg'  >
                            <div className='uploadimagebgmain'>

                                {editshoeopen ?

                                    <div className="editeiconimghideenalda" onClick={editgalleryssclick}>
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </div>

                                    :


                                    <div className="editeiconimghideen" >
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </div>
                                }
                                <div className='cameralogodivimgabslt'>
                                    {/* <div className='filedibbhsjj'>
                            <input type='file' className='filedibbhsjjdiflediv'/>
                        </div> */}
                                    <div className='cameralogodivimg'>
                                        <img src={galleryimg} alt='...' />
                                    </div>
                                    <p className='uplsdttxttppshji'>Upload image</p>

                                </div>
                                <div className='gagelttysimgdivmain'>
                                    <img src={Photography} alt='...' />
                                </div>
                            </div>

                            <p className="imagetitrtlstrtrxpp" onClick={productmodalotherclcik}>Photography</p>
                        </div>
                    </div>
                </div>
            </div >

            {galleymodala && <Galleryeditmodal closemodal={setGalleymodala} />}
            {editgallerproductmodal && <Editgalleryproductmodal closemodal={setEditgallerproductmodal} />}
            {beautyproductmodal && <Otherproductmodal closemodal={setBeautyproductmodal}/>}

        </>
    )
}

export default Galleryblmprofile
