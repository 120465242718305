import React, { useEffect, useRef } from "react";
import "../Chatpage/Chat.css";
import TotalChatList from "./TotalChatList";
import SingleChatArea from "./SingleChatArea";
import { useDispatch, useSelector } from "react-redux";
import { SingleChat, chatList } from "../../Redux/Slice/Chat";
import NoChat from "./NoChat";

const ChatIndex = () => {
  const dispatch = useDispatch();
  const { chatListData, singleChatData, RecieverID } = useSelector(
    (state) => state?.chat
  );

  

  // Reference to the container that should be scrolled
  const chatContainerRef = useRef(null);

  // Scroll to top of the chat container when component mounts
  useEffect(() => {
    // Scroll the container horizontally to the start
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollLeft = 0;
    }

    // Check initial screen size when component mounts
    const checkScreenSize = () => {
      if (window.innerWidth > 767) {
        dispatch(chatList(true));
        dispatch(SingleChat(true));
      } else if (window.innerWidth <= 767 && RecieverID) {
        dispatch(chatList(false));
        dispatch(SingleChat(true));
      } else {
        dispatch(chatList(true));
        dispatch(SingleChat(false));
      }
    };

    checkScreenSize();

    // Add event listener for window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, [dispatch, RecieverID, window.innerWidth]);

  return (
    <>
      <section className="chatpage_sec">
        <div className="cust_container">
          <div className="main-container" ref={chatContainerRef}>
            {chatListData && <TotalChatList />}
            {window.innerWidth <= 767 || RecieverID ? (
              singleChatData && <SingleChatArea />
            ) : (
              <NoChat />
            )}
            {/* <NoChat /> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ChatIndex;
