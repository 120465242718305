import React from 'react'

const PeopleAcceleratorProgram = () => {
    return (
        <>
            <section className='PeopleAcceleratorProgram_sec'>
                <div className='cust_container'>
                    <h4 className='heading'>People accelerator program  ?</h4>

                    <p className='text'>Neoo Search is a business networking application. This platform will be quite valuable for individuals who run a business and wish to promote it. As a result, members will benefit significantly from the Neoo Search Rewards Program. Through our cross-promotion, you can meet and create relationships with all Neoo Search members. Our business rewards program will help you take your partnership to the next level.</p>
                    <p className='text'>Meet or invite new people, and exchange contacts through Neoo search and earn points.  – the more you spend, the greater the reward. <br />Points can also be earned on a range of Neoo search members profile activities, including Likes, rating, profile download, products views... and as your points tally grows, so does your membership status – through Silver, Gold and Platinum tiers.</p>

                    <div className='row mt-5'>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <p className='text'>Real, visible, significant, and recognition <br /> <br /> Our engagement solutions are intended to bring delight, raise morale, develop a culture of recognition, and improve motivation and focus, all while making users feel valued.</p>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <p className='text'>Crafted relevant local rewards. <br /> <br /> We provide a wide range of awards that are consistent with your company principles and relevant to your worldwide user base. Local rewards for an international audience.</p>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default PeopleAcceleratorProgram