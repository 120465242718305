import React, { useState } from "react";
import Background_bg from "../../Images/explore/explore_bg.png";
import pricetag from "../../Images/explore/pricetag.png";
import launchicon from "../../Images/explore/launch.png";
import callicon from "../../Images/Call.png";
import whatsicon from "../../Images/whatsapp.png";
import manimg from "../../Images/explore/explore_man.png";
import thikicon from "../../Images/explore/thik.png";
import Slider from "react-slick";
import JoinNowModal from "../../Modal/JoinNowModal";
import JoinNowSuccessModal from "../../Modal/JoinNowSuccessModal";

const ExploreBanner = () => {
  const [modalA, setModalA] = useState(false);
  const [modalB, setModalB] = useState(false);

  const bannerslider = [{ id: 1 }, { id: 2 }, { id: 3 }];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  const openPopup = () => {
    setModalA(!modalA);
  };
  const opensuccessPopup = () => {
    setModalA(false);
    setModalB(!modalB);
  };
  return (
    <>
      <Slider {...settings}>
        {bannerslider.map((items, index) => (
          <div key={index}>
            <section
              className="ExploreBanner_sec"
              style={{ backgroundImage: `url(${Background_bg})` }}
            >
              <div className="cust_container">
                <div className="content">
                  <h2 className="heading">Neoo search Smart business pack</h2>
                  <div className="price_tag">
                    <h4 className="headline">ALL 3 FOR ONE PRICE </h4>
                    <div className="ctrbox">
                      <img src={pricetag} className="img-fluid" alt=".." />
                    </div>
                  </div>

                  <div className="launch_wrap">
                    <div className="d-flex align-items-center">
                      <span className="launch_icon">
                        <img src={launchicon} className="img-fluid" alt="..." />
                      </span>
                      <h4 className="head">Launch Offer 50% Off </h4>
                    </div>

                    <ul className="list">
                      <li>
                        <span>
                          <img src={thikicon} alt=".." />
                        </span>
                        SMART NFC BUSINESS CARD
                      </li>

                      <li>
                        <span>
                          <img src={thikicon} alt=".." />
                        </span>
                        SMART NFC TABLE TALKER
                      </li>
                      <li>
                        <span>
                          <img src={thikicon} alt=".." />
                        </span>
                        SMART BUSINESS DIRECTORY
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="banner_bottom">
                <div className="cust_container">
                  <p className="para">
                    This offer is only valid for the First 100 customers
                  </p>
                  <div className="contact_wrap">
                    <button className="getnow_btn" onClick={openPopup}>
                      <span>
                        <i className="fa-solid fa-angle-right"></i>
                      </span>
                      Get Now!
                    </button>
                    <p className="number">
                      <span>
                        <img src={callicon} alt=".." />
                      </span>
                      <span>
                        <img src={whatsicon} alt=".." />
                      </span>
                      0562027229
                    </p>
                  </div>
                </div>
              </div>

              <div className="manimg_wrap">
                <img src={manimg} className="img-fluid" alt=".." />
              </div>
            </section>
          </div>
        ))}
      </Slider>
      {modalA && (
        <JoinNowModal
          closepopup={setModalA}
          onclick={() => {
            opensuccessPopup();
          }}
        />
      )}
      {modalB && <JoinNowSuccessModal closepopup2={setModalB} />}
    </>
  );
};

export default ExploreBanner;
