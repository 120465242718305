import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { chatList, SingleChat } from "../../Redux/Slice/Chat";
import { db } from "../../Firebase/FirebaseConfig";
import { ref, update, get } from "firebase/database";
import logootherblm from "../../Images/logootherblm.png";

const ChatHeader = ({
  RecieverDetails,
  RecieverTypingStatus,
  statusData,
  deleteChatForUser,
  OwnId,
  RecieverID,
  isBlocked,
  whoBlocked,
}) => {
  const dispatch = useDispatch();
  const [isOpenmenu, setIsOpenmenu] = useState(false);
  const [isArchived, setIsArchived] = useState(false); // Local state for archived status
  const menuRef = useRef(null); // Create a ref for the menu

  // Fetch the current archived status of the chat from Firebase
  const fetchArchivedStatus = async () => {
    try {
      const chatRef = ref(db, `chatList/${OwnId}/${RecieverID}`);
      const snapshot = await get(chatRef);
      const data = snapshot.val();
      if (data) {
        setIsArchived(data.isArchived || false);
      }
    } catch (error) {
      console.error("Error fetching archived status:", error);
    }
  };

  useEffect(() => {
    fetchArchivedStatus();
  }, [RecieverID, OwnId]);

  const handleClick = () => {
    if (window.innerWidth < 767) {
      dispatch(chatList(true));
      dispatch(SingleChat(false));
    }
  };

  const toggleSubmenu = () => {
    setIsOpenmenu(!isOpenmenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpenmenu(false); // Close the menu if clicked outside
    }
  };

  useEffect(() => {
    if (isOpenmenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenmenu]);

  const archiveChat = async (chatId, archiveValue) => {
    if (typeof chatId !== "string") {
      console.error("Invalid chatId:", chatId);
      return;
    }

    const chatRef = ref(db, `chatList/${OwnId}/${chatId}`);

    try {
      await update(chatRef, { isArchived: archiveValue });
      console.log("Chat archived successfully");
      setIsArchived(archiveValue); // Update local state
    } catch (error) {
      console.error("Error archiving chat:", error);
    }
  };

  const blockedChat = (chatId, block) => {
    if (typeof chatId !== "string") {
      console.error("Invalid chatId:", chatId);
      return;
    }

    const chatRef = ref(db, `chatList/${OwnId}/${chatId}`);

    const updateData = block
      ? { isBlocked: true, whoBlocked: OwnId }
      : { isBlocked: false, whoBlocked: null };

    update(chatRef, updateData)
      .then(() => {
        console.log("Chat blocked/unblocked successfully");
      })
      .catch((error) => {
        console.error("Error blocking chat:", error);
      });
  };

  return (
    <div className="header">
      <div className="d-flex align-items-center">
        <button type="button" className="chatback_btn" onClick={handleClick}>
          <i className="fa-solid fa-arrow-left"></i>
        </button>
        <div className="img-text">
          <div
            className="user-img"
            style={{
              border:
                statusData?.state === "online" ? "2px solid #4aad29" : "none",
            }}
          >
            <img
              className="dp"
              src={RecieverDetails?.profile_picture || logootherblm}
              alt="Profile"
            />
          </div>
          <h4>
            {RecieverDetails?.personName}
            <br />
            <span>
              {RecieverTypingStatus?.[0]?.isTyping
                ? "typing..."
                : statusData?.state}
            </span>
          </h4>
        </div>
      </div>

      <div className="nav-icons">
        <li onClick={toggleSubmenu}>
          <i className="fa-solid fa-ellipsis-vertical"></i>
          {isOpenmenu && (
            <div className="dropsubmenu_box" ref={menuRef}>
              <ul className="list">
                {/* <li>
                  <button className="btn">View profile</button>
                </li> */}
                <li>
                  <button
                    className="btn"
                    onClick={() => archiveChat(RecieverID, !isArchived)}
                  >
                    {isArchived ? "Remove from Archive" : "Move to Archive"}
                  </button>
                </li>
                <li>
                  {isBlocked ? (
                    whoBlocked === OwnId ? (
                      <button
                        onClick={() => blockedChat(RecieverID, false)} // Unblock chat
                        className="btn"
                      >
                        Remove from Block chat
                      </button>
                    ) : null // If blocked by someone else, no button is shown
                  ) : (
                    <button
                      onClick={() => blockedChat(RecieverID, true)} // Block chat
                      className="btn"
                    >
                      Block chat
                    </button>
                  )}
                </li>
                <li>
                  <button className="btn" onClick={deleteChatForUser}>
                    Clear chat
                  </button>
                </li>
              </ul>
            </div>
          )}
        </li>
      </div>
    </div>
  );
};

export default ChatHeader;
