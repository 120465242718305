import React, { useContext, useEffect, useState } from 'react'
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import vectorarrow from "../../Images/vectorarrow.png";
import facebook_icon from "../../Images/facebook_icon.png";
import insta_icon from "../../Images/insta_icon.png";
import linkedin_icon from "../../Images/linkedin_icon.png"
import { Link } from 'react-router-dom';
const BusinessInfoform = () => {

    const colordata = useContext(Colorcontext);
    const [drowdowsncustom, setDrowdowsncustom] = useState(false)
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);
    const drowdowncustclick = () => {
        setDrowdowsncustom(!drowdowsncustom)
    }
    return (
        <>
            <section className='listbusinessformdivmain'>
                <div className='cust_container'>
                    <div className='listbusinessdivflx'>
                        <div className='listbusinessdivleft'>
                            <div className='listbusinessdivleftbg'>
                                <p className='conattaindptxtpp'>Business information</p>

                                <div className='contatcindofdicfplx'>
                                    <div className='contatcindofdiinpur'>
                                        <p className='contatcindofdiinpurtxtp'> Type of business<span> *</span></p>
                                        <div className='dropwdowndivfrmbusinee'>
                                            <p className='vectoraarowdivomgulmaili'>
                                                Advertising
                                            </p>
                                            <div className='vectoraarowdivomg' onClick={drowdowncustclick}>
                                                <img src={vectorarrow} alt='...' />
                                            </div>
                                            {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                                <ul className='vectoraarowdivomgulmaiul'>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Advertising
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Advertising
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Advertising
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Advertising
                                                    </li>
                                                    <li className='vectoraarowdivomgulmaili'>
                                                        Advertising
                                                    </li>
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className='contatcindofdiinptextareediv'>
                                        <p className='contatcindofdiinpurtxtp'>About the Business  <span>* </span></p>
                                        <textarea cols={4} rows={4} className='inpustxocntbusuitxtaretpp' />
                                        <p className='worrsrrttxtpphu'>100-250 words</p>
                                    </div>
                                </div>

                                <div className=''>
                                    <p className='contatcindofdiinpurtxtp'>Products/Services categories<span> *</span></p>
                                    <div className='contatcindofdicfplx'>

                                        <div className='contatcindofdiinpur'>

                                            <div className='dropwdowndivfrmbusinee'>
                                                <p className='vectoraarowdivomgulmaili'>
                                                    Select your service
                                                </p>
                                                <div className='vectoraarowdivomg' onClick={drowdowncustclick}>
                                                    <img src={vectorarrow} alt='...' />
                                                </div>
                                                {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                                    <ul className='vectoraarowdivomgulmaiul'>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                    </ul>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className='contatcindofdiinpur'>

                                            <div className='dropwdowndivfrmbusinee'>
                                                <p className='vectoraarowdivomgulmaili'>
                                                    Select your service
                                                </p>
                                                <div className='vectoraarowdivomg' onClick={drowdowncustclick}>
                                                    <img src={vectorarrow} alt='...' />
                                                </div>
                                                {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                                    <ul className='vectoraarowdivomgulmaiul'>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                    </ul>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className='contatcindofdiinpur'>

                                            <div className='dropwdowndivfrmbusinee'>
                                                <p className='vectoraarowdivomgulmaili'>
                                                    Select your service
                                                </p>
                                                <div className='vectoraarowdivomg' onClick={drowdowncustclick}>
                                                    <img src={vectorarrow} alt='...' />
                                                </div>
                                                {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                                    <ul className='vectoraarowdivomgulmaiul'>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                    </ul>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className='contatcindofdiinpur'>

                                            <div className='dropwdowndivfrmbusinee'>
                                                <p className='vectoraarowdivomgulmaili'>
                                                    Select your service
                                                </p>
                                                <div className='vectoraarowdivomg' onClick={drowdowncustclick}>
                                                    <img src={vectorarrow} alt='...' />
                                                </div>
                                                {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                                    <ul className='vectoraarowdivomgulmaiul'>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                        <li className='vectoraarowdivomgulmaili'>
                                                            Select your service
                                                        </li>
                                                    </ul>
                                                </div>}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className=''>
                                    <p className='contatcindofdiinpurtxtp'>Product/Services key workds<span> </span></p>
                                    <div className='contatcindofdicfplx'>
                                        <div className='contatcindofdiinpur'>

                                            <input type='text' placeholder='KeyWord' className='inpustxocntbusuitxtpp' />
                                        </div>
                                        <div className='contatcindofdiinpur'>

                                            <input type='text' placeholder='KeyWord' className='inpustxocntbusuitxtpp' />
                                        </div>
                                        <div className='contatcindofdiinpur'>

                                            <input type='text' placeholder='KeyWord' className='inpustxocntbusuitxtpp' />
                                        </div>
                                        <div className='contatcindofdiinpur'>

                                            <input type='text' placeholder='KeyWord' className='inpustxocntbusuitxtpp' />
                                        </div>
                                    </div>
                                </div>

                                <div className=''>
                                    <p className='contatcindofdiinpurtxtp'>Social links<span> </span></p>
                                    <div className='contatcindofdicfplx'>
                                        <div className='contatcindofdiinpur'>
                                            <Link className='icopndibimahhmainimjhdibmai' to="https://www.facebook.com/neoosearch">
                                                <div className='icopndibimahhmainimjh'>
                                                    <img src={facebook_icon} alt='...' />

                                                </div>
                                                <p className='faccevttxttpphhtxtpp'>www.facebook.com/neoosearch</p>

                                            </Link>
                                        </div>
                                        <div className='contatcindofdiinpur'>
                                            <Link className='icopndibimahhmainimjhdibmai' to="https://www.instagram.com/neoo_search/">
                                                <div className='icopndibimahhmainimjh'>
                                                    <img src={insta_icon} alt='...' />

                                                </div>
                                                <p className='faccevttxttpphhtxtpp'>www.instagram.com/neoo_search</p>
                                            </Link>
                                        </div>
                                        <div className='contatcindofdiinpur'>
                                            <Link className='icopndibimahhmainimjhdibmai' to="https://www.linkedin.com/in/neoo-search-72677432/">
                                                <div className='icopndibimahhmainimjh'>
                                                    <img src={linkedin_icon} alt='...' />

                                                </div>
                                                <p className='faccevttxttpphhtxtpp'>www.linkedin.com/in/neoo-search-72677432</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='btnnexttdivbtndivmain'>
                                    <Link className='btnnexttdivbtn' to="/searchloginform">Next</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default BusinessInfoform
