import React, { useState } from "react";
// import StarRatings from './react-star-ratings';
import StarRatings from "react-star-ratings";
import HttpClient from "../utils/HttpClient";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getSingleUserProfile } from "../Redux/Slice/PostSlice";

function GiveRattingModal({ closemodal, UserID }) {
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();

  console.log(rating, "userid");

  const handleRatingChange = (newRating) => {
    // Update the rating state when a user gives a new rating
    setRating(newRating);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      id: UserID,
      rating: rating,
    };

    const res = await HttpClient.requestData("give-rating", "PUT", data);
    if (rating < 1) {
      toast.error("Please Provide a valid rating point");
      return;
    }

    if (res && res?.status) {
      toast.success("Your Rating has been submitted successfully!");
      closemodal(false);
      dispatch(getSingleUserProfile(UserID));
    } else {
      toast.error("Unable to give rating");
    }
  };
  return (
    <>
      <section className="giveratting_modal">
        <div className="giverattingmodal_body">
          <div className="close_btn">
            <button className="btn" onClick={() => closemodal(false)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <h4 className="heding">Give Rating</h4>

          <div className="rating_box">
            <StarRatings
              rating={rating}
              starRatedColor="#F3660B"
              starEmptyColor="grey"
              numberOfStars={5}
              name="UserProfileRating" // Set to a generic name for product ratings
              starDimension="20px"
              starSpacing="5px"
              className="fa-regular fa-star"
              changeRating={handleRatingChange} // Call handleRatingChange when the rating changes
            />
          </div>

          <div className="submit_btn">
            <button
              type="button"
              className="btn"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default GiveRattingModal;
