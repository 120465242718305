import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PartnersOwnOffer } from "../../Redux/Slice/Offer";
import { getOwnProfile } from "../../Redux/Slice/PostSlice";

function VenderDetails() {
  const dispatch = useDispatch();
  const { PartnersOwnOfferData } = useSelector((state) => state.offer);
  const { UserProfileID } = useSelector((state) => state.app);
  console.log(PartnersOwnOfferData, "venderDetails");
  console.log(UserProfileID, "UserProfileID");

  useEffect(() => {
    dispatch(PartnersOwnOffer());
    dispatch(getOwnProfile());
  }, []);

  return (
    <>
      <section className="VenderDetails_sec">
        <ul className="list">
          <li>
            <p>
              <span>Partner name:</span>{" "}
              <strong>{PartnersOwnOfferData?.[0]?.partnerName}</strong>
            </p>
            <p>
              <span>Offer category:</span>{" "}
              <strong>
                {PartnersOwnOfferData?.[0]?.offerCat?.categoryName}
              </strong>
            </p>
          </li>
          <li>
            <p>
              <span>Type of offer:</span>{" "}
              <strong>{PartnersOwnOfferData?.[0]?.offerType}</strong>
            </p>
            <p>
              <span>Location:</span> <strong>{UserProfileID?.location} - {UserProfileID?.country}</strong>
            </p>
          </li>
        </ul>
      </section>
    </>
  );
}

export default VenderDetails;
