import React, { useState, useEffect } from "react";
import facebook from "../Images/Facebook.png";
import whatsapp from "../Images/Whatsapp Icon.png";
import mssngr from "../Images/Messenger.png";
import linkedin from "../Images/Linkedin.png";
import xcom from "../Images/Xcom.png";
import email from "../Images/Email.png";
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  InstapaperIcon,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useDispatch } from "react-redux";
import { ShareModalonOff } from "../Redux/Slice/PostSlice";

const ProfileShare = ({
  whatsApp,
  linkedInLink,
  facebookLink,
  userLink,
  instaLink,
  emailId,
}) => {
  const [copiedStatus, setCopiedStatus] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let timeoutId;
    if (copiedStatus) {
      timeoutId = setTimeout(() => {
        setCopiedStatus(false);
      }, 3000); // Reset copied status after 3 seconds
    }
    return () => clearTimeout(timeoutId);
  }, [copiedStatus]);

  const handleCopyClick = () => {
    setCopiedStatus(true);
  };

  //send email
  const sendEmail = () => {
    window.open(`mailto:${emailId}`);
    const userEmail = emailId; // Replace with the user's email address
    const subject = userLink; // Customize as needed
    const body = "Body of the email"; // Customize as needed
    const separator = " - "; // Customize as needed
    const emailLink = `mailto:${userEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = emailLink;
  };

  const loop = [
    { id: 1, name: "Facebook", img: facebook },
    { id: 2, name: "Whatsapp", img: whatsapp },
    { id: 3, name: "Messenger", img: mssngr },
    { id: 4, name: "Linkedin", img: linkedin },
    { id: 5, name: "Twitter", img: xcom },
    { id: 6, name: "Email", img: email },
  ];

  const closeModal = () => {
    dispatch(ShareModalonOff(false));
  };

  return (
    <>
      <section className="prflShrModSec">
        <div className="prflshrModInn">
          <div className="ModClosebtn" onClick={() => closeModal()}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <div className="prflShrIcon">
            {loop.map((item) => (
              <div className="prflshrDiv" key={item.id}>
                {/* <figure className="prflshrFig">
                  {item.name === "Whatsapp" ? (
                    <ReactWhatsapp number={whatsApp} message={userLink}>
                      <img src={item.img} alt="img" />
                    </ReactWhatsapp>
                  ) : item.name === "Facebook" ? (
                    <FacebookShareButton url={facebookLink}>
                      <img src={item.img} alt="img" />
                    </FacebookShareButton>
                  ) : item.name === "Twitter" ? (
                    <a
                      href={item.name === "Twitter" ? instaLink : userLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.img} alt="img" />
                    </a>
                  ) : item.name === "Email" ? (
                    <button
                      type="button"
                      onClick={sendEmail}
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.img} alt="img" />
                    </button>
                  ) : item?.name === "Linkedin" ? (
                    <LinkedinIcon url={linkedInLink} title={userLink} size={32} round={true}>
                      <img src={item.img} alt="img" />
                    </LinkedinIcon>
                  ): item?.name === "Messenger" ? (
                    <LinkedinIcon url={linkedInLink} title={userLink} size={32} round={true}>
                      <img src={item.img} alt="img" />
                    </LinkedinIcon>
                  )  
                  : (
                    ""
                  )}
                </figure> */}
                {/* <p className="prflshrnm">{item.name}</p> */}
              </div>
            ))}

            <div className="m-auto">
              <WhatsappShareButton
                title="Check out this link -"
                url={userLink}
                className="mx-4"
              >
                <img src={whatsapp} alt="img" />
              </WhatsappShareButton>
              <FacebookShareButton
                url={userLink}
                hashtag={`Check out this link: ${userLink}`}
                className="mx-4 px-4"
              >
                <img src={facebook} alt="img" />
              </FacebookShareButton>
              <TwitterShareButton url={userLink} className="mx-4">
                {" "}
                <img src={xcom} alt="img" />
              </TwitterShareButton>
            </div>

            <br />
            <div className="m-auto">
              <LinkedinShareButton
                url={userLink}
                source={userLink}
                title={userLink}
                summary={userLink}
                className="mx-4"
              >
                <img src={linkedin} alt="img" />
              </LinkedinShareButton>
              <FacebookMessengerShareButton
                url={userLink}
                source={userLink}
                title={userLink}
                summary={userLink}
                className="mx-4 px-4"
              >
                <img src={mssngr} alt="img" />
              </FacebookMessengerShareButton>
              {/* <EmailShareButton
              subject={userLink}
              body={userLink}
              separator=" - "
              url={userLink}
              className="mx-4"
            >
              <img src={email} alt="img" />
            </EmailShareButton> */}
              <button
                type="button"
                onClick={sendEmail}
                // target="_blank"
                rel="noopener noreferrer"
                className="mx-4 border-0"
              >
                <img src={email} alt="img" />
              </button>
            </div>
          </div>

          <div className="copyUrlDiv">
            <Link className="copyUrlLink">{userLink}</Link>
            <CopyToClipboard text={userLink}>
              <button className="copyUrlBtn" onClick={handleCopyClick}>
                {copiedStatus ? "Copied" : "Copy"}
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileShare;
