import { Html5QrcodeScanner } from 'html5-qrcode';
import React, { useEffect, useState } from 'react'

export const QrCamera = ({ setCamera }) => {

    const [scanResult, setscanResult] = useState(null)

    useEffect(() => {
        const scaner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250
            },
            fps: 5,
        })

        scaner.render(success, error);

        function success(result) {
            scaner.clear();
            setscanResult(result)
        }
        function error(err) {
            console.warn(err)
        }
    }, [])



    return (
        <div className="QRCodeScanner_modal">
            <div className='QRCodeScannermodal_body'>
                <button className='close_btn' onClick={() => setCamera(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
                <div className='content'>
                    <h4 className='heading'>QR Code Scanner</h4>

                    {
                        scanResult ? <div className='Scannerbox_wrap'> Success :
                            <a href={scanResult}>{scanResult}</a>
                        </div> :
                            <div id="reader"></div>
                    }
                </div>
            </div>
        </div>
    )
}
