import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Reward/Rewards.css";
import Profile from "../../Images/prfl.png";
import logoC from "../../Images/neoologo-c.png";
import logow from "../../Images/logo-w.png";
import pfp from "../../Images/prfl.png";
import qrcode from "../../Images/qr-code.jpg";
import cardimg from "../../Images/morereward.jpeg";
import { Link } from "react-router-dom";
import icon from "../../Images/offer2.png";
import icon2 from "../../Images/Partners2.png";
import redeemicon from "../../Images/giftbox.png";
import icontext1 from "../../Images/Group 70.png";
import icontext2 from "../../Images/Group 69.png";
import icontext3 from "../../Images/Group 71.png";
import icontext4 from "../../Images/Group72.png";
import icontext5 from "../../Images/Group 286.png";
import { Helmet } from "react-helmet";
import TopredeemedOffers from "./TopredeemedOffers";
import { useDispatch, useSelector } from "react-redux";
import { getOwnProfile } from "../../Redux/Slice/PostSlice";
import { OurPartNerAllOffer } from "../../Redux/Slice/Offer";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function Index() {
  const dispatch = useDispatch();
  const { UserProfileID, status } = useSelector((state) => state.app);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };

  console.log(UserProfileID, "UserProfileID");
  console.log(status, "dfdfdfdsef");
  useEffect(() => {
    dispatch(getOwnProfile());
  }, []);

  useEffect(() => {
    console.log("OurPartNerAllOffer");
    dispatch(OurPartNerAllOffer());
  }, []);
  return (
    <>
      <div className="cust_container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Neoo Search Rewards Page</title>
        </Helmet>

        <div className="reward_wrapflex row">
          <div className="left col-lg-7 col-md-6 col-12">
            <section className="rewards_sec">
              <div className="">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12 bg_color">
                    <div className="neooPoint_wrap">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="">
                          <h4>Welcome</h4>
                          <h6>{UserProfileID.personName}</h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="img_wrap">
                            <img
                              src={UserProfileID.profilePhoto}
                              className="img-fluid"
                              alt="..."
                            />
                          </div>
                          <div className="point_box">
                            <p>Neoo points</p>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>{UserProfileID?.walletPoints?.points}</p>
                              <Link to="/reward-points-summary">
                                <i className="fa-regular fa-circle-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {status == "loading" ? (
                      <SkeletonTheme
                        // highlightColor="#67308F"
                        highlightColor="white"
                        duration={1}
                      >
                        <div className="rewards_card">
                          <Skeleton height={200} style={{ width: "100%" }} />
                        </div>{" "}
                      </SkeletonTheme>
                    ) : (
                      <div
                        className={`rewards_card ${
                          UserProfileID?.rewardPoints >= 1500 &&
                          UserProfileID?.rewardPoints < 3000
                            ? "silver"
                            : UserProfileID?.rewardPoints >= 3000 &&
                              UserProfileID?.rewardPoints <= 5000
                            ? "Gold"
                            : UserProfileID?.rewardPoints > 5000
                            ? "Plat"
                            : ""
                        }`}
                      >
                        <div className="logoarea">
                          {UserProfileID?.rewardPoints <= 1500 ? (
                            <img src={logoC} className="img-fluid" alt=".." />
                          ) : (
                            <img src={logow} className="img-fluid" alt=".." />
                          )}
                          <span>/ Rewards</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-around">
                          <div className="profile">
                            <div className="pic">
                              <img
                                src={UserProfileID.profilePhoto}
                                className="img-fluid"
                                alt="..."
                              />
                            </div>
                            <h4>{UserProfileID.personName}</h4>
                            <p>{UserProfileID.SLNo}</p>
                          </div>
                          <div className="Qr_code">
                            <img
                              src={UserProfileID.qrCode}
                              className="img-fluid"
                              alt="...."
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-12 col-md-12 col-12 bg_color">
                    <div className="moreReward_slider">
                      <Slider {...settings}>
                        <div className="moreReward_card">
                          <img src={cardimg} className="img-fluid" alt="..." />
                          <div className="content row">
                            <div className="col-md-4 col-sm-4 col-3"></div>
                            <div className="col-md-8 col-sm-8 col-9 m-auto">
                              <h4 className="head">
                                Add more & Get more rewards
                              </h4>
                              <p className="text">
                                As you meet more companies, your business grows,
                                and you get more offers with Neoo search rewards
                                points.
                              </p>

                              <div className="viewmore_btn">
                                <Link
                                  to="/reward-points-summary"
                                  className="btn"
                                >
                                  View more
                                  <span>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="moreReward_card">
                          <img src={cardimg} className="img-fluid" alt="..." />
                          <div className="content row">
                            <div className="col-md-4 col-sm-4 col-3"></div>
                            <div className="col-md-8 col-sm-8 col-9 m-auto">
                              <h4 className="head">
                                Add more & Get more rewards
                              </h4>
                              <p className="text">
                                As you meet more companies, your business grows,
                                and you get more offers with Neoo search rewards
                                points.
                              </p>

                              <div className="viewmore_btn">
                                <Link
                                  to="/reward-points-summary"
                                  className="btn"
                                >
                                  View more
                                  <span>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="moreReward_card">
                          <img src={cardimg} className="img-fluid" alt="..." />
                          <div className="content row">
                            <div className="col-md-4 col-sm-4 col-3"></div>
                            <div className="col-md-8 col-sm-8 col-9 m-auto">
                              <h4 className="head">
                                Add more & Get more rewards
                              </h4>
                              <p className="text">
                                As you meet more companies, your business grows,
                                and you get more offers with Neoo search rewards
                                points.
                              </p>

                              <div className="viewmore_btn">
                                <Link
                                  to="/reward-points-summary"
                                  className="btn"
                                >
                                  View more
                                  <span>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="Offersbox_sec">
              <div className="">
                <div className="row">
                  {/* OFFERES */}
                  <div className="col-lg-6 col-md-6 col-6">
                    <Link to="/offers-categories">
                      <div className="offers_card">
                        <div className="d-flex justify-content-between">
                          <p className="text">Offers</p>
                          <div className="icon">
                            <img src={icon} className="img-fluid" alt="..." />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {/* OUR PARTNERS */}
                  <div className="col-lg-6 col-md-6 col-6">
                    <Link to="/our-partners">
                      <div className="offers_card">
                        <div className="d-flex justify-content-between">
                          <p className="text">Partners</p>
                          <div className="icon">
                            <img src={icon2} className="img-fluid" alt="..." />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            <div
              className=""
              style={{
                backgroundColor: "#fff",
                padding: "15px 15px 20px",
                borderRadius: "6px",
              }}
            >
              <Link to="/redeemyour-points">
                <section className="redeemyourneoo_sec">
                  <div className="redeemyourneoo_card">
                    <div className="row m-0">
                      <div className="col-md-8 col-8 m-auto">
                        <h4 className="text">
                          Redeem your <br /> Neoo search Points
                        </h4>
                      </div>
                      <div className="col-md-4 col-4 m-auto">
                        <div className="icon">
                          <img
                            src={redeemicon}
                            className="img-fluid"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </Link>

              <section className="rewardlist_sec">
                <div className="">
                  <ul>
                    <li>
                      <span className="">
                        <img src={icontext1} className="img-fluid" alt=".." />
                      </span>
                      <p>About Neoo search rewards</p>
                      <Link to="/rewards-about">
                        <i className="fa-solid fa-angle-right"></i>
                      </Link>
                    </li>

                    <li>
                      <span className="">
                        <img src={icontext5} className="img-fluid" alt=".." />
                      </span>
                      <p>How earning rewards</p>
                      <Link to="/how-earning-rewards">
                        <i className="fa-solid fa-angle-right"></i>
                      </Link>
                    </li>

                    <li>
                      <span className="">
                        <img src={icontext2} className="img-fluid" alt=".." />
                      </span>
                      <p>How work rewards</p>
                      <Link to="/how-work-rewards">
                        <i className="fa-solid fa-angle-right"></i>
                      </Link>
                    </li>

                    <li>
                      <span className="">
                        <img src={icontext4} className="img-fluid" alt=".." />
                      </span>
                      <p>Neoo search reward cards</p>
                      <Link to="/">
                        <i className="fa-solid fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <span className="">
                        <img src={icontext3} className="img-fluid" alt=".." />
                      </span>
                      <p>How redeem</p>
                      <Link to="/how-redeem">
                        <i className="fa-solid fa-angle-right"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </div>

          <div className="right col-lg-5 col-md-6 col-12">
            <TopredeemedOffers />
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
