import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import icn from "../../Images/gift-icon.png";
import comimg1 from "../../Images/offerimg/Planet.png";
import comimg2 from "../../Images/offerimg/Resto.png";
import comimg3 from "../../Images/offerimg/Thumbay Hospital.png";
import comimg4 from "../../Images/offerimg/Planet.png";
import GiftvoucherModal from "../../Modal/GiftvoucherModal";
import { useDispatch, useSelector } from "react-redux";
import { OwnWalletDetails } from "../../Redux/Slice/Offer";

function MyWalletPage() {
  const [voucherModal, seVoucherModal] = useState(false);
  const [QRModalImage, setQRModalImage] = useState("");
  // All wallet data
  const { WalletData } = useSelector((state) => state.offer);
  // filtered data of non purchased voucher , doint it for future reference if needed
  const nonPurchaseWalletOffer = WalletData?.filter(
    (item) => !item?.voucherReedem
  );
  const dispatch = useDispatch();
  console.log(nonPurchaseWalletOffer, "nonPurchaseWalletOffer");
  const handleClick = (item) => {
    seVoucherModal(!voucherModal);
    setQRModalImage(item);

    console.log(item, "item");
  };

  useEffect(() => {
    dispatch(OwnWalletDetails());
  }, []);

  const activities = [
    {
      id: 1,
      activities: "Kids Activities",
      src: comimg1,
      free: "Free",
      ticket: "2 Entry ticket",
      code: "LO2SZBB2SNBE5T9T",
      Valid: "Valid for 48 Hrs",
    },
    {
      id: 2,
      activities: "Dining",
      src: comimg2,
      free: "Free",
      ticket: "Dinner for 2 person",
      code: "LO2SZBB2SNBE5T9T",
      Valid: "Valid for 48 Hrs",
    },
    {
      id: 3,
      activities: "Kids Activities",
      src: comimg3,
      free: "Free",
      ticket: "Free Dental consultation for 2 person",
      code: "LO2SZBB2SNBE5T9T",
      Valid: "Valid for 48 Hrs",
    },
    {
      id: 4,
      activities: "Kids Activities",
      src: comimg4,
      free: "Free",
      ticket: "2 Entry ticket",
      code: "LO2SZBB2SNBE5T9T",
      Valid: "Valid for 48 Hrs",
    },
  ];

  return (
    <>
      <section className="mywallet_page">
        <div className="cust_container">
          <div className="top_area">
            <Link to="/reward" className="backbtn">
              <i className="fa-solid fa-arrow-left-long"></i>
            </Link>
            <h4 className="heading">My Wallet</h4>

            <Link to="/my-wallet">
              <div className="icon">
                <img src={icn} className="img-fluid" alt="....." />
                {/* {nonPurchaseWalletOffer?.length > 0 && (
                  <span className="notification">
                    {nonPurchaseWalletOffer?.length}
                  </span>
                )} */}
              </div>
            </Link>
          </div>

          {/* <div className="row mt-4">
            {activities.map((item, index) => (
              <div className="col-lg-6 col-md-12 col-12 mb-4" key={index}>
                <div
                  className="walletactivities_card"
                  onClick={() => handleClick(item)}
                >
                  <div className="leftstyle"></div>
                  <div className="rightstyle"></div>

                  <div className="left_wrap">
                    <h6 className="head">{item.activities}</h6>

                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="img_wrap">
                        <img src={item.src} className="img-fluid" alt="image" />
                      </div>
                    </div>
                  </div>
                  <div className="right_wrap">
                    <h4 className="free">{item.free}</h4>
                    <h6 className="ticket">{item.ticket}</h6>
                    <p className="code">{item.code}</p>
                    <p className="Valid">
                      <i className="fa-regular fa-clock mr-1"></i> {item.Valid}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
          {WalletData?.length > 0 ? (
            <div className="row mt-4">
              {WalletData?.map((item, index) =>
                !item?.voucherReedem && (
                  <div className="col-lg-6 col-md-12 col-12 mb-4" key={index}>
                    <div
                      className="walletactivities_card"
                      onClick={() => handleClick(item)}
                    >
                      <div className="leftstyle"></div>
                      <div className="rightstyle"></div>

                      <div className="left_wrap">
                        <h6 className="head">{item?.Offer?.offerType}</h6>

                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="img_wrap">
                            <img
                              src={item?.Offer?.partnerLogo}
                              className="img-fluid"
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="right_wrap">
                        <h6 className="free mb-5">{item?.Offer?.offerCatData?.categoryName}</h6>
                        {/* <h6 className="ticket">2 Entry ticket</h6> */}
                        <p className="code">{item?.offerCode}</p>
                        <p className="Valid">
                          <i className="fa-regular fa-clock mr-1"></i>Valid for{" "}
                          {item?.Offer?.voucherValidity} Hrs
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          ) : (
            //need SHILTU
            <h5>You Dont have any Offer in your wallet.</h5>
          )}
        </div>
      </section>

      {voucherModal && (
        <GiftvoucherModal
          closemodal={seVoucherModal}
          QRModalImage={QRModalImage}
        />
      )}
    </>
  );
}

export default MyWalletPage;
