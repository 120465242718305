import React, { useState } from 'react'
import peopleadd from "../../Images/peopleadd.png";
import emojiwavinghandsign from "../../Images/emojiwavinghandsign.png";
import Frame from "../../Images/Frame.png";
import framelike from "../../Images/framelike.png";
import profle from "../../Images/profle.png";
import whatsapp from "../../Images/whatsapp.png";
import "./Invitation.css";
import Rectangle from "../../Images/Rectangle.png"
import { Link } from 'react-router-dom';
import prdct from "../../Images/prdct.png";
import pdf from "../../Images/pdf.png";
import call from "../../Images/Call.png";
import ProductModal from '../../Modal/ProductModal';
import QRshareModal from '../../Modal/QRshareModal';
import coverpic from "../../Images/cover img.jpg";

const Invitation = () => {
    const [contactdetail, setContactdetail] = useState(true);
    const [aboutdetail, setAboutdetail] = useState(true);
    const [servicedetail, setServicedetail] = useState(true);
    const [productdetail, setProductdetail] = useState(true);
    const [servicepdfdetail, setServicepdfdetail] = useState(true);
    const [modala, setModala] = useState(false);
    const [showmodal, setShowmodal] = useState(false);

    const handledetailClick = () => {
        setContactdetail(!contactdetail)
    }
    const handleaboutClick = () => {
        setAboutdetail(!aboutdetail)
    }
    const handleserviceClick = () => {
        setServicedetail(!servicedetail)
    }
    const handleproductClick = () => {
        setProductdetail(!productdetail)
    }
    const handleservicepdfClick = () => {
        setServicepdfdetail(!servicepdfdetail)
    }
    const handlemodalClick = () => {
        setModala(!modala);
    }
    const modhandleclick = () => {
        setShowmodal(!showmodal);
    }

    const productdetails = [
        {
            image: prdct,
            text: "Product name",
            para: "Lorem Ipsum has been the industry's standard",
        },
        {
            image: prdct,
            text: "Product name",
            para: "Lorem Ipsum has been the industry's standard",
        },
        {
            image: prdct,
            text: "Product name",
            para: "Lorem Ipsum has been the industry's standard",
        },
        {
            image: prdct,
            text: "Product name",
            para: "Lorem Ipsum has been the industry's standard",
        }
    ]
    return (
        <>
            <div className='neoobg'>
                <div className='cust_container'>
                    <div className='bg-wht'>
                        <section className='mainbgsavecontact'>
                            <div className='mainsavecontact'>
                                <div className='deartxtmain'>
                                    <div className='emojuwavetxt'>
                                        <img src={emojiwavinghandsign} alt="..." />
                                        <span className='jsmrtxt'>Dear Jasim Noor,</span>
                                    </div>
                                    <div className='txtdearmain'>
                                        <p className='txtdear'>Welcome to Neoo Card Business Directory, you have new contact in your directory</p>
                                    </div>
                                </div>
                                <div className='savebtn'>
                                    <Link to={'/Owncontactlist'} className='savecontactbtn'>
                                        Save contact
                                        <span className='cntntimg'><img src={peopleadd} alt="..." /></span>
                                    </Link>
                                </div>
                            </div>
                        </section>
                        <div className='bggradient'>
                            <img src={coverpic} alt="img" />
                        </div>
                        <div className='persondetailsdiv'>
                            <div className='img_profileflx'>
                                <div className='imgbrdr'>
                                    <div className='imgmain'>
                                        <img src={profle} alt="..." />
                                    </div>
                                </div>
                                <div className='dtlsprflnmmph'>
                                    <p className='profiledtlstxt'>Sophia John</p>
                                    <p className='prodtlmarkettxt'>Marketing Executive</p>
                                </div>
                            </div>
                            <div className='likespinerdivflx'>

                                <div className='d-flex' style={{ gap: '8px' }}>
                                    <Link className='likespiner'>
                                        <img src={framelike} alt='...' />
                                        <span className=''>100</span>
                                    </Link>

                                    <Link to='/Owncontactlist' className='likespiner'>
                                        <img src={Frame} alt="..." />
                                        <span className=''>100</span>
                                    </Link>
                                </div>

                                <div className='d-flex' style={{ gap: '5px' }}>
                                    <Link to={"/"} className='whatsppimg'>
                                        <img src={whatsapp} alt="..." />
                                    </Link>

                                    <Link to={"/"} className='callimg'>
                                        <img src={call} alt="..." />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='infolabbrdr'>
                            <div className='infoflx'>
                                <div className='rctdivimg' onClick={modhandleclick}>
                                    <img src={Rectangle} alt="QR" />
                                </div>
                                <div className='groupdiv'>
                                    <h4 className='ocentxt'>Ocean infolab FZE</h4>
                                    <p className='pcndgtxt'>Digital Marketing</p>
                                    <p className='dbytxtp'>Dubai l United Arab Emirates</p>
                                </div>
                            </div>
                        </div>

                        <div className='socialdivflx'>
                            <Link className='bgsocial'>
                                <i className="fa-brands fa-facebook-f"></i>
                            </Link>
                            <Link className='bgsocial'>
                                <i className="fa-brands fa-instagram"></i>
                            </Link>
                            <Link className='bgsocial'>
                                <i className="fa-brands fa-linkedin-in"></i>
                            </Link>
                            <Link className='bgsocial'>
                                <i className="fa-brands fa-youtube"></i>
                            </Link>
                            <Link className='bgsocial'>
                                <i className="fa-solid fa-basketball"></i>
                            </Link>
                        </div>

                        <div className="cntntbrdr">
                            <div className='flxcntnt'>
                                <p className='cntncttxt'>Contact Details</p>
                                <div className='ArrwiconDiv' onClick={handledetailClick}>
                                    <span className={contactdetail ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></span>
                                </div>
                            </div>
                            {contactdetail && <div className=''>
                                <div className='divcnctflx'>
                                    <div className='brdrdivdtls'>
                                        <span className='txtdtlmn'>
                                            <i className="fa-solid fa-phone"></i>
                                            +971 6 7427316
                                        </span>
                                    </div>
                                    <div className='brdrdivdtls'>
                                        <span className='txtdtlmn'>
                                            <i className="fa-solid fa-mobile-screen-button"></i>
                                            +971 6 7427316
                                        </span>
                                    </div>
                                    <div className='brdrdivdtls'>
                                        <span className='txtdtlmn'>
                                            <i className="fa-brands fa-whatsapp"></i>
                                            +971 6 7427316
                                        </span>
                                    </div>
                                </div>
                                <div className='dibmailflx'>
                                    <div className='envelopdiv'>
                                        <span className='txtdtlmn'>
                                            <i className="fa-solid fa-envelope"></i>
                                            hamsascreativee@gmail.com
                                        </span>
                                    </div>
                                    <div className='envelopdiv'>
                                        <span className='txtdtlmn'>
                                            <i className="fa-solid fa-globe"></i>
                                            www.neoocard.com
                                        </span>
                                    </div>
                                </div>
                                <div className='divloctin'>
                                    <span className='txtdtlmn'>
                                        <i className="fa-solid fa-location-dot"></i>
                                        Business Center, Sharjah Publishing City Free Zone Dubai. United Arab Emirates
                                    </span>
                                </div>

                                {/* <div className='mapwidth'>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14728.727405628732!2d88.33364660000001!3d22.64700745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1706076991023!5m2!1sen!2sin"
                                        height={350}
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />

                                </div> */}

                                <div className="getdrctnBtnDiv">
                                    <Link to='https://www.google.com/maps' className='getdirectionbtn' target='_blank'>Get Direction<i class="fa-solid fa-diamond-turn-right"></i></Link>
                                </div>
                            </div>
                            }

                        </div>

                        <div className='abtbrsdr'>
                            <div className='flxcntnt'>
                                <p className='cntncttxt'>About Us</p>
                                <div className='ArrwiconDiv' onClick={handleaboutClick}>
                                    <span className={aboutdetail ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></span>
                                </div>
                            </div>
                            {aboutdetail && <div className=''>
                                <p className='txtabtmain'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type Lorem Ipsum has been the industry's standard dummy text  when an unknown printer took a galley of type and scrambled it to make a type </p>
                            </div>}
                        </div>

                        <div className='servcbrdr'>
                            <div className='flxcntnt'>
                                <p className='cntncttxt'>Services</p>
                                <div className='ArrwiconDiv' onClick={handleserviceClick}>
                                    <span className={servicedetail ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></span>
                                </div>
                            </div>
                            {servicedetail && <div className='srvcboxbrdr'>

                                <div className='brdrmaindesign'>Graphic design</div>
                                <div className='brdrmaindesign'>Digital marketing</div>
                                <div className='brdrmaindesign'>Publishing</div>
                                <div className='brdrseo'>SEO</div>


                                <div className='brdrseosms'>SMS Marketing</div>
                                <div className='brdrprnt'>Printing</div>
                                <div className='brdrbrand'>Branding</div>

                            </div>
                            }
                        </div>

                        <div className='prdrtbrdr'>
                            <div className='flxcntnt'>
                                <p className='cntncttxt'>Products</p>
                                <div className='ArrwiconDiv' onClick={handleproductClick}>
                                    <span className={productdetail ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></span>
                                </div>
                            </div>
                            {productdetail && <div className=''>
                                <div className='prdrtflxdivmain'>
                                    {productdetails.map((item, index) => {
                                        return (
                                            <div className='brdrprdctdiv' key={index} onClick={handlemodalClick}>
                                                <div className='prdcgimg'>
                                                    <img src={item.image} alt="..." />
                                                </div>
                                                <div className='textparamain'>
                                                    <p className='txtdiv'>{item.text}</p>
                                                    <p className='txtpara'>{item.para}</p>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    
                                </div>
                                {/* <div className='vdoighh'>
                                    <iframe
                                        width={560}
                                        height={315}
                                        src="https://www.youtube-nocookie.com/embed/My0jYiN5JDU"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    />
                                </div> */}
                            </div>
                            }

                        </div>

                        <div className='brdrsrvc'>
                            <div className='flxcntnt'>
                                <p className='cntncttxt'>Services</p>
                                <div className='ArrwiconDiv' onClick={handleservicepdfClick}>
                                    <span className={servicepdfdetail ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></span>
                                </div>
                            </div>
                            {servicepdfdetail &&
                                <div>
                                    <div className='vdoighh'>
                                        <iframe
                                            width={560}
                                            height={315}
                                            src="https://www.youtube-nocookie.com/embed/My0jYiN5JDU"
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        />
                                    </div>
                                    <div className='pdrmainflx'>
                                        <div className='pdfflxDiv'>
                                            <div className='pdfimg'>
                                                <img src={pdf} alt="..." />
                                                <div className='inpuduv'>
                                                    <input type="file" id="myfile" name="myfile" />
                                                </div>
                                            </div>
                                            <div className='prflmainflx'>
                                                <p className='prfltxtprdct'>Profile Name</p>
                                                <p className='prfldtlsprdct'>Lorem Ipsum has been the industry's standard</p>
                                            </div>
                                        </div>
                                        <div className='pdfflxDiv'>
                                            <div className='pdfimg'>
                                                <img src={pdf} alt="..." />
                                                <div className='inpuduv'>
                                                    <input type="file" id="myfile" name="myfile" />
                                                </div>
                                            </div>
                                            <div className='prflmainflx'>
                                                <p className='prfltxtprdct'>Profile Name</p>
                                                <p className='prfldtlsprdct'>Lorem Ipsum has been the industry's standard</p>
                                            </div>
                                        </div>
                                        <div className='pdfflxDiv'>
                                            <div className='pdfimg'>
                                                <img src={pdf} alt="..." />
                                                <div className='inpuduv'>
                                                    <input type="file" id="myfile" name="myfile" />
                                                </div>
                                            </div>
                                            <div className='prflmainflx'>
                                                <p className='prfltxtprdct'>Profile Name</p>
                                                <p className='prfldtlsprdct'>Lorem Ipsum has been the industry's standard</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                    </div>
                </div>
                {modala && <ProductModal closemodal={setModala} />}
                {showmodal && <QRshareModal closeModal={setShowmodal} />}
            </div>

        </>
    )
}

export default Invitation
