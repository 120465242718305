import React from "react";
import Group1 from "../Images/Group1.png";
import { Link, useLocation } from "react-router-dom";
import hbfhf from "../../src/Images/PWA iocn_neoosearch.png";

const Footer = () => {
  const location = useLocation();
  return (
    <>
      <div className={location.pathname === '/chat' ? 'hiddenfooter' : ''}>
        <section className="fottersection">
          <div className="cust_container">
            <div className="socialicon_wrap">
              <ul>
                <li className="icon">
                  <a href="https://www.facebook.com/neoosearch" target="_blank">
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </li>
                <li className="icon">
                  <a
                    href="https://www.instagram.com/neoo_search/"
                    target="_blank"
                  >
                    <i className="fa-brands fa-square-instagram"></i>
                  </a>
                </li>
                <li className="icon">
                  <li className="icon">
                    <a href="https://wa.me/971562027229" target="_blank">
                      <i className="fa-brands fa-square-whatsapp"></i>
                    </a>
                  </li>
                </li>
                <li className="icon">
                  <a
                    href="https://www.linkedin.com/in/neoo-search-72677432/"
                    target="_blank"
                  >
                    <i class="fa-brands fa-linkedin"></i>
                  </a>
                </li>
                <li className="icon">
                  <a href="https://x.com/neoosearch" target="_blank">
                    <i className="fa-brands fa-square-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>

            <hr />

            <div className="fopterbg">
              <div className="imgfooterprl">
                <div className="">
                  <p className="howcan">How can we help? </p>
                </div>
                <div className="">
                  <Link to="/lets-get-intouch" className="contuctus_btn">
                    Contact Us
                  </Link>
                </div>
              </div>
              <div className="rightfootercontentwrap">
                <div className="fotercfyvflx">
                  <Link to="/about-neoo-search" className="txttftrtkjh">
                    About Us
                  </Link>
                  <Link to="/products-and-services" className="txttftrtkjh">
                    Products and services
                  </Link>
                  <Link to="/reward-home-page" className="txttftrtkjh">
                    Rewards
                  </Link>
                  <Link to="/refer-and-earn" className="txttftrtkjh">
                    Refer & Earn
                  </Link>
                  <Link to="/terms-of-services" className="txttftrtkjh">
                    Terms & conditions{" "}
                  </Link>
                  <Link to="/terms-of-services" className="txttftrtkjh">
                    User Agreement
                  </Link>
                  <Link to="/privacy-policy" className="txttftrtkjh">
                    Privacy Policy
                  </Link>
                  <Link to="/businessdirectory" className="txttftrtkjh">
                    About Local Directory
                  </Link>
                </div>

                <div className="footerafbhsvbhy">
                  <img src={hbfhf} className="img-fluid" alt="..." />
                  <p className="txtppcpy">
                    Copyright © 2024 Neoo Search FZE All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;
