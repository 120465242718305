import React from 'react'
import icon1 from "../../Images/referral_icon/refer.png"
import icon2 from "../../Images/referral_icon/Signup.png"
import icon3 from "../../Images/referral_icon/earn.png"

const HereHowReferralWorks = () => {
    const referralworks = [
        { id: 1, linkimg: icon1, name: "1. Refer your friend & Families", desc: "Generate your referral link from your dashboard and send to friends or family", },
        { id: 2, linkimg: icon2, name: "2. Your friend sign up and member", desc: "Your friend signs up using your referral link and purchases the Neoo search business pack.", },
        { id: 3, linkimg: icon3, name: "3. Get cash rewards", desc: "Earn your referral amount on every purchase, which can then be sent to your bank account.", },
    ];
    return (
        <>
            <section className='HereHowReferralWorks_sec'>
                <div className='cust_container'>
                    <h2 className='heading'>Here’s how referral works</h2>

                    <div className='allreferral_works'>
                        <div className='row'>
                            {referralworks.map((items, index) => (
                                <div className='col-lg-4 col-md-4 col-6' key={index}>
                                    <div className='HowReferralWorks_card'>
                                        <div className='icon_wrap'>
                                            <img src={items.linkimg} className='img-fluid' alt='...' />
                                        </div>
                                         
                                         <h4 className='name'>{items.name}</h4>
                                         <p className='descrip'>{items.desc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className='ReferEarn_btn'>
                            <button className='btn'>Refer & Earn now</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HereHowReferralWorks