import React from 'react'
import icon from "../../Images/Refer and Earn/Refer-friends-annu.png"
import { Link } from 'react-router-dom'

const RFECreditsInner = () => {
    return (
        <>
            <section className='RFECreditsInner_sec'>
                <div className='ReferFriends_card'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-3'>
                            <div className='iconwrap'>
                                <img src={icon} className='img-fluid' alt='...' />
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-9 col-9'>
                            <h4 className='head'>Refer friends and earn credits.</h4>
                            <p className='desc'>Share your referral link with friends and earn AED 100
                                credit for each membership.</p>
                        </div>
                    </div>
                </div>
                <p className='text'>Generate and use the link below to refer your friends to sign up for a Neoo Search Business Pack! Once they do, you will receive a 20% credit for each membership.</p>

                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                        I agree to <Link to="#">Terms & Conditions</Link>
                    </label>
                </div>

                <button className='GetReferralLink_btn'>Generate link</button>

            </section>
        </>
    )
}

export default RFECreditsInner