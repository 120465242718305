import React from "react";
import { Link } from "react-router-dom";

function UpgradingModal({ closePopup, isPopupOpen }) {
  // const { closePopup } = props;
  const handlestopmodal = (event) => {
    event.stopPropagation();
  };
  return (
    <>
      <section
        className="upgradingModal_wrap"
        onClick={() => closePopup(!isPopupOpen)}
      >
        <div
          className="upgradingModal_body"
          onClick={(event) => handlestopmodal(event)}
        >
          <div className="content">
            <div className="icon">
              <i className="fa-solid fa-exclamation"></i>
            </div>

            <div className="text">
              <p>Thank you for the interest in upgrading your profile</p>
              <p>Our customer executive will be in contact with you.</p>
            </div>

            <div className="Leanrmore_btn">
              <Link to="#" className="btn">
                Learn more
              </Link>
            </div>
          </div>

          <button
            className="done_btn mb-3"
            type="submit"
            onClick={() => closePopup(!isPopupOpen)}
          >
            Done
          </button>
        </div>
      </section>
    </>
  );
}

export default UpgradingModal;
