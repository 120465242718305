import React, { useRef, useState } from "react";
import Advertising from "../../src/Images/Advertising.png";
import ErrorImage from "../Images/error.png";

const Chatcontentbigmodalown = ({ closemodal, previewMediaModal }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [mediaError, setMediaError] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleMediaError = () => {
    setMediaError(true);
  };

  return (
    <div className="uploabannermodalbgshadow">
      <div className="sihnglechatbigdbgmain" style={{ position: "relative" }}>
        {/* Close button */}
        <div className="crossdivmainmnm" onClick={() => closemodal()}>
          <i className="fa-solid fa-xmark" />
        </div>

        <div className="chatconytenybigmodalbtmm">
          <div
            className="chatconytentbigmdlimg"
            style={{ position: "relative" }}
          >
            {mediaError ? (
              <img src={ErrorImage} alt="Error" />
            ) : previewMediaModal?.type === "video" ? (
              <>
                {/* Video */}
                <video
                  ref={videoRef}
                  key={previewMediaModal?.link}
                  autoPlay
                  loop
                  muted={isMuted}
                  preload="auto"
                  playsInline
                  style={{ width: "100%", maxHeight: "500px" }}
                  onError={handleMediaError}
                >
                  <source src={previewMediaModal?.link} type="video/mp4" />
                </video>

                {/* Controls (absolute positioning) */}
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 10,
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <button
                    onClick={togglePlayPause}
                    style={{
                      padding: "10px",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {isPlaying ? (
                      <i className="fa-solid fa-pause" />
                    ) : (
                      <i className="fa-solid fa-play" />
                    )}
                  </button>
                  <button
                    onClick={toggleMute}
                    style={{
                      padding: "10px",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {isMuted ? (
                      <i className="fa-solid fa-volume-mute" />
                    ) : (
                      <i className="fa-solid fa-volume-up" />
                    )}
                  </button>
                </div>
              </>
            ) : (
              <img
                src={previewMediaModal?.link || Advertising}
                alt="Media"
                onError={handleMediaError}
                style={{
                  width: "100%",
                  maxHeight: "500px",
                  objectFit: "contain",
                  borderRadius: "5px",
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatcontentbigmodalown;
