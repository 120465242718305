import React, { useEffect, useState } from "react";
import directory from "../../Images/Frame.png";
import like from "../../Images/framelike.png";
import whatsapp from "../../Images/whatsapp.png";
import call from "../../Images/Call.png";
import { Link, useLocation } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import CustomLoader from "../../CustomComponents/loader/CustomLoader";

const NonMemberuserOtherprofile = () => {
  const [contactdetail, setContactdetail] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [UserMemberData, setUserMemberData] = useState({});
  const location = useLocation()

  console.log(location,"UserMemberData")
  const handledetailClick = () => {
    setContactdetail(!contactdetail);
  };

  const getUserProfile = async () => {
    setisLoading(true);
    const res = await HttpClient.requestData("get-profile", "GET", {});
    console.log(res, "use profiler");
    if (res && res?.status) {
      setisLoading(false);
        setUserMemberData(res?.data);
    } else {
      setisLoading(false);
    }
  };
  //Saved contactlist
  const savedContactList = async () => {
    const res = await HttpClient.requestData("member-contacts", "GET", {});
    console.log(res, "resData");
    if (res && res?.status) {
      //   setsavedContacts(res?.data);
    }
  };
  useEffect(() => {
    savedContactList();
  }, []);
  useEffect(() => {
    getUserProfile();
  }, []);
  return (
    <section className="memberuserprofile">
      <CustomLoader loading={isLoading} />
      <div className="cust_container">
        <div className="contactbg">
          <div className="contctbrdr">
            <div className="cntntflx">
              <div className="mbrtusrprflflx">
                <div className="imgbrdrmbrt">
                  {/* <img src={"https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/300px-No_image_available.svg.png"} alt="..." /> */}
                </div>
                <div className="jhjmbmrtdiv">
                  <p className="jhjtcfggytxt">{UserMemberData?.gender} {UserMemberData?.personName}</p>
                  <p className="mghtxtpp">{UserMemberData?.designation}</p>
                </div>
              </div>
              {/* <div className="upbtndivmain">
                <button className="upbtndiv">Upgrade</button>
              </div> */}
            </div>
          </div>
        </div>

        <div className="contactbg">
          <div className="drctryDiv">
            <div className="drctryDivLeft">
              <div className="d-flex align-items-center">
                <figure className="drctryDivFig">
                  <img src={like} alt="img" />
                </figure>
                <p>00</p>
              </div>

              <div className="d-flex align-items-center">
                <figure className="drctryDivFig">
                  <img src={directory} alt="img" />
                </figure>
                <p>00</p>
              </div>
            </div>

            <div className="drctryDivRght">
              <figure className="drctrCntctIcon">
                <img src={whatsapp} alt="img" />
              </figure>

              <figure className="drctrCntctIcon responsiveshow">
                <img src={call} alt="img" />
              </figure>
            </div>
          </div>
        </div>

        <div className="contactbg">
          <div className="cntntbrdr">
            <div className="flxcntnt">
              <p className="cntncttxt">Contact Details</p>
              <div className="ArrwiconDiv" onClick={handledetailClick}>
                <span
                  className={
                    contactdetail
                      ? "fa-solid fa-chevron-up"
                      : "fa-solid fa-chevron-down"
                  }
                ></span>
              </div>
            </div>
            {contactdetail && (
              <div className="">
                <div className="cntntgdtls">
                  <p className="egltxtdiv">{UserMemberData?.companyName}</p>
                  <p className="mdlfrntr">{UserMemberData?.TypeOfCompany}</p>
                  <p className="ajmtxt">{UserMemberData?.emirate} | {UserMemberData?.country}</p>
                </div>
                <div className="divcnctflx  ">
                  {/* <div className="brdrdivdtls">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-phone"></i>
                      +971 6 7427316
                    </span>
                  </div> */}
                  <div className="brdrdivdtls">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-mobile-screen-button"></i>
                      {UserMemberData?.mobileNo}
                    </span>
                  </div>
                  <div className="brdrdivdtls">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-envelope"></i>
                      {UserMemberData?.emailId}
                    </span>
                  </div>
                  <div className="brdrdivdtls">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-envelope"></i>
                      {UserMemberData?.emailId}
                    </span>
                  </div>
                  {/* <div className="brdrdivdtls">
                    <span className="txtdtlmn">
                      <i className="fa-brands fa-whatsapp"></i>
                      +971 6 7427316
                    </span>
                  </div> */}
                </div>
                <div className="dibmailflx">
                  {/* <div className="envelopdiv">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-envelope"></i>
                      {UserMemberData?.emailId}
                    </span>
                  </div> */}
                  {/* <div className="envelopdiv">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-globe"></i>
                      www.neoocard.com
                    </span>
                  </div> */}
                </div>
                {/* <div className="divloctin">
                  <span className="txtdtlmn">
                    <i className="fa-solid fa-location-dot"></i>
                    Business Center, Sharjah Publishing City Free Zone Dubai.
                    United Arab Emirates
                  </span>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NonMemberuserOtherprofile;
