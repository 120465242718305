import { createSlice } from "@reduxjs/toolkit";


const chat = createSlice({
    name: "chat",
    initialState: {
        chatListData: true,
        singleChatData: false,
        clickonTHreadData: false,
        recipentUser: "",
        RecieverID: "",
        chatThreads: [],
    },
    reducers: {
        chatList: (state, action) => {
            state.chatListData = action.payload
        },
        SingleChat: (state, action) => {
            state.singleChatData = action.payload
        },
        clickonTHread: (state, action) => {
            state.clickonTHreadData = action.payload
        },
        OnetoOneChatIdname: (state, action) => {
            state.RecieverID = action.payload
        },
        setChatThreads: (state, action) => {
            state.chatThreads = action.payload;
        },
    },
    extraReducers: (builder) => {
        // builder
    }
})


export const { chatList, SingleChat, clickonTHread, OnetoOneChatIdname, setChatThreads } = chat.actions
export default chat.reducer