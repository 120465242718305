import React, { useContext, useEffect } from 'react'
import EditProfileblm from './EditProfileblm'
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import Blmpostadd from './Blmpostadd';
import Viewersengagement from './Viewersengagement';
import Upgradeprofile from './Upgradeprofile';
import Contactdetaiilsprofile from './Contactdetaiilsprofile';
import Uploadbannerblm from './Uploadbannerblm';
import Aboutusblmprofile from './Aboutusblmprofile';
import Servicesblmprofile from './Servicesblmprofile';
import Socilallinkprofile from './Socilallinkprofile';
import Galleryblmprofile from './Galleryblmprofile';

const Ownblmprofile = () => {
    const colordata = useContext(Colorcontext);
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);
    return (
        <>
            <section className='ownblmprofiledivmaoin '>
                <div className='cust_container'>
                    <div className=' reposivecideforspecial'>
                        <div className='ownblmprofiledivleft'>
                            <div className='repsonsivedivcls'>
                                {/* For Tablet responsive code start  */}
                                <div className=' oenblmsnfidivmainleft'>
                                    <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                        <EditProfileblm />
                                    </div>

                                    <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                        <Contactdetaiilsprofile />
                                    </div>
                                </div>


                                <div className='oenblmsnfidivmainright'>
                                    <div className='oenblmsnfdrpbapbbtm responsivdedivmain '>
                                        <Blmpostadd />
                                    </div>
                                    <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                        <Viewersengagement />
                                    </div>
                                    <div className='oenblmsnfdrpbapbbtm responsivdedivmain '>
                                        <Upgradeprofile />
                                    </div>
                                    <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                        <Uploadbannerblm />
                                    </div>

                                </div>
                                {/* For Tablet responsive code  end */}
                            </div>

                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                <EditProfileblm />
                            </div>

                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                <Blmpostadd />
                            </div>

                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                <Viewersengagement />
                            </div>

                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                <Upgradeprofile />
                            </div>
                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                <Contactdetaiilsprofile />
                            </div>

                        </div>
                        <div className='ownblmprofiledivright'>
                            <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                <Uploadbannerblm />
                            </div>
                            <div className='oenblmsnfdrpbapbbtm'>
                                <Aboutusblmprofile />
                            </div>

                            <div className='oenblmsnfdrpbapbbtm'>
                                <Servicesblmprofile />
                            </div>

                            <div className='oenblmsnfdrpbapbbtm'>
                                <Galleryblmprofile />
                            </div>
                            <div className='oenblmsnfdrpbapbbtm'>
                                <Socilallinkprofile />
                            </div>
                        </div>
                    </div>


                    {/* Mobile Screen Design Start */}

                    <div className="mobilereposiveprofile">
                        <div className='bannerprofileblmreposnive'>
                            <Uploadbannerblm />
                        </div>
                        <div className='mobilereposvispsicaldivflx'>
                            <div className='eidteprofileblmreposnive'>
                                <EditProfileblm />
                            </div>

                            <div className='viewsprofileprofileblmreposnive'>
                                <div className=''>
                                    <Viewersengagement />
                                </div>
                                <div className='bklmlposprofileprofileblmreposnive'>
                                    <Blmpostadd />
                                </div>

                                <div className=''>
                                    <Upgradeprofile />
                                </div>
                            </div>
                        </div>
                        <div className='contaallprofileblmreposnive'>
                            <Contactdetaiilsprofile />
                        </div>
                        <div className='contaallprofileblmreposnive'>
                        <Aboutusblmprofile />
                        </div>
                        <div className='contaallprofileblmreposnive'>
                        <Servicesblmprofile />
                        </div>
                        <div className='contaallprofileblmreposnive'>
                        <Galleryblmprofile />
                        </div>
                        <div className='contaallprofileblmreposnive'>
                        <Socilallinkprofile />
                        </div>
                    </div>

                    {/* Mobile Screen Design End */}
                </div>
            </section>


        </>
    )
}

export default Ownblmprofile
