import React, { useEffect, useState } from "react";
import "./Home.css";
import { Link, useNavigate } from "react-router-dom";
import homefig from "../../Images/Home Figure.png";
// import homefig1 from "../../Images/homefig1.png";
// import homefig2 from "../../Images/homefig2.png";
import homefig3 from "../../Images/homefig3.png";
import homefig1 from "../../Images/card.png";
import homefig2 from "../../Images/mobile.png";
import HomeHeader from "../../Layout/HomeHeader";
import Aos from "aos";
import "aos/dist/aos.css";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import toast from "react-hot-toast";

function LandingPage() {

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);
    return (
        <>
            <section className="homeBtm">
                <div className="cust_container">
                    <div className="homeDiv">
                        <div className="homeLeft">
                            <div className="homeContent">
                                <h3 className="homebighead">
                                    Welcome To Our Business Community
                                </h3>
                                <h5 className="homeSmallHead">
                                    MEET YOUR CUSTOMERS SMARTLY & BOOST YOUR BUSINESS DIGITALLY
                                </h5>
                                <h6 className="homepara">
                                    We help people connect with opportunities. Join our people
                                    accelerator program <Link to="/people-accelerator-program">Know more</Link>
                                </h6>
                            </div>

                            <div className="landingpage_allbtn">
                                <Link to="/user-login" className="homeloginBtn" style={{marginLeft: "0"}}>Log In</Link>
                                <Link to="/explore" className="landingExplore_btn" >Explore</Link>
                            </div>
                        </div>

                        {/* <figure className='Homefig' data-aos="fade-right" data-aos-duration="2000">
                            <img src={homefig} alt="img" />
                        </figure> */}

                        <div className="homeStruc">
                            <div className="homeStrucInn"></div>
                            <figure
                                className="HomeFigOne homimg"
                                data-aos="fade-up-right"
                                data-aos-duration="1000"
                            >
                                <img src={homefig1} alt="img" />
                            </figure>
                            <figure
                                className="HomeFigTwo homimg"
                                data-aos="fade-in"
                                data-aos-duration="1500"
                            >
                                <img src={homefig2} alt="img" />
                            </figure>
                            {/* <figure
                  className="HomeFigThree homimg"
                  data-aos="fade-in"
                  data-aos-duration="1500"
                >
                  <img src={homefig3} alt="img" />
                </figure> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LandingPage