import React from "react";
import annouimg from "../../Images/explore/annouimg.png";
import { Link } from "react-router-dom";

const MarketingGrowth = () => {
  return (
    <>
      <section className="MarketingGrowth_sec">
        <div className="cust_container">
          <div className="MarketingGrowth_card">
            <div className="left_wrap">
              <img src={annouimg} className="img-fluid" alt=".." />
            </div>
            <div className="right_wrap">
              <h3 className="head">
                Marketing Growth Strategies for Your Organization
              </h3>
              <p className="text">
                We help people connect with opportunities. Join our people
                accelerator program
              </p>

              <Link
                to="/people-accelerator-program"
                className="OrderNow_btn"
                style={{ justifyContent: "end" }}
              >
                <button className="btn">
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                  Know more
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MarketingGrowth;
