import React from 'react'
import "../Explore/Explore.css"
import ExploreBanner from './ExploreBanner'
import InnovativeTechnology from './InnovativeTechnology'
import NFCbusinessCard from './NFCbusinessCard'
import WhatWeOffer from './WhatWeOffer'
import MarketingGrowth from './MarketingGrowth'
import ExploreBusinessRewards from './ExploreBusinessRewards'
import PromoteMembersBusinesses from './PromoteMembersBusinesses'
import ReferEarnProgram from './ReferEarnProgram'

const Explore = () => {
  return (
    <>
        <ExploreBanner />
        <InnovativeTechnology />
        <NFCbusinessCard />
        <WhatWeOffer />
        <MarketingGrowth />
        <ExploreBusinessRewards />
        <PromoteMembersBusinesses />
        <ReferEarnProgram />
    </>
  )
}

export default Explore