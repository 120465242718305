import React from 'react'
import facebook_icon from "../../Images/facebook_icon.png";
import insta_icon from "../../Images/insta_icon.png";
import linkedin_icon from "../../Images/linkedin_icon.png"
const Othersociallinks = () => {
    return (
        <div className='socisllakijsnnlinkdivmain'>
            <div className='socilaimnnkotherdetsdivdivbmain'>
                <p class="cinnattddttdtsttspo">Social links</p>

                <div className='soiiajujshdijhdivfllsklodj'>
                    <div className='icopndibimahhmainimjhmewother'>
                        <img src={facebook_icon} alt='...' />

                    </div>
                    <p className='faccevttxttpphhtxtppnewoiothtrr'>Facebook </p>
                </div>
                <div className='soiiajujshdijhdivfllsklodj'>
                    <div className='icopndibimahhmainimjhmewother'>
                    <img src={insta_icon} alt='...' />

                    </div>
                    <p className='faccevttxttpphhtxtppnewoiothtrr'>Instagram </p>
                </div>
                <div className='soiiajujshdijhdivfllsklodj'>
                    <div className='icopndibimahhmainimjhmewother'>
                    <img src={linkedin_icon} alt='...' />

                    </div>
                    <p className='faccevttxttpphhtxtppnewoiothtrr'>Linkedin </p>
                </div>
            </div>
        </div>
    )
}

export default Othersociallinks
