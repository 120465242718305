import React from 'react'
import "../../View/ReferAndEarnPage/ReferAndEarn.css"
import ReferAndEarnBanner from './ReferAndEarnBanner'
import MaximiseYourEarnings from './MaximiseYourEarnings'
import HereHowReferralWorks from './HereHowReferralWorks'
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions'
import ReferAndEarnTermsConditions from './ReferAndEarnTermsConditions'

const ReferAndEarnHome = () => {
  return (
    <>
       <ReferAndEarnBanner />
       <MaximiseYourEarnings />
       <HereHowReferralWorks />
       <FrequentlyAskedQuestions />
       <ReferAndEarnTermsConditions />
    </>
  )
}

export default ReferAndEarnHome