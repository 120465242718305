import React, { useState } from 'react'
import crosssimgiocn from "../../src/Images/crosssimgiocn.png";
import vectorarrow from "../../src/Images/vectorarrow.png"
const Enqueymodal = ({ closemodal }) => {
    const [drowdowsncustom, setDrowdowsncustom] = useState(false)
    const drowdowncustclick = () => {
        setDrowdowsncustom(!drowdowsncustom)
    }
    return (
        <div className='otherprofudvtmodalbackbox'>
            <div className='enquerysmokdnndbndbg'>
                <div className="threedorttsdcoroodivflx">
                    <div className="imnmnprhhhsbdijnvvgbsj" onClick={() => closemodal()}>
                        <img src={crosssimgiocn} alt="..." />
                    </div>
                </div>

                <div class="psendwendijndwjjwndiv">
                    <p class="breyuuayproosttxppp">Send Enquery </p>
                    <div className='compamydinfodivmainbtmhuhauhdsicflx'>
                        <div className='compamydinfodivmdiobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>First Name <span> * </span></p>
                            <input type="text" placeholder="john" className="inpustxocntbusuitxtppnewallattyy" />
                        </div>

                        <div className='compamydinfodivmdiobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Mobile number  <span> * </span></p>
                            <input type="number" placeholder="9173459034" className="inpustxocntbusuitxtppnewallattyy" />
                        </div>

                        <div className='compamydinfodivmdiobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Work email <span> * </span></p>
                            <input type="text" placeholder="info@companyname.com" className="inpustxocntbusuitxtppnewallattyy" />
                        </div>

                        <div className='compamydinfodivmdiobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Type of enquiry <span> * </span></p>
                            <div className='dropwdowndivfrmbusineenewwlas'>
                                <p className='vectoraarowdivomgulmaili'>
                                    Buy/Sell
                                </p>
                                <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                    <img src={vectorarrow} alt='...' />
                                </div>
                                {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                    <ul className='vectoraarowdivomgulmaiul'>
                                        <li className='vectoraarowdivomgulmaili'>
                                            Buy/Sell
                                        </li>
                                        <li className='vectoraarowdivomgulmaili'>
                                            Buy/Sell
                                        </li>
                                        <li className='vectoraarowdivomgulmaili'>
                                            Buy/Sell
                                        </li>
                                        <li className='vectoraarowdivomgulmaili'>
                                            Buy/Sell
                                        </li>
                                        <li className='vectoraarowdivomgulmaili'>
                                            Buy/Sell
                                        </li>
                                    </ul>
                                </div>}
                            </div>
                        </div>

                        <div className='compamydinfodivmdiobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Enquiry subject <span> * </span></p>
                            <input type="text" placeholder="info@companyname.com" className="inpustxocntbusuitxtppnewallattyy" />
                        </div>

                        <div className='compamydinfodivtesxtrareobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Enquiry subject <span> * </span></p>
                            <textarea rows={4} cols={4} className='ennsjjuwtysttsxttaytredg' />
                        </div>

                        <div className='compamydinfodivtesxtrareobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Attachment <span>  </span></p>
                            <div className='chossefilkklkebdtrfdiv'>
                                <div className='enuqteyschosefllfbrdttdivdkdlnb'>


                                    <button className='enuqteyschosefllfbrdtt'>
                                        Choose file
                                    </button>
                                    <p>No file chosen</p>
                                </div>
                            </div>
                            <p className='alkoowjdiisdttxpp'>Allowed file types: JPG, PNG, PDF, Maximum allowed size is 2 MP</p>

                        </div>


                    </div>
                    <div className="svavtatvvbtnnmdivmaokin">
                        <button className="svavtatvvbtnnmsendferdgf">Send enquiry</button>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Enqueymodal
