import React, { useState } from "react";
import JoinNowModal from "../../Modal/JoinNowModal";
import JoinNowSuccessModal from "../../Modal/JoinNowSuccessModal";

const WhatWeOffer = () => {
  const [modalA, setModalA] = useState(false);
  const [modalB, setModalB] = useState(false);
  const openPopup = () => {
    setModalA(!modalA);
  };
  const opensuccessPopup = () => {
    setModalA(false);
    setModalB(!modalB);
  };
  const smartcard = [
    {
      id: 1,
      head: "SMART NFC BUSINESS CARD",
      desc: "3 Smart NFC Business cards With QR code Freely tap or Scan and get your profile and Business card.",
    },
    {
      id: 2,
      head: "SMART NFC TABLE TALKER",
      desc: "2 Smart NFC Acrylic Table Talkers With QR Code Freely Tap or Scan and get your Profile or business card.",
    },
    {
      id: 3,
      head: "SMART BUSINESS PROFILE",
      desc: "A lifetime premium membership Allow you to build a network with Verified B2B members and Get 100 leads monthly for one year.",
    },
  ];
  return (
    <>
      <section className="WhatWeOffer_sec">
        <div className="cust_container">
          <h2 className="heading">
            What we offer with the Neoo Search Business Pack
          </h2>

          <div className="row">
            {smartcard.map((items, index) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                <div className="smart_card">
                  <div className="top">
                    <h6 className="head">{items.head}</h6>
                  </div>
                  <div className="bottom">
                    <p className="desc">{items.desc}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="OrderNow_btn">
            <button className="btn" onClick={openPopup}>
              <span>
                <i className="fa-solid fa-angle-right"></i>
              </span>
              Order Now
            </button>
          </div>
        </div>
      </section>
      {modalA && (
        <JoinNowModal
          closepopup={setModalA}
          onclick={() => {
            opensuccessPopup();
          }}
        />
      )}
      {modalB && <JoinNowSuccessModal closepopup2={setModalB} />}
    </>
  );
};

export default WhatWeOffer;
