import React from 'react'
import ReferEarnHeader from './ReferEarnHeader'
import RFECreditsInner from './RFECreditsInner'
import HowToGET from './HowToGET'
import ShareYourLink from './ShareYourLink'

const GenerateLinkPage = () => {
    return (
        <>
            <div className='GenerateLink_Page'>
                <div className='cust_container'>
                    <ReferEarnHeader headname="Generate Link" backbtn={true} />
                    <RFECreditsInner />
                    <ShareYourLink />
                </div>
                <div className='cust_container'>
                    <HowToGET />
                </div>
            </div>
        </>
    )
}

export default GenerateLinkPage