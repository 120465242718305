import React from 'react'
import Delete from "../../src/Images/Delete.png";
import crosssimgiocn from "../../src/Images/crosssimgiocn.png";
import beqautyproproduct from "../../src/Images/beqautyproproduct.png"
const Editgalleryproductmodal = ({ closemodal }) => {
    return (
        <div className='edittamodalboixshadow'>
            <div className='editramoffahsgaellrttdivshhafb'>
                <div className='editabannerideletcrrsdivhjdsj'>
                    <div className='diesdrddeytecroosdivffzl'>
                        <div className='dettetstyyiocnnhui'>
                            <img src={Delete} alt='...' />
                        </div>

                        <div className='dettetstyyiocnnhui' onClick={() => closemodal()}>
                            <img src={crosssimgiocn} alt='...' />
                        </div>
                    </div>

                    <div className=''>
                        <p className='edittsbannerttxtppp'>Edit Gallery Image</p>
                    </div>
                </div>

                <div className='galleytyusysdheheiighgsccgyyukj'>
                    <div className='hahgetytsiimghbmaindibv'>
                        <img src={beqautyproproduct} alt='...' />
                        <button className='bggsttgaggsllerybanutn'>
                            <input type='file' className='ijnbnsdkpwggartyiomghghs' />
                            Browse File
                        </button>
                    </div>

                    <div className='hhsjjhahhahuyguuydibbanmnib'>
                        <div className='hhsjjhahhahuyguuy'>
                            <p className='galrrttxtittxttxtpp'>Title</p>
                            <input type='text' className='insttsbtrxrrds' />
                        </div>

                        <div className='hhsjjhahhahuyguuy'>
                            <p className='galrrttxtittxttxtpp'>Description</p>
                            <textarea rows={4} cols={4} className='insttsbtrxrrdstexrare' />
                        </div>
                        <div className='svavtatvvbtnnmdivmaokin'>
                            <button className='svavtatvvbtnnm'>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Editgalleryproductmodal
