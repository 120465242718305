import React from "react";
import facebook from "../Images/Facebook.png";
import whatsapp from "../Images/Whatsapp Icon.png";
import mssngr from "../Images/Messenger.png";
import linkedin from "../Images/Linkedin.png";
import xcom from "../Images/Xcom.png";
import email from "../Images/Email.png";
import { WhatsappShareButton } from "react-share";

const QRprofileShare = ({ closeProfile, Qrimage }) => {
  console.log(Qrimage, "url={userLink}");

  const handleShare = async (image) => {
    try {
      // Fetch the image
      const response = await fetch(image);
      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }

      // Read the image as a blob
      const blob = await response.blob();

      // Read the blob as a data URL
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const base64Data = fileReader.result.split(",")[1];
        const base64Image = `data:image/jpeg;base64,${base64Data}`;

        // Create WhatsApp share URL with base64 image
        const shareUrl = `whatsapp://send?text=&media=${encodeURIComponent(
          base64Image
        )}`;
        window.location.href = shareUrl;
      };
      fileReader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error sharing image:", error);
    }
  };

  const loop = [
    { id: 1, name: "Facebook", img: facebook },
    { id: 2, name: "Whatsapp", img: whatsapp },
    { id: 3, name: "Messenger", img: mssngr },
    { id: 4, name: "Linkedin", img: linkedin },
    { id: 5, name: "X.com", img: xcom },
    { id: 6, name: "Email", img: email },
  ];

  return (
    <>
      <section className="prflShrModSec">
        <div className="prflshrModInn">
          <div className="ModClosebtn" onClick={() => closeProfile()}>
            <i class="fa-solid fa-xmark"></i>
          </div>
          <div className="prflShrIcon">
            <div className="prflshrDiv">
              <figure className="prflshrFig">
                <WhatsappShareButton title="Check out this link -">
                  <img
                    src={whatsapp}
                    alt="WhatsApp"
                    onClick={() => handleShare(Qrimage)}
                  />
                </WhatsappShareButton>
              </figure>
              <p className="prflshrnm">{loop?.[1]?.name}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QRprofileShare;
