import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Images/logo-w.png";
import "../View/Homepage/Home.css";
import Aos from "aos";
import "aos/dist/aos.css";
import JoinNowModal from "../Modal/JoinNowModal";
import JoinNowSuccessModal from "../Modal/JoinNowSuccessModal";
import { reactLocalStorage } from "reactjs-localstorage";
import Sidebar from "../Component/Sidebar";
import NotificationBar from "../Component/NotificationBar";

const HomeHeader = () => {
  const [modalA, setModalA] = useState(false);
  const [modalB, setModalB] = useState(false);
  const [token, settoken] = useState("");
  let user = reactLocalStorage.get("userDataNeoCard");
  console.log(user, "token");
  console.log(token, "kkj");


  //sidebar menu start
  const sidebaropen = () => {
    document.querySelector(".sidebardiv").style.cssText += "right: 0";
  }
  //sidebar menu end

  const openPopup = () => {
    setModalA(!modalA);
  };
  const opensuccessPopup = () => {
    setModalA(false);
    setModalB(!modalB);
  };

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);
  useEffect(() => {
    if (user && user != null && Object.keys(user).length > 0) {
      settoken(user);
    }
  }, [user]);

  return (
    <>
      <section className="homepageHeader">
        <div className="cust_container">
          <div className="homeHeadInn">
            <Link to="/">
              <figure
                className="logoneeo"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img src={logo} alt="logo" />
              </figure>
            </Link>
            <div className="homeMenu">
              {!token && <Link to="#" className="homeJoinBtn" onClick={openPopup}>
                Join Now
              </Link>}
              {!token && <Link to="/login" className="homeactvBtn">
                Activate
              </Link>}
              <button className="homehamberger_btn" onClick={sidebaropen}>
                <i className="fa-solid fa-bars"></i>
              </button>
            </div>
          </div>
        </div>
      </section>

      {modalA && (
        <JoinNowModal
          closepopup={setModalA}
          onclick={() => {
            opensuccessPopup();
          }}
        />
      )}
      {modalB && <JoinNowSuccessModal closepopup2={setModalB} />}

      {/****sidebar ***/}
      <Sidebar />

    </>
  );
};

export default HomeHeader;
