import React, { useEffect, useState } from "react";
// import peopleadd from "../../Images/peopleadd.png";
// import emojiwavinghandsign from "../../Images/emojiwavinghandsign.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import framelike from "../../Images/framelike.png";
// import profle from "../../Images/profle.png";
import whatsapp from "../../Images/whatsapp.png";
import Frame from "../../Images/Frame.png";
// import Rectangle from "../../Images/Rectangle.png";
import QRshareModal from "../../Modal/QRshareModal";
import call from "../../Images/Call.png";
// import coverpic from "../../Images/cover img.jpg";
import verified from "../../Images/Verify responsive.png";
import HttpClient from "../../utils/HttpClient";
import toast from "react-hot-toast";
import emojiwavinghandsign from "../../Images/emojiwavinghandsign.png";
import ExchangedModal from "../../Modal/ExchangedModal";
import StarRatings from "react-star-ratings";
import { Helmet } from "react-helmet";
import { reactLocalStorage } from "reactjs-localstorage";

const initdata = {
  personName: "",
  designationID: "",
  mobileNo: "",
  companyName: "",
  companyTypeID: "",
  companyTypeName: "",
  emailId: "",
  emirate: "",
  country: "",
  countryCodeMobile: "",
  whatsappNo: "",
  countryCodewhatsappNo: "",
};

const Memberinvitation = () => {
  //Exchange popup start
  const [isExchangeOpen, setExchangeOpen] = useState(false);
  const openPopup = () => { };
  //Exchange popup end

  const userToken = reactLocalStorage.get("userDataNeoCard");
  const [contactdetail, setContactdetail] = useState(true);
  const [showmodal, setShowmodal] = useState(false);
  const [invitationProfile, setinvitationProfile] = useState([]);
  const [password, setPassword] = useState("");
  const [NonMemberProfileAdd, setNonMemberProfileAdd] = useState(initdata);

  const [Designation, setDesignation] = useState([]);
  const [Business, setBusiness] = useState([]);
  const [Country, setCountry] = useState([]);
  const [CountryWiseState, setCountryState] = useState([]);

  const navigate = useNavigate();
  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const nameRegex = /^[a-zA-Z\s]+$/;

  const params = useParams();
  console.log(params?.name, "params");
  console.log(NonMemberProfileAdd, "NonMemberProfileAdd");
  console.log(invitationProfile?.[0], "invitationProfile");
  console.log(CountryWiseState, "CountryWiseState");
  console.log(Business, "Business");
  console.log(password, "password");

  //handlechange
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNonMemberProfileAdd((prev) => ({ ...prev, [name]: value }));
  };
  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    // Ensure only positive numbers are entered
    const newValue = Math.max(0, parseInt(value));

    setNonMemberProfileAdd((prev) => ({ ...prev, [name]: newValue }));
  };

  //submit

  // const passWord = password.length >= 4 && password.length <= 15;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // alert("...")

    const isValid = NonMemberProfileAdd?.emailId.trim() !== "";
    const personName = NonMemberProfileAdd?.personName.trim();
    if (isValid || personName) {
      // toast.success("login successfully")

      if (!NonMemberProfileAdd?.personName) {
        return toast.error("Please enter your full name"); // Specific message for name
      }
      if (!NonMemberProfileAdd?.designationID) {
        return toast.error("Please select your designation"); // Message for designation
      }
      if (!NonMemberProfileAdd?.mobileNo) {
        return toast.error("Please enter your mobile number"); // Message for mobile number
      }
      if (!NonMemberProfileAdd?.whatsappNo) {
        return toast.error("Please enter your WhatsApp number"); // Optional field message
      }
      // if (!NonMemberProfileAdd?.companyName) {
      //   return toast.error("Please enter your company name"); // Message for company name
      // }
      // if (!NonMemberProfileAdd?.companyTypeID) {
      //   return toast.error("Please select your buisiness type"); // Message for company type
      // }
      // Remove duplicate designation check
      if (!NonMemberProfileAdd?.emailId) {
        return toast.error("Please enter your email address"); // Message for email
      }
      // if (!NonMemberProfileAdd?.country) {
      //   return toast.error("Please select your country"); // Message for country
      // }
      // if (!NonMemberProfileAdd?.emirate) {
      //   return toast.error("Please select your emirate"); // Message for emirate (assuming this applies)
      // }
      if (!NonMemberProfileAdd.emailId.match(mailFormat)) {
        toast.error("Invalid Email");
        return;
      }
      if (!nameRegex.test(NonMemberProfileAdd?.personName)) {
        toast.error("Invalid name. Please use only letters.");
        return;
      } else {
        // const password = `${new Date().getDate()}${new Date().getHours()}${new Date().getSeconds()}${new Date().getMilliseconds()}`;
        const data = {
          memberId: invitationProfile?.[0]?._id,
          personName: NonMemberProfileAdd?.personName,
          designationID: NonMemberProfileAdd?.designationID,
          mobileNo: NonMemberProfileAdd?.mobileNo.toString(),
          companyName: NonMemberProfileAdd?.companyName,
          companyTypeID: NonMemberProfileAdd?.companyTypeID || null,
          emailId: NonMemberProfileAdd?.emailId,
          emirate: NonMemberProfileAdd?.emirate,
          country: NonMemberProfileAdd?.country || null,
          whatsappNo: NonMemberProfileAdd?.whatsappNo.toString(),
          password: password,
          phone_code: NonMemberProfileAdd.countryCodeMobile,
          whats_code: NonMemberProfileAdd.countryCodewhatsappNo,
        };
        // console.log(data, "dataresres");
        // return
        const res = await HttpClient.requestData("non-member", "POST", data);
        console.log("resCat", res);
        if (res && res?.status) {
          // reactLocalStorage.set("userDataNeoCard", res?.data?.token);
          toast.success("Request sent Successfully");
          // setNonMemberProfileAdd(initdata);
          setExchangeOpen(!isExchangeOpen);
          getCountry();
        } else {
          toast.error(res?.message);
        }
      }
      // console.log(first)
    } else {
      toast.error("Please fill the details");
    }
  };

  //Designation
  const getDesignation = async () => {
    const res = await HttpClient.requestData("designation", "GET", {});
    console.log("Des", res);

    if (res && res?.status) {
      setDesignation(res?.data);
    }
  };
  //typeof business
  const getBuisinessType = async () => {
    const res = await HttpClient.requestData("companyType", "GET", {});
    console.log("com", res);

    if (res && res?.status) {
      setBusiness(res?.data);
    }
  };
  //country
  const getCountry = async () => {
    const res = await HttpClient.requestData("countries", "GET", {});
    console.log("rescount", res);

    if (res && res?.status) {
      setCountry(res?.data);
      setNonMemberProfileAdd({
        ...NonMemberProfileAdd,
        countryCodeMobile: res.data[0].phone_code,
        countryCodewhatsappNo: res.data[0].phone_code,
      });
    }
  };
  //state
  const getState = async () => {
    const res = await HttpClient.requestData(
      "countrywise-state/" + NonMemberProfileAdd.country,
      "GET",
      {}
    );
    console.log("counState", res);

    if (res && res?.status) {
      setCountryState(res?.data?.[0]?.states);
    }
  };
  const getCountryList = async () => {
    const res = await HttpClient.requestData("country");
  };

  const handledetailClick = () => {
    setContactdetail(!contactdetail);
  };

  const modhandleclick = () => {
    setShowmodal(!showmodal);
  };

  const sharelinkprofile = async () => {
    const data = {
      userName: params?.name,
    };
    const res = await HttpClient.requestData(
      "get-profile-via-share-link",
      "POST",
      data
    );
    console.log(res, "resgetshareLink");
    if (res && res?.status) {
      console.log("df451", userToken, data, res?.data?.[0]?.memberStatus)

      // console.log(res?.data,"resget")
      if (userToken) {
        if (data && res?.data?.[0]?.memberStatus) {
          navigate("/MemberOtherProfile/" + res?.data?.[0]?.userName?.trim(), {
            state: res?.data?.[0],
          });
        }
        // else {

        //   navigate(
        //     "/NonMemberuserOtherprofile/" + res?.data?.[0]?.personName?.trim(),
        //     {
        //       state: res?.data?.[0],
        //     }
        //   );
        // }
      } else {
        // navigate("/")
        setinvitationProfile(res?.data);
      }
    }
  };

  useEffect(() => {
    sharelinkprofile();
    getDesignation();
    getBuisinessType();
    getCountry();
  }, []);
  useEffect(() => {
    if (NonMemberProfileAdd?.companyTypeID) {
      const data = Business?.filter(
        (item) => item?._id === NonMemberProfileAdd?.companyTypeID
      );
      console.log(data, "ooojihuhuuhu");
      setNonMemberProfileAdd((prev) => ({
        ...prev,
        companyTypeName: data?.[0]?.type,
      }));
    }
  }, [NonMemberProfileAdd?.companyTypeID]);
  useEffect(() => {
    getState();
  }, [NonMemberProfileAdd.country]);
  // random password
  useEffect(() => {
    function generateString(length) {
      const numberChars = "0123456789";
      const specialChars = "!@#$&*";
      const allChars = numberChars + specialChars;

      let result = "";

      // Ensure at least one character from each category
      result += numberChars[Math.floor(Math.random() * numberChars.length)];

      // Add two random special characters
      for (let i = 0; i < 1; i++) {
        result += specialChars[Math.floor(Math.random() * specialChars.length)];
      }

      // Fill remaining characters with any character from the combined set
      for (let i = result.length; i < length; i++) {
        result += allChars[Math.floor(Math.random() * allChars.length)];
      }

      return result;
    }

    // Example usage: generate a string of length 12
    setPassword(generateString(8));
  }, []);

  return (
    <>
      <Helmet>
        {/* <title>My Page Title</title> */}
        <meta
          name="description"
          content={`Neoo Search
          Hey, I am sharing my business profile. 
         Let's exchange our contact details.`}
        />
      </Helmet>

      <div className="membrinvit">
        <div className="cust_container">
          <div className="bg-wht">
            <section className="mainbgsavecontact">
              <div className="mainsavecontact">
                <div className="deartxtmain">
                  <div className="emojuwavetxt">
                    <img src={emojiwavinghandsign} alt="..." />
                    <span className="jsmrtxt">Hi User,</span>
                  </div>
                  <div className="txtdearmain">
                    <p className="txtdear">
                      {/* Welcome to Neoo Card Business Directory, you have new
                      contact in your directory, */}
                      Welcome to Neoo Search Business
                      Network, Nice to see you today. I invite
                      you to my business community.
                      <br />
                      <span>
                        {" "}
                        {/* Please fill out the below form and exchange your contact
                        information. */}
                        Please fill out the below form and exchange
                        our contact information.
                      </span>
                    </p>
                  </div>
                </div>
                {/* <div className='savebtn'>
                                    <button className='savecontactbtn'>
                                        Save contact
                                        <span className='cntntimg'><img src={peopleadd} alt="..." /></span>
                                    </button>
                                </div> */}
              </div>
            </section>
            <div className="bggradient">
              <img src={invitationProfile?.[0]?.banner} alt="img" />
            </div>
            <div className="persondetailsdiv">
              <div className="img_profileflx">
                <div className="imgbrdr">
                  <img src={invitationProfile?.[0]?.profilePhoto} alt="..." />
                </div>
                <div className="dtlsprflnmmph">
                  <div className="left">
                    <p className="profiledtlstxt">
                      {invitationProfile?.[0]?.personName}
                    </p>
                    <p className="prodtlmarkettxt">
                      {invitationProfile?.[0]?.designation}
                    </p>
                    <div className="likeCount_all">
                      <p className="text">
                        <strong>{invitationProfile?.[0]?.totalLike}</strong>
                        <span>Likes</span>
                      </p>
                      <p className="text">
                        <strong>
                          {invitationProfile?.[0]?.totalDirectory}
                        </strong>
                        <span>Connections</span>
                      </p>
                    </div>
                  </div>
                  <div className="Verified">
                    <img src={verified} className="img-fluid" alt="icon" />
                  </div>
                </div>
              </div>

              <div className="likespinerdivflx">
                <div className="d-flex" style={{ gap: "8px" }}>
                  {/* <Link className="likespiner">
                    <img src={framelike} alt="..." />
                    <span className="">{invitationProfile?.[0]?.totalLike} Liked</span>
                  </Link> */}

                  {/* <Link to="/Owncontactlist" className="likespiner">
                    <img src={Frame} alt="..." />
                    <span className="">100</span>
                  </Link> */}
                </div>

                <div className="d-flex" style={{ gap: "5px" }}>
                  <Link to={"/"} className="whatsppimg">
                    <img src={whatsapp} alt="..." />
                  </Link>

                  <Link to={"/"} className="callimg">
                    <img src={call} alt="..." />
                  </Link>
                </div>
              </div>
            </div>

            <div className="infolabbrdr">
              <div className="infoflx">
                <div className="rctdivimg" onClick={modhandleclick}>
                  <img src={invitationProfile?.[0]?.qrCode} alt="QR" />
                </div>
                <div className="groupdiv">
                  <div>
                    <h4 className="ocentxt">
                      {invitationProfile?.[0]?.companyName}
                    </h4>
                    <p className="pcndgtxt">
                      {invitationProfile?.[0]?.companyType}
                    </p>
                    <p className="dbytxtp">
                      {invitationProfile?.[0]?.location}
                    </p>
                  </div>

                  <div className="rating_box">
                    <StarRatings
                      rating={invitationProfile?.[0]?.avgRating}
                      starRatedColor="#F3660B"
                      starEmptyColor="grey"
                      numberOfStars={5}
                      name="UserProfileRating" // Set to a generic name for product ratings
                      starDimension="18px"
                      starSpacing="2px"
                      className="fa-regular fa-star"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="socialdivflx">
              <a
                href={invitationProfile?.[0]?.facebookLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href={invitationProfile?.[0]?.instaLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href={invitationProfile?.[0]?.linkedInLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-linkedin-in"></i>
              </a>

              <a
                href={invitationProfile?.[0]?.youtubeLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a
                href={invitationProfile?.[0]?.dribbbleLink}
                target="_blank"
                className="bgsocial"
              >
                <i className="fa-solid fa-basketball"></i>
              </a>
            </div> */}

            {/* <div className="cntntbrdr"> */}
            {/* <div className="flxcntnt">
                <p className="cntncttxt">Contact Details</p>
                <div className="ArrwiconDiv" onClick={handledetailClick}>
                  <span
                    className={
                      contactdetail
                        ? "fa-solid fa-chevron-up"
                        : "fa-solid fa-chevron-down"
                    }
                  ></span>
                </div>
              </div> */}
            {contactdetail && (
              <div className="">
                {/* <div className="divcnctflx">
                    <div className="brdrdivdtls">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-phone"></i>
                        {invitationProfile?.[0]?.telephoneNo}
                      </span>
                    </div>
                    <div className="brdrdivdtls">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-mobile-screen-button"></i>
                        {invitationProfile?.[0]?.mobileNo}
                      </span>
                    </div>
                    <div className="brdrdivdtls">
                      <span className="txtdtlmn">
                        <i className="fa-brands fa-whatsapp"></i>
                        {invitationProfile?.[0]?.whatsappNo}
                      </span>
                    </div>
                  </div>
                  <div className="dibmailflx"><div className="getdrctnBtnDiv">
                    <a
                      href={invitationProfile?.[0]?.locationLink}
                      className="getdirectionbtn"
                      target="_blank"
                    >
                      Get Direction
                      <i class="fa-solid fa-diamond-turn-right"></i>
                    </a>
                  </div>
                    <div className="envelopdiv">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-envelope"></i>
                        {invitationProfile?.[0]?.emailId}
                      </span>
                    </div>
                    <div className="envelopdiv">
                      <span className="txtdtlmn">
                        <i className="fa-solid fa-globe"></i>
                        {invitationProfile?.[0]?.websiteLink}
                      </span>
                    </div>
                  </div>
                  <div className="divloctin">
                    <span className="txtdtlmn">
                      <i className="fa-solid fa-location-dot"></i>
                      {invitationProfile?.[0]?.address}
                    </span>
                  </div> */}

                {/* <div className='mapwidth'>
                                    <iframe
                                        src="https://www.google.com/maps<div className="getdrctnBtnDiv">
                    <a
                      href={invitationProfile?.[0]?.locationLink}
                      className="getdirectionbtn"
                      target="_blank"
                    >
                      Get Direction
                      <i class="fa-solid fa-diamond-turn-right"></i>
                    </a>
                  </div>/embed?pb=!1m14!1m12!1m3!1d14728.727405628732!2d88.33364660000001!3d22.64700745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1706076991023!5m2!1sen!2sin"
                                        // width={900}
                                        heigbwFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />

                                </div> */}

                {/* <div className="getdrctnBtnDiv">
                    <a
                      href={invitationProfile?.[0]?.locationLink}
                      className="getdirectionbtn"
                      target="_blank"
                    >
                      Get Direction
                      <i class="fa-solid fa-diamond-turn-right"></i>
                    </a>
                  </div> */}
              </div>
            )}
            {/* </div> */}

            <div className="quckcntntdiv">
              <div className="top">
                <div className="cntghjflx  ">
                  <p className="crtdivtct d-flex ">
                    Create your Neoo Search Profile
                  </p>
                </div>
                <div className="cntghjflx  ">
                  {/* <button className="updgttxt">Update later</button> */}
                  <span>
                    It is enough to fill out one time only and save contact
                    information in your Neoo card directory; you can add more
                    members through the Neoo card profile.
                  </span>
                </div>
                <div className="wfwfe3g3wtg3">
                  <span>
                    If you are already a member, login and exchange contact{" "}
                  </span>
                  <Link to="/" className="login_btn">
                    Login
                  </Link>
                </div>
              </div>
              <div className="bottom">
                <form>
                  <div className="inptfgfrmdiv required">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        name="personName"
                        value={NonMemberProfileAdd.personName}
                        placeholder="Your Full Name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="inptfgfrmdiv required">
                    <div className="input-wrapper">
                      <select
                        className="form-control py-2"
                        aria-label="Default select example"
                        name="designationID"
                        value={NonMemberProfileAdd.designationID}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Designation
                        </option>
                        {Designation.map((item, i) => (
                          <option key={i} value={item?._id}>
                            {item?.designation}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="inptfgfrmdiv align-items-center required">
                    <div className="input-wrapper">
                      <select
                        className="form-control countrycodebox"
                        aria-label="Default select example"
                        name="countryCodeMobile"
                        value={NonMemberProfileAdd.countryCodeMobile}
                        onChange={handleChange}
                      >
                        {Country.map((item, i) => (
                          <option key={i} value={item?.phone_code}>
                            {item?.emoji} {item?.phone_code}
                          </option>
                        ))}
                      </select>
                      <input
                        type="number"
                        name="mobileNo"
                        value={NonMemberProfileAdd.mobileNo}
                        onChange={handleNumberChange}
                        placeholder="Mobile Number "
                        className="remove-arrow"
                      />
                    </div>
                  </div>
                  <div className="inptfgfrmdiv align-items-center required">
                    <div className="input-wrapper">
                      <select
                        className="form-control countrycodebox"
                        aria-label="Default select example"
                        name="countryCodewhatsappNo"
                        value={NonMemberProfileAdd.countryCodewhatsappNo}
                        onChange={handleChange}
                      >
                        {Country.map((item, i) => (
                          <option key={i} value={item?.phone_code}>
                            {item?.emoji} {item?.phone_code}
                          </option>
                        ))}
                      </select>
                      <input
                        type="number"
                        name="whatsappNo"
                        value={NonMemberProfileAdd.whatsappNo}
                        onChange={handleNumberChange}
                        placeholder="Whatsapp Number "
                        className="remove-arrow"
                      />
                    </div>
                  </div>
                  <div className="inptfgfrmdiv required">
                    <div className="input-wrapper">
                      <input
                        type="email"
                        name="emailId"
                        value={NonMemberProfileAdd.emailId}
                        placeholder="Email Id"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* <div className="inptfgfrmdiv ">
                    <div className="input-wrapper">
                      <input
                        type="password"
                        name="password"
                        value={password}
                        placeholder="********"
                        onChange={(e) => setPassword(e.target.value)}
                      // onChange={handleChange}
                      />
                    </div>
                  </div> */}
                  <div className="inptfgfrmdiv ">
                    <div className="input-wrapper">
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        name="companyTypeID"
                        value={NonMemberProfileAdd.companyTypeID}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Type of Business
                        </option>
                        {Business.map((item, i) => (
                          <option key={i} value={item?._id}>
                            {item?.type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="inptfgfrmdiv ">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        name="companyName"
                        value={NonMemberProfileAdd.companyName}
                        placeholder="Company Name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="inptfgfrmdiv ">
                    <div className="input-wrapper">
                      <select
                        className="form-control py-2 mb-4 "
                        aria-label="Default select example"
                        name="country"
                        value={NonMemberProfileAdd.country}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Country
                        </option>
                        {Country.map((item, i) => (
                          <option key={i} value={item?._id}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="inptfgfrmdiv ">
                    <div className="input-wrapper">
                      <select
                        className="form-control mb-4 py-2"
                        aria-label="Default select example"
                        name="emirate"
                        value={NonMemberProfileAdd.emirate}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Emirate
                        </option>
                        {CountryWiseState?.map((item, i) => (
                          <option key={i} value={item?.name}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
                <div className="svaecntcydiv">
                  <div className="quickContctBtn">
                    <button className="txtplt" onClick={openPopup}>
                      Please save and connect
                    </button>
                  </div>
                  <div className="quickContctBtn">
                    <button
                      onClick={(e) => handleSubmit(e)}
                      className="btnsvsnd"
                    >
                      Exchange contact
                    </button>
                  </div>

                  {/* <div className='savebtn'>
                                        <button className='savecontactbtn'>
                                            Exchange contact
                                        </button>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showmodal && (
          <QRshareModal
            Qrimage={invitationProfile?.[0]?.qrCode}
            closeModal={setShowmodal}
          />
        )}
      </div>

      {isExchangeOpen && <ExchangedModal closePopup={setExchangeOpen} />}
    </>
  );
};

export default Memberinvitation;
