import React, { useEffect, useState } from "react";
import whatsapp from "../../Images/whatsapp.png";
import verified from "../../Images/verified.png";
import verifiedres from "../../Images/Verify responsive.png";
import Unverifiedres from "../../Images/warning.png";
import HttpClient from "../../utils/HttpClient";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import call from "../../Images/Call.png";
import chatIcon from "../../Images/chatIcon.png";
import {
  ProfileSubmenuDropDown,
  SearchModal,
  addSearch,
  getOwnProfile,
  nonMemberDirectoryCountData,
} from "../../Redux/Slice/PostSlice";

import { Helmet } from "react-helmet";
import StarRatings from "react-star-ratings";
import TotalChatList from "../Chatpage/TotalChatList";
import { OnetoOneChatIdname } from "../../Redux/Slice/Chat";

function Owncontactlist2() {
  const [dropDelpop, setDropDelpop] = useState(false);

  const [TypeOFSavedContacts, setTypeOFSavedContacts] = useState("all");
  const [savedContacts, setsavedContacts] = useState([]);
  // const [inputSearch, setinputSearch] = useState("");
  console.log("s64g6d8456", savedContacts);
  const [ProfileTypeCountdata, setProfileTypeCountdata] = useState([]);
  const [ProfileType, setProfileType] = useState([]);
  const [AllContactCount, setAllContactCount] = useState([]);
  const [UserTypeCompany, setUserTypeCompany] = useState([]);
  const [UserTypePersonal, setUserTypePersonal] = useState([]);
  const navigate = useNavigate();
  const {
    inputSearch,
    SearchBar,
    ProfileSubmenuDropDownToggle,
    UserProfileID,
    responSiveSearchBar,
  } = useSelector((state) => state.app);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [deleteWhatsappModalOpenClose, setdeleteWhatsappModalOpenClose] =
    useState(false);

  const [DynamicSetProfileType, setDynamicSetProfileType] = useState("");
  const [ProfileTypeCompany, setProfileTypeCompany] = useState([]);
  const [ProfileTypePersonal, setProfileTypePersonal] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const profileTypeCompany = ProfileTypeCountdata?.filter(
    (item, i) => item?.nonmemberDetails?.proType?.name === "Company"
  ).length;
  const profileTypePersonal = ProfileTypeCountdata?.filter(
    (item, i) => item?.nonmemberDetails?.proType?.name === "Personal"
  ).length;

  console.log(inputSearch, "inputSearch");
  console.log(savedContacts, "savedContacts");
  console.log(
    DynamicSetProfileType,
    AllContactCount,
    TypeOFSavedContacts,
    "DynamicSetProfileType"
  );
  console.log(ProfileType, "ProfileType");
  console.log(UserProfileID, "UserProfileID");
  console.log(ProfileTypeCompany, "ProfileTypeCompany");
  console.log(ProfileTypePersonal, "ProfileTypePersonal");
  console.log(location, "location");
  console.log(SearchBar, "SearchBar");
  console.log(profileTypeCompany, "profileTypeCompany");
  console.log(profileTypePersonal, "profileTypePersonal");
  console.log(ProfileTypeCountdata, "ProfileTypeCountdata");

  //whatsapp redirection
  const whatsAppRedirection = async (e, whats_code, whatsappNmbr, id) => {
    // e.stopPropagation();
    console.log(whatsappNmbr, "whatsappNmbr");
    const whatsappNumber = whats_code + whatsappNmbr;

    const message = "Hi, I am Using Neoo Search Platform."; // Replace with your desired message
    const encodedMessage = encodeURIComponent(message);
    const encodedWhatsAppNumber = encodeURIComponent(whatsappNumber);
    // Construct the WhatsApp URL with phone number and message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodedWhatsAppNumber}&text=${encodedMessage}`;

    const whatsappPoint = {
      profileId: id,
      type: "whatsapp",
    };

    if (id) {
      const res = await HttpClient.requestData(
        "getting-rewards",
        "POST",
        whatsappPoint
      );
      if (res && res?.status) {
        toast.success("Redirecting to the WhatsApp App...");
      } else {
        console.log("Cant add points");
      }

      window.open(whatsappUrl, "_blank");
    }
  };

  // Modify the openDele function to accept the index and set the clickedIndex state
  const openDelet = (index, nmbr) => {
    console.log(nmbr, "nmbr");
    setdeleteWhatsappModalOpenClose(true);
    console.log(index, "index");
    setClickedIndex(index === clickedIndex ? null : index);
  };

  const openDele = (index) => {
    setClickedIndex(index === clickedIndex ? null : index);
    setDropDelpop(!dropDelpop);
  };
  const delMember = async (id, e) => {
    console.log(id, "sdedawded");
    e.stopPropagation(); // Stop event propagation
    // alert("hgh");
    const res = await HttpClient.requestData("delete-contacts/" + id, "DELETE");
    if (res && res?.status) {
      toast.success("Contact Deleted");
      savedContactList();
    } else {
      toast.error("Delete Failed");
    }
  };

  const handlecntClick = (item, all) => {
    console.log(all, "ALLS");
    setDynamicSetProfileType(all?.name);
    setTypeOFSavedContacts(item);
    dispatch(ProfileSubmenuDropDown(false));
  };

  const getProfileType = async () => {
    const res = await HttpClient.requestData("get-profile-type", "GET", {});
    console.log(res?.data, "profiletype");
    if (res && res?.status) {
      setProfileType(res?.data || []);
    }
  };

  console.log(UserProfileID, "UserMemberDataout");
  const savedContactList = async () => {
    const data = {
      key: TypeOFSavedContacts,
    };
    const data2 = {
      key: "all",
    };

    //profile type Personal
    let CompanyUserType = {
      key: ProfileType?.[0]?._id,
    };
    //profile type Personal
    let PersonalUserType = {
      key: ProfileType?.[1]?._id,
    };

    //member
    const res = await HttpClient.requestData("get-userby-key", "POST", data);
    const res1 = await HttpClient.requestData("get-userby-key", "POST", data2);
    const resMember = await HttpClient.requestData(
      "get-userby-key",
      "POST",
      CompanyUserType
    );
    const resNonMember = await HttpClient.requestData(
      "get-userby-key",
      "POST",
      PersonalUserType
    );
    //member who is upgraded from non mem
    const resUpgraded = await HttpClient.requestData(
      "get-userby-key-upgradeMem",
      "POST",
      data
    );
    const res1Upgraded = await HttpClient.requestData(
      "get-userby-key-upgradeMem",
      "POST",
      data2
    );
    const resMemberUpgraded = await HttpClient.requestData(
      "get-userby-key-upgradeMem",
      "POST",
      CompanyUserType
    );
    const resNonMemberUpgraded = await HttpClient.requestData(
      "get-userby-key-upgradeMem",
      "POST",
      PersonalUserType
    );
    //nonmember contact List
    const res2 = await HttpClient.requestData(
      "get-userby-key-nonMem",
      "POST",
      data
    );
    const res3 = await HttpClient.requestData(
      "get-userby-key-nonMem",
      "POST",
      data2
    );
    const resMember2 = await HttpClient.requestData(
      "get-userby-key-nonMem",
      "POST",
      CompanyUserType
    );
    const resNonMember2 = await HttpClient.requestData(
      "get-userby-key-nonMem",
      "POST",
      PersonalUserType
    );

    console.log(res3?.data.length, "resDataresres");
    console.log("ddffgghw", res2);

    console.log(UserProfileID, "UserMemberData1");
    if (Object.keys(UserProfileID).length !== 0) {
      if (UserProfileID.memberStatus && !UserProfileID.upgradeStatus) {
        // console.log("ddffgghw", res?.data, UserProfileID.memberStatus);
        if (res && res?.status) {
          setsavedContacts(res?.data);
          setAllContactCount(res1?.data);
          setProfileTypeCompany(resMember?.data);
          setProfileTypePersonal(resNonMember?.data);
        }
        if (TypeOFSavedContacts == "recent") {
          // Get the current date
          const currentDate = new Date();

          // Calculate the date two days ago
          const twoDaysAgo = new Date(currentDate);
          twoDaysAgo.setDate(currentDate.getDate() - 7);

          // Filter profiles based on creation date
          const filtered = savedContacts.filter((item) => {
            const creationDate = new Date(item?.myContact?.createdOn); // Assuming creationDate is a string in ISO format
            return creationDate >= twoDaysAgo && creationDate <= currentDate;
          });
          setsavedContacts(filtered);
          setAllContactCount(filtered);
        }
      }
      if (UserProfileID.memberStatus) {
        // console.log("ddffgghw", res?.data, UserProfileID.memberStatus);
        if (res && res?.status) {
          setsavedContacts(res?.data);
          setAllContactCount(res1?.data);
          setProfileTypeCompany(resMember?.data);
          setProfileTypePersonal(resNonMember?.data);
        }
        if (TypeOFSavedContacts == "recent") {
          // Get the current date
          const currentDate = new Date();

          // Calculate the date two days ago
          const twoDaysAgo = new Date(currentDate);
          twoDaysAgo.setDate(currentDate.getDate() - 7);

          // Filter profiles based on creation date
          const filtered = savedContacts.filter((item) => {
            const creationDate = new Date(item?.myContact?.createdOn); // Assuming creationDate is a string in ISO format
            return creationDate >= twoDaysAgo && creationDate <= currentDate;
          });
          setsavedContacts(filtered);
          setAllContactCount(filtered);
        }
      } else if (!UserProfileID.memberStatus) {
        if (res2 && res2?.status) {
          console.log("ddffggh", res2?.data, UserProfileID.memberStatus);
          setsavedContacts(res2?.data);
          setAllContactCount(res3?.data);
          setProfileTypeCompany(resMember2?.data);
          setProfileTypePersonal(resNonMember2?.data);
          dispatch(nonMemberDirectoryCountData(res3?.data.length));
        }
      }
    }
  };

  // for profile type count
  const savedconstForprofileCount = async () => {
    const data = {
      key: "all",
    };
    const res = await HttpClient.requestData("get-userby-key", "POST", data);
    const res1 = await HttpClient.requestData(
      "get-userby-key-nonMem",
      "POST",
      data
    );
    console.log(res1, UserProfileID?.memberStatus, "djjhhhh775");
    if (UserProfileID?.memberStatus) {
      if (res && res?.status) {
        setProfileTypeCountdata(res?.data);
      } else {
        console.error(res?.message);
      }
    } else if (!UserProfileID.memberStatus) {
      if (res1 && res1?.status) {
        setProfileTypeCountdata(res1?.data);
      } else {
        console.error(res1?.message);
      }
    }
  };
  const searchDebouncing = async () => {
    let trimmedSearch;

    if (location?.state) {
      trimmedSearch = location?.state.trim().toLowerCase();
    }
    // if (!SearchBar)
    else {
      trimmedSearch = inputSearch.trim().toLowerCase(); // Trim the inputSearch value }
    }
    if (trimmedSearch?.length > 0) {
      const data = {
        searchName: trimmedSearch,
      };

      const res = await HttpClient.requestData("search-user", "POST", data);
      console.log(res, "resDataresresSearch");
      if (res && res?.status) {
        setsavedContacts(res?.data);
        setAllContactCount(res?.data);
      }
    } else {
      // Reset saved contacts when input is cleared
      setTypeOFSavedContacts("all");
      savedContactList();
    }
  };

  const openBox = (item) => {
    console.log(JSON.stringify(item._id), "item");

    if (item?.memberStatus) {
      navigate("/MemberOtherSavedProfile/" + item?.userName?.trim(), {
        state: item,
      });
    } else {
      navigate("/NonMemberuserSavedProfile/" + item?.personName?.trim(), {
        state: item,
      });
    }
  };

  const joinChat = (_id) => {
    dispatch(OnetoOneChatIdname(_id));
    navigate("/chat");
  };

  useEffect(() => {
    if (Object.keys.length !== 0) {
      savedContactList();
    }
  }, [TypeOFSavedContacts, UserProfileID]);

  useEffect(() => {
    savedconstForprofileCount();
  }, [UserProfileID]);
  useEffect(() => {
    getProfileType();
  }, []);
  useEffect(() => {
    dispatch(getOwnProfile());
  }, []);
  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      searchDebouncing();
    }, 500); // Adjust the debounce time as needed (0.5 second in this case)

    return () => clearTimeout(timeoutId); // Clear the timeout on component unmount or when inputSearch changes
  }, [inputSearch, SearchBar]);

  // input search
  useEffect(() => {
    // if (!responSiveSearchBar) {
    //   dispatch(SearchModal(false));
    // }
    // if (responSiveSearchBar && inputSearch.length > 0) {
    //   dispatch(SearchModal(false));
    // }
    if (location?.state) {
      dispatch(SearchModal(false));
    }
    if (inputSearch.length > 0) {
      dispatch(SearchModal(true));
    }
    if (inputSearch.length < 0 && SearchBar == true) {
      dispatch(SearchModal(false));
    }
  }, [inputSearch, location?.state]);
  // useEffect(() => {
  //   if (location?.state) {
  //     dispatch(addSearch(location?.state));
  //   }
  // }, [location]);

  // useEffect(() => {
  //   const handleClick = () => {
  //     // Dispatch the action to close the submenu dropdown
  //     if (ProfileSubmenuDropDownToggle) {
  //       dispatch(ProfileSubmenuDropDown(false));
  //     } else {
  //       dispatch(ProfileSubmenuDropDown(true));
  //     }
  //   };

  //   // Add event listener to the entire component
  //   document.addEventListener("click", handleClick);

  //   // Clean up event listener on component unmount
  //   return () => {
  //     document.removeEventListener("click", handleClick);
  //   };
  // }, [dispatch]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       deleteWhatsappModalOpenClose
  //     ) {
  //       setdeleteWhatsappModalOpenClose(true);
  //     }
  //     else {
  //       setdeleteWhatsappModalOpenClose(false)
  //     }

  //   };

  //   window.addEventListener("click", handleClickOutside);

  //   // return () => {
  //   //   window.removeEventListener("click", handleClickOutside);

  //   // };
  // }, [deleteWhatsappModalOpenClose]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Neoo Search Directory Page</title>
      </Helmet>

      <section className="bgghjh">
        <div
          className="contactlistmainpage"
          onClick={(e) => setClickedIndex(null)}
        >
          <div className="cust_container">
            <div className="mnakmsjjmn">
              {/* <div className="inpyuj">
              <input
                type="search"
                placeholder="Search"
                value={inputSearch}
                onChange={(e) => setinputSearch(e.target.value)}
              />

              {!inputSearch.length > 0 ? (
                <div className="ghjhhsrch">
                  <i className="fa-solid fa-magnifying-glass" />
                </div>
              ) : (
                <div
                  className="ghjhhsrch"
                  onClick={() => setinputSearch("")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </div>
              )}
            </div> */}
              <div className="fhjsdcncth">
                <div
                  className={
                    TypeOFSavedContacts === "all" ? "ttxytnk active" : "ttxytnk"
                  }
                  onClick={() => handlecntClick("all")}
                >
                  All ({AllContactCount?.length})
                </div>
                <div
                  className={
                    TypeOFSavedContacts === "recent"
                      ? "ttxytnk active"
                      : "ttxytnk"
                  }
                  onClick={() => handlecntClick("recent")}
                >
                  Recents
                </div>
                {ProfileType?.map((item, i) => {
                  return (
                    <div
                      className={
                        TypeOFSavedContacts === item?._id
                          ? "ttxytnk active"
                          : "ttxytnk"
                      }
                      onClick={() => handlecntClick(item._id, item)}
                    >
                      {item.name}{" "}
                      {item._id == ProfileType?.[0]?._id && (
                        <>
                          <span>
                            {!UserProfileID?.memberStatus && (
                              <>
                                (
                                {
                                  ProfileTypeCountdata?.filter(
                                    (item, i) =>
                                      item?.nonmemberDetails?.profileType ===
                                      "Company"
                                  ).length
                                }
                                )
                              </>
                            )}
                          </span>
                          {UserProfileID?.memberStatus && (
                            <span>({profileTypeCompany})</span>
                          )}
                        </>
                      )}
                      {item._id == ProfileType?.[1]?._id && (
                        <>
                          <span>
                            {!UserProfileID?.memberStatus && (
                              <>
                                (
                                {
                                  ProfileTypeCountdata?.filter(
                                    (item, i) =>
                                      item?.nonmemberDetails?.profileType ===
                                      "Personal"
                                  ).length
                                }
                                )
                              </>
                            )}
                          </span>
                          {UserProfileID?.memberStatus && (
                            <span>({profileTypePersonal})</span>
                          )}
                        </>
                      )}
                      <i className="fa-solid fa-paper-plane" />
                    </div>
                  );
                })}
                {/* <div className="ttxytnk">
                Personal
                <i className="fa-solid fa-paper-plane" />
              </div> */}
              </div>
            </div>

            <div className="contactbg">
              {savedContacts?.length > 0 &&
                [
                  ...savedContacts
                    .filter(
                      (item) => item?.nonmemberDetails?.memberStatus === true
                    )
                    .reverse(),
                  ...savedContacts
                    .filter(
                      (item) => item?.nonmemberDetails?.memberStatus === false
                    )
                    .reverse(),
                ].map((item, i) => {
                  console.log("sd65f14651", item);
                  return (
                    <>
                      <div>
                        {item?.nonmemberDetails?.memberStatus == true ? (
                          <div
                            className="brdrblckdiv"
                            key={i}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="cntntflx">
                              <div
                                onClick={() => openBox(item?.nonmemberDetails)}
                                style={{ width: "100%" }}
                              >
                                <div className="cntcnmdtlsmai">
                                  {/* <div className='prflimgbrdrdiv'> */}
                                  <div className="imgvrtdiv">
                                    <img
                                      src={
                                        item?.nonmemberDetails?.profilePhoto
                                          ? item?.nonmemberDetails?.profilePhoto
                                          : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png"
                                      }
                                      alt="..."
                                    />
                                  </div>
                                  {/* </div> */}
                                  <div className="dtghhnjkd">
                                    <div className="responResFig">
                                      <span className="prdltxtflx">
                                        <p className="jhntxtlws">
                                          {item?.nonmemberDetails?.personName}
                                        </p>
                                        <p className="mrttxtt">
                                          {item?.nonmemberDetails?.designation}
                                        </p>
                                        <figure className="contResFig">
                                          <img src={verifiedres} alt="img" />
                                        </figure>
                                      </span>
                                      {item?.nonmemberDetails?.memberStatus ? (
                                        <div
                                          className="upbtndivmainlop"
                                          key={i}
                                        >
                                          <div className="vrdfbrdr">
                                            <span className="">Verified</span>
                                            <img src={verified} alt="..." />
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className="upbtndivmainlop"
                                          key={i}
                                        >
                                          <div className="vrdfbrdr py-1">
                                            <span className="">Unverified</span>

                                            <i
                                              className="fa-solid fa-circle-exclamation text-danger fs-3 ml-1"
                                              style={{ fontSize: "19px" }}
                                            ></i>
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    <span className="prdltxtflx wrapbox">
                                      <p className="cmpntyxt">
                                        {item?.nonmemberDetails?.companyName}
                                      </p>
                                      <p className="dghttxt">
                                        {item?.nonmemberDetails?.TypeOfCompany}
                                      </p>
                                    </span>
                                    <span className="prdltxtflx">
                                      {item?.nonmemberDetails?.telephoneNo && (
                                        <p className="mbltxtop">
                                          <span>Tel:</span>{" "}
                                          {item?.nonmemberDetails?.telephoneNo},
                                        </p>
                                      )}
                                      <p className="mbltxtop">
                                        {!item?.nonmemberDetails
                                          ?.telephoneNo && (
                                          <span>Mobile:</span>
                                        )}{" "}
                                        {item?.nonmemberDetails?.phone_code +
                                          " " +
                                          item?.nonmemberDetails?.mobileNo}
                                      </p>
                                      {/* <p className="mbltxtop">
                            <span> {item?.nonmemberDetails?.country}</span>{" "}
                            {item?.nonmemberDetails?.emirate}
                          </p> */}
                                      {/* <p className="mbltxtop">
                            <span>Emirate:</span> {item?.nonmemberDetails?.emirate}
                          </p> */}
                                      {/* <p className="mbbjlisown">
                                <span>
                                  {" "}
                                  {item?.nonmemberDetails?.country}
                                  {item?.nonmemberDetails?.country}
                                </span>{" "}
                                {item?.nonmemberDetails?.emirate}
                              </p> */}
                                    </span>
                                    <p className="mbbjlisown">
                                      <span>
                                        {" "}
                                        {item?.nonmemberDetails?.location}
                                      </span>{" "}
                                      {item?.nonmemberDetails?.location && ","}
                                      <span>
                                        {" "}
                                        {item?.nonmemberDetails?.emirate}
                                      </span>{" "}
                                      -
                                      <span>
                                        {" "}
                                        {item?.nonmemberDetails?.country}
                                      </span>{" "}
                                    </p>
                                    <div className="lksflwresrt">
                                      <div className="txtljklppp">
                                        <span>Likes:</span>{" "}
                                        {item?.nonmemberDetails?.likeCount}
                                      </div>
                                      <div className="txtljklppp">
                                        <span>Followers:</span> 00
                                      </div>
                                      <div className="">
                                        <StarRatings
                                          rating={
                                            item?.nonmemberDetails?.avgRating
                                          }
                                          starRatedColor="#F3660B"
                                          starEmptyColor="grey"
                                          numberOfStars={5}
                                          name="UserProfileRating" // Set to a generic name for product ratings
                                          starDimension="18px"
                                          starSpacing="2px"
                                          className="fa-regular fa-star"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="buttonwrap_asse">
                                {/* DROPDOWN WHATSAPP AND DELETE */}
                                <div
                                  className="iommdot"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openDelet(
                                      i,
                                      item?.nonmemberDetails?.mobileNo
                                    );
                                    dispatch(ProfileSubmenuDropDown(false));
                                  }}
                                >
                                  <i className="fa-solid fa-ellipsis"></i>

                                  {clickedIndex === i &&
                                    deleteWhatsappModalOpenClose && (
                                      <div className="dropDelete">
                                        <ul>
                                          <li>
                                            <i
                                              className="fa-brands fa-square-whatsapp whatsapp"
                                              onClick={(e) =>
                                                whatsAppRedirection(
                                                  e,
                                                  item?.nonmemberDetails
                                                    ?.whats_code,
                                                  item?.nonmemberDetails
                                                    ?.whatsappNo,
                                                  item?.nonmemberDetails?._id
                                                )
                                              }
                                            ></i>
                                          </li>
                                          <li>
                                            <i
                                              className="fa-solid fa-trash-can trash"
                                              onClick={(e) =>
                                                delMember(
                                                  item?.nonmemberDetails?._id,
                                                  e
                                                )
                                              }
                                            ></i>
                                          </li>
                                          <li>
                                            <a
                                              href={`tel:${item?.nonmemberDetails?.phone_code}${item?.nonmemberDetails?.mobileNo}`}
                                              className="callButton "
                                            >
                                              <figure className="drctrCntctIconOpenDel">
                                                <img
                                                  src={call}
                                                  alt="img"
                                                  className="img-fluid"
                                                />
                                              </figure>
                                            </a>
                                          </li>
                                          <li>
                                            <a className="callButton ">
                                              <figure className="drctrCntctIconOpenDel">
                                                <img
                                                  src={chatIcon}
                                                  alt="img"
                                                  className="img-fluid"
                                                  onClick={() =>
                                                    joinChat(item?.nonmemberDetails?._id)
                                                  }
                                                />
                                              </figure>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {item?.nonmemberDetails?.memberStatus == false ? (
                          <div
                            className="brdrblckdiv"
                            key={i}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="cntntflx">
                              <div
                                onClick={() => openBox(item?.nonmemberDetails)}
                                style={{ width: "100%" }}
                              >
                                <div className="cntcnmdtlsmai">
                                  {/* <div className='prflimgbrdrdiv'> */}
                                  <div className="imgvrtdiv border">
                                    <img
                                      src={
                                        "https://st.depositphotos.com/2934765/53192/v/450/depositphotos_531920820-stock-illustration-photo-available-vector-icon-default.jpg"
                                      }
                                      alt="..."
                                    />
                                  </div>
                                  {/* </div> */}
                                  <div className="dtghhnjkd">
                                    <div className="responResFig">
                                      <span className="prdltxtflx">
                                        <p className="jhntxtlws">
                                          {item?.nonmemberDetails?.personName}
                                        </p>
                                        <p className="mrttxtt">
                                          {item?.nonmemberDetails?.designation}
                                        </p>
                                        <figure className="contResFig">
                                          <img src={Unverifiedres} alt="img" />
                                        </figure>
                                      </span>
                                      {item?.nonmemberDetails?.memberStatus ? (
                                        <div
                                          className="upbtndivmainlop"
                                          key={i}
                                        >
                                          <div className="vrdfbrdr">
                                            <span className="">Verified</span>
                                            {/* <img src={verified} alt="..." /> */}
                                            <figure className="contResFig">
                                              <img
                                                src={verifiedres}
                                                alt="img"
                                              />
                                            </figure>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className="upbtndivmainlop"
                                          key={i}
                                        >
                                          <div
                                            className="upbtndivmainlop"
                                            key={i}
                                          >
                                            <div className="vrdfbrdr">
                                              <span className="">
                                                Unverified
                                              </span>
                                              {/* <img src={verified} alt="..." /> */}

                                              <img
                                                src={Unverifiedres}
                                                alt="img"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <span className="prdltxtflx wrapbox">
                                      <p className="cmpntyxt">
                                        {item?.nonmemberDetails?.companyName}
                                      </p>
                                      <p className="dghttxt">
                                        {item?.nonmemberDetails?.TypeOfCompany}
                                      </p>
                                    </span>
                                    <span className="prdltxtflx">
                                      {/* {item?.nonmemberDetails?.telephoneNo && (
                                        <p className="mbltxtop">
                                          <span>Tel:</span>{" "}
                                          {item?.nonmemberDetails?.telephoneNo},
                                        </p>
                                      )} */}
                                      <p className="mbltxtop">
                                        <span>Mobile:</span>{" "}
                                        {item?.nonmemberDetails?.phone_code +
                                          " " +
                                          item?.nonmemberDetails?.mobileNo}
                                      </p>
                                      {/* <p className="mbltxtop">
                            <span> {item?.nonmemberDetails?.country}</span>{" "}
                            {item?.nonmemberDetails?.emirate}
                          </p> */}
                                      {/* <p className="mbltxtop">
                            <span>Emirate:</span> {item?.nonmemberDetails?.emirate}
                          </p> */}
                                      {/* <p className="mbbjlisown">
                                <span>
                                  {" "}
                                  {item?.nonmemberDetails?.country}
                                  {item?.nonmemberDetails?.country}
                                </span>{" "}
                                {item?.nonmemberDetails?.emirate}
                              </p> */}
                                    </span>
                                    <p className="mbbjlisown">
                                      <span>
                                        {" "}
                                        {/* {item?.nonmemberDetails?.location}/ */}
                                      </span>{" "}
                                      {/* {item?.nonmemberDetails?.location && ","} */}
                                      <span>
                                        {" "}
                                        {item?.nonmemberDetails?.emirate}
                                      </span>{" "}
                                      -
                                      <span>
                                        {" "}
                                        {item?.nonmemberDetails?.country}
                                      </span>{" "}
                                    </p>
                                    <div className="lksflwresrt">
                                      <div className="txtljklppp">
                                        <span>Likes:</span>{" "}
                                        {item?.nonmemberDetails?.likeCount}
                                      </div>
                                      <div className="txtljklppp">
                                        <span>Followers:</span> 00
                                      </div>
                                      <div className="">
                                        <StarRatings
                                          rating={
                                            item?.nonmemberDetails?.avgRating
                                          }
                                          starRatedColor="#F3660B"
                                          starEmptyColor="grey"
                                          numberOfStars={5}
                                          name="UserProfileRating" // Set to a generic name for product ratings
                                          starDimension="18px"
                                          starSpacing="2px"
                                          className="fa-regular fa-star"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="buttonwrap_asse">
                                {/* DROPDOWN WHATSAPP AND DELETE */}
                                <div
                                  className="iommdot"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openDelet(
                                      i,
                                      item?.nonmemberDetails?.mobileNo
                                    );
                                    dispatch(ProfileSubmenuDropDown(false));
                                  }}
                                >
                                  <i className="fa-solid fa-ellipsis"></i>

                                  {clickedIndex === i &&
                                    deleteWhatsappModalOpenClose && (
                                      <div className="dropDelete">
                                        <ul>
                                          <li>
                                            <i
                                              className="fa-brands fa-square-whatsapp whatsapp"
                                              onClick={(e) =>
                                                whatsAppRedirection(
                                                  e,
                                                  item?.nonmemberDetails
                                                    ?.whats_code,
                                                  item?.nonmemberDetails
                                                    ?.whatsappNo,
                                                  item?.nonmemberDetails?._id
                                                )
                                              }
                                            ></i>
                                          </li>
                                          <li>
                                            <i
                                              className="fa-solid fa-trash-can trash"
                                              onClick={(e) =>
                                                delMember(item?._id, e)
                                              }
                                            ></i>
                                          </li>
                                          <li>
                                            <a
                                              href={`tel:${
                                                item?.nonmemberDetails
                                                  ?.phone_code +
                                                item?.nonmemberDetails?.mobileNo
                                              }`}
                                              className="callButton"
                                            >
                                              <figure className="drctrCntctIcon ">
                                                <img
                                                  src={call}
                                                  alt="img"
                                                  className="img-fluid"
                                                />
                                              </figure>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  );
                })}

              {/* {savedContacts && savedContacts?.length !== 0 && <TotalChatList savedContacts={savedContacts} />} */}
              {/* <div className="brdrblckdiv">
                <div className="cntntflx">
                  <a href="/">
                    <div className="cntcnmdtlsmai">
                      <div className="imgvrtdiv">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png"
                          alt="..."
                        />
                      </div>
                      <div className="dtghhnjkd">
                        <span className="prdltxtflx">
                          <p className="jhntxtlws">rohit roy</p>
                          <p className="mrttxtt">CEO</p>
                        </span>
                        <span className="prdltxtflx">
                          <p className="cmpntyxt">bloopers</p>
                          <p className="dghttxt">Digital marketing</p>
                        </span>
                        <span className="prdltxtflx">
                          <p className="mbltxtop">
                            <span>Mobile:</span> 22222222
                          </p>
                          <p className="mbbjlisown">
                            <span> India</span> Ajman
                          </p>
                        </span>
                        <div className="lksflwresrt">
                          <div className="txtljklppp">
                            <span>Likes:</span> 151
                          </div>
                          <div className="txtljklppp">
                            <span>Followers:</span> 00
                          </div>
                          <div className="">
                            <i className="fa-regular fa-star" />
                            <i className="fa-regular fa-star" />
                            <i className="fa-regular fa-star" />
                            <i className="fa-regular fa-star" />
                            <i className="fa-regular fa-star" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div className="upbtndivmainlop">
                    <div className="iommdot">
                      <i className="fa-solid fa-ellipsis" />
                    </div>
                    <div className="whtsappimg">
                      <img src={whatsapp} alt="..." />
                    </div>
                    <div className="vrdfbrdr">
                      <span className="">Verified</span>
                      <img src={verified} alt="..." />
                    </div>
                  </div>
                </div>
                <figure className="contResFig">
                  <img src={verifiedres} alt="img" />
                </figure>
              </div>
              <div className="brdrblckdiv">
                <div className="cntntflx">
                  <a href="/">
                    <div className="cntcnmdtlsmai">
                      <div className="imgvrtdiv">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png"
                          alt="..."
                        />
                      </div>
                      <div className="dtghhnjkd">
                        <span className="prdltxtflx">
                          <p className="jhntxtlws">jkjk</p>
                          <p className="mrttxtt">CMO</p>
                        </span>
                        <span className="prdltxtflx">
                          <p className="cmpntyxt">jjkkllh</p>
                          <p className="dghttxt">Digital marketing</p>
                        </span>
                        <span className="prdltxtflx">
                          <p className="mbltxtop">
                            <span>Mobile:</span> 66540587345
                          </p>
                          <p className="mbbjlisown">
                            <span> Armenia</span> Dubai
                          </p>
                        </span>
                        <div className="lksflwresrt">
                          <div className="txtljklppp">
                            <span>Likes:</span> 151
                          </div>
                          <div className="txtljklppp">
                            <span>Followers:</span> 00
                          </div>
                          <div className="">
                            <i className="fa-regular fa-star" />
                            <i className="fa-regular fa-star" />
                            <i className="fa-regular fa-star" />
                            <i className="fa-regular fa-star" />
                            <i className="fa-regular fa-star" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div className="upbtndivmainlop">
                    <div className="iommdot">
                      <i className="fa-solid fa-ellipsis" />
                    </div>
                    <div className="whtsappimg">
                      <img src={whatsapp} alt="..." />
                    </div>
                    <div className="vrdfbrdr">
                      <span className="">Verified</span>
                      <img src={verified} alt="..." />
                    </div>
                  </div>
                </div>
                <figure className="contResFig">
                  <img src={verifiedres} alt="img" />
                </figure>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Owncontactlist2;
