import React, { useEffect } from "react";
import OfferstatsVenderResponsive from "./OfferstatsVenderResponsive";
import OfferVoucherissuedRedeemed from "./OfferVoucherissuedRedeemed";
import OfferVoucherIssuedData from "./OfferVoucherIssuedData";
import { offerStatsTAB } from "../../Redux/Slice/Offer";
import { useDispatch, useSelector } from "react-redux";

const OfferVoucherIssuedStats = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(offerStatsTAB("issued"));
  }, []);
  return (
    <>
      <section className="offerstatsinnerPage">
        <div className="cust_container">
          <OfferstatsVenderResponsive />
          <OfferVoucherIssuedData />
        </div>
      </section>
    </>
  );
};

export default OfferVoucherIssuedStats;
