import React, { useEffect, useState } from 'react'
import instapp from "../Images/PWA iocn_neoosearch.png";

function PWA() {
    //Pwa function start

    const [deferredPrompt, setDeferredPrompt] = useState(null);

    const handleBeforeInstallPrompt = (e) => {
        // alert("fsfsdfd");
        console.log("eeedfd", e);
        // Prevent Chrome 76 and later from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        setDeferredPrompt(e);
        // dispatch(pwaDataSet(e))
    };

    const pwaLocalStoreData = localStorage.getItem("pwaNeoFront");

    console.log("pwaDatadd", deferredPrompt);

    const handleClick = () => {
        console.log("deferredPromptfff", deferredPrompt);
        if (deferredPrompt) {
            // Show the prompt
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("User accepted the A2HS prompt");
                } else {
                    console.log("User dismissed the A2HS prompt");
                }
                setDeferredPrompt(null);
            });
        }
    };

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        return () => {
            window.removeEventListener(
                "beforeinstallprompt",
                handleBeforeInstallPrompt
            );
        };
    }, []);

    //Pwa function end
    return (
        <>
            {/*****pwa popup start*/}
            {pwaLocalStoreData !== "true" && (
                <div>
                    {deferredPrompt && (
                        <div className="pwaPopup_wrap">
                            <div className="pwaPopup_body">
                                <div className="heading">
                                    <h4>Install App ?</h4>
                                </div>
                                <div className="logo_wrap">
                                    <img src={instapp} className="img-fluid" alt="..." />
                                </div>
                                <div className="content">
                                    <button className="install_btn" onClick={handleClick}>
                                        Yes
                                    </button>
                                    <button
                                        className="close_btn"
                                        onClick={() => {
                                            localStorage.setItem("pwaNeoFront", true);
                                            setDeferredPrompt(null);
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {/*****pwa popup end*/}
        </>
    )
}

export default PWA