import React, { useState } from 'react'
import galleryimg from "../../Images/galleryimg.png";
import productdesign from "../../Images/productdesign.png";
import Advertising from "../../Images/Advertising.png";
import DigitalMarketing from "../../Images/DigitalMarketing.png";
import Photography from "../../Images/Photography.png"
import Otherproductmodal from '../../Modal/Otherproductmodal';
const Othergalleryblmprofile = () => {
    const [beautyproductmodal, setBeautyproductmodal] = useState(false)
    const productmodalotherclcik = () => {
        setBeautyproductmodal(!beautyproductmodal)
    }
    return (
        <>

            <div className='uploadbannerblmdiv'>
                <div className='uploadbannerdivnewmain'>
                    <div className="consttactdetatlsarroedyrdiv">
                        <p className="cinnattddttdtsttspo">Gallery</p>

                    </div>
                    <div className='uploadimagedivflx'>
                        <div className='uploadimagebg' onClick={productmodalotherclcik}>
                            <div className='uploadimagebgmain'>
                                <div className='cameralogodivimgabslt'>

                                    <div className='cameralogodivimg'>
                                        <img src={galleryimg} alt='...' />
                                    </div>
                                    <p className='uplsdttxttppshji'>Upload image</p>

                                </div>
                                <div className='gagelttysimgdivmain'>
                                    <img src={productdesign} alt='...' />
                                </div>
                            </div>
                            <p className="imagetitrtlstrtrxpp">Product design</p>
                        </div>

                        <div className='uploadimagebg'>
                            <div className='uploadimagebgmain'>
                                <div className='cameralogodivimgabslt'>

                                    <div className='cameralogodivimg'>
                                        <img src={galleryimg} alt='...' />
                                    </div>
                                    <p className='uplsdttxttppshji'>Upload image</p>

                                </div>
                                <div className='gagelttysimgdivmain'>
                                    <img src={Advertising} alt='...' />
                                </div>
                            </div>

                            <p className="imagetitrtlstrtrxpp">Advertising</p>
                        </div>

                        <div className='uploadimagebg'>
                            <div className='uploadimagebgmain'>
                                <div className='cameralogodivimgabslt'>

                                    <div className='cameralogodivimg'>
                                        <img src={galleryimg} alt='...' />
                                    </div>
                                    <p className='uplsdttxttppshji'>Upload image</p>

                                </div>
                                <div className='gagelttysimgdivmain'>
                                    <img src={DigitalMarketing} alt='...' />
                                </div>
                            </div>

                            <p className="imagetitrtlstrtrxpp">Digital marketing</p>
                        </div>

                        <div className='uploadimagebg'>
                            <div className='uploadimagebgmain'>
                                <div className='cameralogodivimgabslt'>

                                    <div className='cameralogodivimg'>
                                        <img src={galleryimg} alt='...' />
                                    </div>
                                    <p className='uplsdttxttppshji'>Upload image</p>

                                </div>
                                <div className='gagelttysimgdivmain'>
                                    <img src={Photography} alt='...' />
                                </div>
                            </div>

                            <p className="imagetitrtlstrtrxpp">Photography</p>
                        </div>
                    </div>
                </div>
            </div>

            {beautyproductmodal && <Otherproductmodal closemodal={setBeautyproductmodal}/>}
        </>
    )
}

export default Othergalleryblmprofile
