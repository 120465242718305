import React from 'react'
import Background from "../../Images/rewardhomebanner.png"

const RewardHomeBanner = () => {
    return (
        <>
            <section className='RewardHome_Banner' style={{ backgroundImage: `url(${Background})` }}>
                <div className='contant_wrap'>
                    <h4 className='heading'>Magical reward points for your business promotions</h4>
                    <p className='text'>Exclusive rewards and privileges are provided to members</p>
                    <button className='benifits_btn'>Benifits</button>
                </div>
            </section>
        </>
    )
}

export default RewardHomeBanner