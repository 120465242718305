import React from 'react'
import "../ReferEarnDashboard/ReferEarnDashboard.css"
import ReferEarnHeader from './ReferEarnHeader'
import ReferFriendsEarnCredits from './ReferFriendsEarnCredits'
import AllEarningsBox from './AllEarningsBox'

const Index = () => {
    return (
        <>
            <div className='ReferEarn_dashboard'>
                <div className='cust_container'>
                    <ReferEarnHeader headname="Refer and Earn" backbtn= {false} />
                    <ReferFriendsEarnCredits />
                    <AllEarningsBox />
                </div>
            </div>
        </>
    )
}

export default Index