import React from 'react'
import WithdrawTopHeading from './WithdrawTopHeading'
import EarnedBalanceTransfer from './EarnedBalanceTransfer'
import EarnBalanceTransferDetails from './EarnBalanceTransferDetails'

const TotalEarned = () => {
    return (
        <>
            <div className='cust_container'>
                <WithdrawTopHeading />
                <EarnedBalanceTransfer />
                <EarnBalanceTransferDetails />
            </div>
        </>
    )
}

export default TotalEarned