import React from 'react'
import nochatimg from "../../Images/no-chat.png"

const NoChat = () => {
  return (
    <>
       <div className="right-container">
          {/*header */}
          <div className="header"></div>

          <div className="chat-container">
               <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: 'center'}}>
                    <figure style={{width: "100%", maxWidth: "250px", height: "auto",}}>
                        <img src={nochatimg} className='img-fluid' alt='...' style={{width: "100%", height: "100%"}}/>
                    </figure>
               </div>
          </div>
        </div>
    </>
  )
}

export default NoChat