import React from 'react'
import propic from "../../Images/profle.png";
import ReferEarnHeader2 from './ReferEarnHeader2';

const JoinedMembersPage = () => {
  const ProfileShare = [
    {
      id: 1,
      srclink: propic,
      name: "Rehmem Khihal",
      desc: "Eagle star Metalic furniture",
      text: 'Sharjah, United arab emirates',
      date: "Today 11 AM",
      amount: "$ 27.23",
    },
    {
      id: 2,
      srclink: propic,
      name: "Rehmem Khihal",
      desc: "Eagle star Metalic furniture",
      text: 'Sharjah, United arab emirates',
      date: "Today 11 AM",
      amount: "$ 27.23",
    },
    {
      id: 3,
      srclink: propic,
      name: "Rehmem Khihal",
      desc: "Eagle star Metalic furniture",
      text: 'Sharjah, United arab emirates',
      date: "Monday  11.05.2024  10 AM",
      amount: "$ 27.23",
    },
    {
      id: 4,
      srclink: propic,
      name: "Rehmem Khihal",
      desc: "Eagle star Metalic furniture",
      text: 'Sharjah, United arab emirates',
      date: "Today 11 AM",
      amount: "$ 27.23",
    },
  ];
  return (
    <>
      <div className='todayearnings_page'>
        <div className='cust_container'>
          <ReferEarnHeader2 headname="Joined Members" backbtn={true} monthdrowpdown={true} />

          <ul className='listwrap_all'>
            {ProfileShare.map((items, index) => (
              <li className='profiledetails_box' key={index}>
                <div className='left_area'>
                  <div className='profdetails'>
                    <div className='pro_pic'>
                      <img src={items.srclink} className='img-fluid' alt='..' />
                    </div>
                    <div className=''>
                      <h4 className='name'>{items.name}</h4>
                      <h6 className='desc'>{items.desc}</h6>
                      <p className='text'>{items.text}</p>
                    </div>
                  </div>
                  <div className='date'>
                    <p className='time'>{items.date}</p>
                  </div>
                </div>

                <div className='right_area'>
                  <h6 className='amount'>{items.amount}</h6>
                </div>
              </li>
            ))}
          </ul>

        </div>
      </div>
    </>
  )
}

export default JoinedMembersPage