import React, { useEffect } from "react";
import icon from "../../Images/vender/Offer stats.png";
import { Link } from "react-router-dom";
import ico from "../../Images/vender/views-w.png";
import ico2 from "../../Images/vender/issued.png";
import ico3 from "../../Images/vender/Redeemed.png";
import { useDispatch, useSelector } from "react-redux";
import {
  OfferViewDetails,
  ReddemVoucherList,
  VoucherIssuedList,
} from "../../Redux/Slice/Offer";

function OfferstatsVenderResponsive() {
  const boxcard = [
    { id: 1, src: ico, head: "Offer views", number: "100" },
    { id: 2, src: ico2, head: "Voucher issued", number: "200" },
    { id: 3, src: ico3, head: "Redeemed", number: "100" },
  ];

  const dispatch = useDispatch();
  const {
    VoucherIssuesDataButNotRedeemed,
    VoucherIsRedeemed,
    OfferViewData,
    offerStatsTABtype,
  } = useSelector((state) => state.offer);

  console.log(VoucherIsRedeemed, "VoucherRedeemedList");
  console.log(offerStatsTABtype, "offerStatsTABtype");

  useEffect(() => {
    dispatch(VoucherIssuedList());
    dispatch(ReddemVoucherList());
    dispatch(OfferViewDetails());
  }, []);
  return (
    <>
      <section className="OfferstatsVender_sec responsiveOfferstats">
        <div className="d-flex align-items-center justify-content-between toparea">
          <Link to="/vender-dashboard" className="backbutton_btn">
            <i className="fa-solid fa-arrow-left-long"></i>
          </Link>
          <div className="heading">
            <img src={icon} className="img-fluid" alt=".." />
            Offer stats
          </div>
          <div className="blank"></div>
        </div>

        <ul className="list">
          <li>
            <Link to="/offer-view">
              <div
                className={`box_wrap ${
                  offerStatsTABtype === "view" ? "active" : ""
                }`}
              >
                <div className="icon">
                  <img src={boxcard?.[0]?.src} className="img-fluid" alt=".." />
                </div>
                <div className="content">
                  <h6 className="head">{boxcard?.[0]?.head}</h6>
                  <p className="number">
                    <i class="fa-regular fa-eye"></i>
                    {OfferViewData?.length}
                  </p>
                </div>
              </div>{" "}
            </Link>
          </li>
          <li>
            <Link to="/offer-voucher-issued">
              <div
                className={`box_wrap ${
                  offerStatsTABtype === "issued" ? "active" : ""
                }`}
              >
                <div className="icon">
                  <img src={boxcard?.[1]?.src} className="img-fluid" alt=".." />
                </div>
                <div className="content">
                  <h6 className="head">{boxcard?.[1]?.head}</h6>
                  <p className="number">
                    <i class="fa-regular fa-eye"></i>
                    {VoucherIssuesDataButNotRedeemed?.length}
                  </p>
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/offer-voucher-redeemed">
              <div
                className={`box_wrap ${
                  offerStatsTABtype === "redeemed" ? "active" : ""
                }`}
              >
                <div className="icon">
                  <img src={boxcard?.[2]?.src} className="img-fluid" alt=".." />
                </div>
                <div className="content">
                  <h6 className="head">{boxcard?.[2]?.head}</h6>
                  <p className="number">
                    <i class="fa-regular fa-eye"></i>
                    {VoucherIsRedeemed?.length}
                  </p>
                </div>
              </div>
            </Link>
          </li>
        </ul>
      </section>
    </>
  );
}

export default OfferstatsVenderResponsive;
