import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRouter = () => {
    const userToken = localStorage.getItem("userDataNeoCard");

    if (userToken) {
        return <Outlet />
    } else {
        return <Navigate to={'/'} />
    }
}

export default PrivateRouter
