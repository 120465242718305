import React from 'react'
import "./Logedsearch.css";
import neofrontlogo from "../../Images/neofrontlogo.png";
import searchicon from "../../Images/searchicon.png"
const Logedsearch = () => {
    return (
        <>
            <section className='logedsearchmain'>
                <div className='cust_container'>
                    <div className='midownseacrh'>
                        <div className='midownseacrhmaindiv'>
                            <div className='midownseacrhlogodiv'>
                                <div className='neoofrontlogoimage'>
                                    <img src={neofrontlogo} alt='...' />
                                </div>
                            </div>
                            <div className='logedserchfrminpt'>
                                <input type='text' placeholder='Neoo search dubai' className='logedserchfrminptinpt' />
                                <div className='searchiconimg'>
                                    <img src={searchicon} alt='...' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Logedsearch
