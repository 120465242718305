import React from 'react'
import { Link } from 'react-router-dom'

const Viewersengagement = () => {
    return (
        <div className='viewsandebgagemntdiv '>
            <div className='viewsandebgagemntdivmain'>
                <table className='tavbedebieisdtldiv'>
                    <tr className='tavbedebieisdtldivtr'>
                        <td className='tavbedebieisdtldivtrd'>Profile viewers  </td>
                        <td className='tavbedebieisdtldivtrd'>
                            30
                        </td>
                    </tr>
                    <tr className='tavbedebieisdtldivtr'>
                        <td className='tavbedebieisdtldivtrd'>Follower engagement  </td>
                        <td className='tavbedebieisdtldivtrd'> 
                            60
                        </td>
                    </tr>
                </table>
                <div className='vuisrrcannsiittxtpmaindib'>
                    <Link className="vuisrrcannsiittxtp" to="/">View analytics</Link>
                </div>
            </div>
        </div>
    )
}

export default Viewersengagement
