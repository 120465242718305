import React, { useContext, useEffect, useState } from 'react'
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import directoryimage from "../../Images/directoryimage.png";
import mobilecopy from "../../Images/mobilecopy.png";
import Reward from "../../Images/Reward.png";
import network from "../../Images/network.png";
import infos from "../../Images/infos.png";
import visual from "../../Images/visual.png";
import post from "../../Images/post.png";
import social from "../../Images/social.png";
import chat from "../../Images/chat.png";
import brief from "../../Images/brief.png"
import { Link } from 'react-router-dom';
const Businessdirectory = () => {
    const colordata = useContext(Colorcontext);
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);
    return (
        <>
            <section className='searchloginmain'>
                <div className='cust_container'>
                    <div className='bg-wht'>
                        <div className='bannerloginbg'>
                            <img src={directoryimage} alt='...' />
                            <div className='bannerloginbgcontent'>
                                <h4 className='busineeheadparadirecrpara'>List your business in
                                    Neoo Search and grow fast</h4>
                                <p className='businesssubparaparasecrtstt'>
                                    Discover and evaluate top local companies by joining the
                                    fastest-growing  directory of small and large businesses.
                                </p>
                                <div className='freebusinessbtndibmaoin'>
                                    <Link className='freebusinessbtn' to="/searchlogin">Free Business Listing</Link>
                                </div>
                            </div>
                        </div>

                        <div className='businedirreidovmain'>
                            <p className='businedirreidovmainttxpp'>
                                Our extensive database of regional business listings helps you rank higher on search engine results pages while promoting your goods and brand. We have developed an online business directory that benefits both you and your clients by utilizing the finest aspects of social media, local SEO, and business directories.
                            </p>
                        </div>

                        <div className='businedirreidovmainnedpoing'>
                            <div className='businedirreidovmainnedpoingdibvlcx'>
                                <div className='mobilecopydivimage'>
                                    <img src={mobilecopy} alt='...' />
                                </div>

                                <div className='businedirreidovmainnedpoingdiright'>
                                    <div className='businedirreidovmainnedpoingdimain'>
                                        <h4 className='buiisconnesttxtppp'>Build contacts with clients</h4>
                                        <p className='sennjandrecetxtpp'>Send and receive enquiries</p>
                                    </div>
                                    <div className='businedirreidovmainnedpoingdimain'>
                                        <h4 className='buiisconnesttxtppp'>Manage your business profile</h4>
                                        <p className='sennjandrecetxtpp'>Update your product and services
                                            information and increase your online reach</p>
                                    </div>
                                    <div className='businedirreidovmainnedpoingdimain'>
                                        <h4 className='buiisconnesttxtppp'>Promote and connect your business</h4>
                                        <p className='sennjandrecetxtpp'>Showcase your products and
                                            services and reach out to your customers</p>
                                    </div>
                                    <div className='businedirreidovmainnedpoingdimain'>
                                        <h4 className='buiisconnesttxtppp'>Monitor your profile analytics</h4>
                                        <p className='sennjandrecetxtpp'>Send and receive enquiries</p>
                                    </div>
                                    <div className='businedirreidovmainnedpoingdimain'>
                                        <h4 className='buiisconnesttxtppp'>Build contacts with clients</h4>
                                        <p className='sennjandrecetxtpp'>Get performs report from analytics</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className='bg-whtdivnmanb'>
                        <div className=''>
                            <h4 className='makednekleosearchtxtpp'>
                                What makes Neoo Search
                                the best business directory?
                            </h4>

                            <p className='WhatmakesNeoosearchtxtp'>
                                What makes Neoo Search the best business directory? How simple it is to use and
                                locate the information you need! Neoo Search offers a space on the fastest-
                                growing online  small business directory for all kinds of companies.
                            </p>

                            <p className='WhatmakesNeoosearchtxtp'>
                                Neoo Search functions as the ideal local business directory, giving you a special
                                set of advantages in addition to the ability to search for businesses.
                            </p>
                        </div>
                        <div className='bg-whtdivnmanbdivmaimaindiv'>
                            <div className='bg-whtdivnmanbdivmaidfflex'>
                                <div className='bg-whtdivnmanbdivmaimdivfexrt'>
                                    <div className='rewarddibbabimgahsdimg'>
                                        <img src={brief} alt='...' />
                                    </div>
                                    <div className=''>
                                        <p className='Promotetxtpp'>
                                            Promote Your Local Business
                                        </p>
                                        <p className='freetetxtppp'>
                                            A free platform to enhance your online
                                            visibility and advertise your goods and services
                                        </p>
                                    </div>
                                </div>

                                <div className='bg-whtdivnmanbdivmaimdivfexrt'>
                                    <div className='rewarddibbabimgahsdimg'>
                                        <img src={network} alt='...' />
                                    </div>
                                    <div className=''>
                                        <p className='Promotetxtpp'>
                                            Build business Networking
                                        </p>
                                        <p className='freetetxtppp'>
                                            Establish connections with businesses
                                            and target customers.
                                        </p>
                                    </div>
                                </div>

                                <div className='bg-whtdivnmanbdivmaimdivfexrt'>
                                    <div className='rewarddibbabimgahsdimg'>
                                        <img src={infos} alt='...' />
                                    </div>
                                    <div className=''>
                                        <p className='Promotetxtpp'>
                                            Key Business Information
                                        </p>
                                        <p className='freetetxtppp'>
                                            Give your audience all the information they
                                            require about your business.
                                        </p>
                                    </div>
                                </div>

                                <div className='bg-whtdivnmanbdivmaimdivfexrt'>
                                    <div className='rewarddibbabimgahsdimg'>
                                        <img src={visual} alt='...' />
                                    </div>
                                    <div className=''>
                                        <p className='Promotetxtpp'>
                                            Visual Impact
                                        </p>
                                        <p className='freetetxtppp'>
                                            To keep your customers interested,
                                            incorporate videos and images
                                        </p>
                                    </div>
                                </div>


                                <div className='bg-whtdivnmanbdivmaimdivfexrt'>
                                    <div className='rewarddibbabimgahsdimg'>
                                        <img src={post} alt='...' />
                                    </div>
                                    <div className=''>
                                        <p className='Promotetxtpp'>
                                            Enquiry post
                                        </p>
                                        <p className='freetetxtppp'>

                                            Add enquiry posts to your listing to
                                            showcase your latest updates.
                                        </p>
                                    </div>
                                </div>

                                <div className='bg-whtdivnmanbdivmaimdivfexrt'>
                                    <div className='rewarddibbabimgahsdimg'>
                                        <img src={Reward} alt='...' />
                                    </div>
                                    <div className=''>
                                        <p className='Promotetxtpp'>
                                            Business rewards
                                        </p>
                                        <p className='freetetxtppp'>


                                            Magical reward points for your
                                            business promotions
                                        </p>
                                    </div>
                                </div>

                                <div className='bg-whtdivnmanbdivmaimdivfexrt'>
                                    <div className='rewarddibbabimgahsdimg'>
                                        <img src={social} alt='...' />
                                    </div>
                                    <div className=''>
                                        <p className='Promotetxtpp'>
                                            Social Media
                                        </p>
                                        <p className='freetetxtppp'>


                                            Include connections to your
                                            social media accounts.
                                        </p>
                                    </div>
                                </div>

                                <div className='bg-whtdivnmanbdivmaimdivfexrt'>
                                    <div className='rewarddibbabimgahsdimg'>
                                        <img src={chat} alt='...' />
                                    </div>
                                    <div className=''>
                                        <p className='Promotetxtpp'>
                                        Real time chat
                                        </p>
                                        <p className='freetetxtppp'>
                                        Improve customer’s 
                                        engagement and retention
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Businessdirectory
