import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Updatepostmoda from '../../Modal/Updatepostmoda'

const Blmpostadd = () => {
    const [ownpostupdatemodal,setownpostupdatemodal]=useState(false)
    const ownnsposyymodal = () => {
        setownpostupdatemodal(!ownpostupdatemodal)
    }
    return (
        <>
            <div className='bmlmmaposttaddddib'>
                <div className='bmlmmaposttaddddibdivmain'>
                    <p className='poiosyyattatxtpp'>
                        Post your latest product or service
                        to connect your customers.
                    </p>
                    <div className='poisyybtnmainlibkldivflx'>
                        <button className='poisyybtnmain' onClick={ownnsposyymodal}>
                            Post
                            <i className="fa-solid fa-plus"></i>
                        </button>
                        <div className=''>
                            <Link to="/ownpostpage" className="viewallpostlnk">View all posts</Link>
                        </div>
                    </div>
                </div>
            </div>
            {ownpostupdatemodal &&  <Updatepostmoda closemodal={setownpostupdatemodal}/>}
        </>
    )
}

export default Blmpostadd
