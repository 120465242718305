import React from 'react'
import { Link } from 'react-router-dom'

function ExchangedModal({ closePopup }) {
    const handlestopmodal = (event) => {
        event.stopPropagation()
    }
    return (
        <>
            <section className='exchangedModal_wrap' onClick={() => closePopup()}>
                <div className='exchangedModal_body' onClick={(event) => handlestopmodal(event)}>
                    <div className='content'>
                        <div className='icon'>
                            <i className="fa-solid fa-check"></i>
                        </div>

                        <h4 className='head'>Successfully completed !</h4>

                        <h6 className='sub_head'>Your contact information has been exchanged.</h6>
                        <p className='text'>Your login username and password will be sent to your registered email address.</p>
                    </div>

                    <Link to="/" className='gotoyourprofile_btn'>
                        Go to your profile
                        <span><i className="fa-solid fa-angle-right"></i></span>
                    </Link>

                </div>
            </section>
        </>
    )
}

export default ExchangedModal