import React, { useState } from 'react'
import "./MemberUserprofile.css";
import directory from "../../Images/Frame.png";
import like from "../../Images/framelike.png";
import whatsapp from "../../Images/whatsapp.png";
import call from "../../Images/Call.png";
import { Link } from 'react-router-dom';


const Memberuserprofile = () => {
    const [contactdetail, setContactdetail] = useState(true);
    const handledetailClick = () => {
        setContactdetail(!contactdetail)
    }
    return (
        <>
            <section className='memberuserprofile'>
                <div className='cust_container'>
                    <div className='contactbg'>
                        <div className='contctbrdr'>
                            <div className='cntntflx'>
                                <div className='mbrtusrprflflx'>
                                    <div className='imgbrdrmbrt'>
                                        {/* <img src={} alt="..." /> */}
                                    </div>
                                    <div className='jhjmbmrtdiv'>
                                        <p className='jhjtcfggytxt'>John Lewis</p>
                                        <p className='mghtxtpp'>Marketing Executive</p>
                                    </div>
                                </div>

                                <Link to={"/"} className='whatsppimg'>
                                    <img src={whatsapp} alt="..." />
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* <div className='contactbg'>
                        <div className="drctryDiv">
                            <div className="drctryDivLeft">
                                <div className='d-flex align-items-center'>
                                    <figure className='drctryDivFig'>
                                        <img src={like} alt="img" />
                                    </figure>
                                    <p>00</p>
                                </div>

                                <div className='d-flex align-items-center'>
                                    <figure className='drctryDivFig'>
                                        <img src={directory} alt="img" />
                                    </figure>
                                    <p>00</p>
                                </div>
                            </div>

                            <div className="drctryDivRght">

                                <figure className='drctrCntctIcon'>
                                    <img src={whatsapp} alt="img" />
                                </figure>

                                <figure className='drctrCntctIcon'>
                                    <img src={call} alt="img" />
                                </figure>
                            </div>
                        </div>
                    </div> */}

                    <div className='contactbg'>
                        <div className="cntntbrdr">
                            <div className='flxcntnt'>
                                <p className='cntncttxt'>Contact Details</p>
                                <div className='ArrwiconDiv' onClick={handledetailClick}>
                                    <span className={contactdetail ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></span>
                                </div>
                            </div>
                            {contactdetail && <div className=''>
                                <div className='cntntgdtls'>
                                    <p className='egltxtdiv'>Eagle Start</p>
                                    <p className='mdlfrntr'>Medical furniture</p>
                                    <p className='ajmtxt'>Ajman l United Arab Emirates</p>
                                </div>
                                <div className='divcnctflx'>
                                    <div className='brdrdivdtls'>
                                        <span className='txtdtlmn'>
                                            <i className="fa-solid fa-phone"></i>
                                            +971 6 7427316
                                        </span>
                                    </div>
                                    <div className='brdrdivdtls'>
                                        <span className='txtdtlmn'>
                                            <i className="fa-solid fa-mobile-screen-button"></i>
                                            +971 6 7427316
                                        </span>
                                    </div>
                                    <div className='brdrdivdtls'>
                                        <span className='txtdtlmn'>
                                            <i className="fa-brands fa-whatsapp"></i>
                                            +971 6 7427316
                                        </span>
                                    </div>
                                </div>
                                <div className='dibmailflx'>
                                    <div className='envelopdiv'>
                                        <span className='txtdtlmn'>
                                            <i className="fa-solid fa-envelope"></i>
                                            hamsascreativee@gmail.com
                                        </span>
                                    </div>
                                    <div className='envelopdiv'>
                                        <span className='txtdtlmn'>
                                            <i className="fa-solid fa-globe"></i>
                                            www.neoocard.com
                                        </span>
                                    </div>
                                </div>
                                <div className='divloctin'>
                                    <span className='txtdtlmn'>
                                        <i className="fa-solid fa-location-dot"></i>
                                        Business Center, Sharjah Publishing City Free Zone Dubai. United Arab Emirates
                                    </span>
                                </div>

                            </div>
                            }

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Memberuserprofile
