import React from 'react'
import nfcimg from "../../Images/explore/nfcimg.png"

const NFCbusinessCard = () => {
    return (
        <>
            <section className='NFCbusinessCard_sec'>
                <div className='cust_container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='nfcimg_wrap'>
                                <img src={nfcimg} className='img-fluid' alt='...' />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12 m-auto'>
                            <div className='content_'>
                                <p className='desc'>10 Reasons why you should consider using one reusable
                                    Neoo search NFC business card instead of using many
                                    traditional paper business cards:</p>
                                <ol>
                                    <li>Environmentally friendly</li>
                                    <li>Business profile</li>
                                    <li>Socialize</li>
                                    <li>Cost-effective</li>
                                    <li>Convenient</li>
                                    <li>Easy to update</li>
                                    <li>Easy to promote</li>
                                    <li>Interactive</li>
                                    <li>Professional Look</li>
                                    <li>Easy to store</li>
                                </ol>

                                <p className='desc2'>Neoo search NFC business cards and profile are the
                                    smart, sustainable, and logical technology
                                    alternative to traditional paper business cards.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NFCbusinessCard