import React from 'react'

function AboutNeooSearch() {
    return (
        <>
            <section className='aboutneoosearch_sec'>
                <div className='cust_container'>
                    <div className='toparea'>
                        <h4 className='headline'>About Neoo search</h4>
                    </div>

                    <div className='textarea'>
                        <p className='text'>Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies, drive more connections, and build new networks and leads. Neoo search business netwrok lets you discover and access a whole new world of business opportunities from across the world. Having a business of our own, we know the value of being able to share tips and information, develop relationships with other businesses, and have somewhere to look for information.</p>
                        <p className='text'>We have provided a wealth of information to help you market your business by letting you search for and access your target customers. The platform allows you to create unlimited opportunities that are visible to thousands of other companies in the world. You can also search for and contact other entrepreneurs in a safe and reliable environment. Besides, you can also use search tools to generate more leads with Neoo Search.</p>
                        
                        <h4 className='headline'>Promote your business</h4>
                        <p className='text'>Neoo Search assists you in promoting your business and services to a wider audience in the World. This business network also allows you to create unlimited and trusted business opportunities that are visible and accessible to thousands of other companies in the UAE.</p>
                    

                        <h4 className='headline'>Daily Stream of New Business Opportunities</h4>
                        <p className='text'>Being a Neoo Search member, you are able to access hundreds of fresh business opportunities from around the world effortlessly. Neoo Search is the fastest way to streamline your online business development by getting instant access to trustworthy leads, potential customers, suppliers, skills, and partnerships.</p>
                    </div>

                </div>
            </section>
        </>
    )
}

export default AboutNeooSearch