import React, { useState } from "react";
import { Link } from "react-router-dom";
import icn from "../../Images/gift-icon.png";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  OwnWalletDetails,
  RewardHistoryDetails,
} from "../../Redux/Slice/Offer";
import { getOwnProfile } from "../../Redux/Slice/PostSlice";

function RewardPointsSummary() {
  const dispatch = useDispatch();
  const { WalletData, RewardHistoryDetailsData } = useSelector(
    (state) => state.offer
  );
  const { UserProfileID } = useSelector((state) => state.app);
  const [MemberShipPoints, setMemberShipPoints] = useState(0);
  const [RedeemingPoints, setRedeemingPoints] = useState(0);
  const [GetPointSummary, setGetPointSummary] = useState(0);
  const [MemberAddPoint, setMemberAddPoint] = useState(0);
  const [NonMemberAddPoint, setNonMemberAddPoint] = useState(0);
  const [RedeemEarnedPoint, setRedeemEarnedPoint] = useState(0);

  const nonPurchaseWalletOffer = WalletData?.filter(
    (item) => !item?.voucherReedem
  );
  console.log(WalletData, "WalletData");
  console.log(RedeemingPoints, "wallet");
  console.log(UserProfileID, "UserProfileID");
  console.log(RewardHistoryDetailsData, "RewardHistoryDetailsData");
  console.log(GetPointSummary, "GetPointSummary");
  console.log(MemberAddPoint, "MemberAddPoint");

  useEffect(() => {
    if (WalletData) {
      setRedeemingPoints(
        WalletData?.reduce(
          (total, offer) => total + offer.Offer.reddemingPoints,
          0
        )
      );
    }
  }, [WalletData]);
  useEffect(() => {
    if (RewardHistoryDetailsData) {
      setMemberShipPoints(
        RewardHistoryDetailsData?.filter(
          (item, i) => item?.type == "memberAdd"
        )?.reduce((total, offer) => total + offer.points, 0)
      );
      setGetPointSummary(
        RewardHistoryDetailsData?.filter(
          (item, i) =>
            item?.type != "memberAdd" &&
            item?.type != "memSave" &&
            item?.type != "nonMemSave" &&
            item?.type != "redeemPoint"
        )?.reduce((total, offer) => total + offer.points, 0)
      );
      setMemberAddPoint(
        RewardHistoryDetailsData?.filter(
          (item, i) => item?.type == "memSave"
        )?.reduce((total, offer) => total + offer.points, 0)
      );
      setNonMemberAddPoint(
        RewardHistoryDetailsData?.filter(
          (item, i) => item?.type == "nonMemSave"
        )?.reduce((total, offer) => total + offer.points, 0)
      );
      setRedeemEarnedPoint(
        RewardHistoryDetailsData?.filter(
          (item, i) => item?.type == "redeemPoint"
        )?.reduce((total, offer) => total + offer.points, 0)
      );
    }
  }, [RewardHistoryDetailsData]);
  useEffect(() => {
    dispatch(OwnWalletDetails());
    dispatch(getOwnProfile());
    dispatch(RewardHistoryDetails());
  }, []);

  return (
    <>
      <section className="rewardpointssummary_sec">
        <div className="cust_container">
          <div className="top_area">
            <Link to="/reward" className="backbtn">
              <i className="fa-solid fa-arrow-left-long"></i>
            </Link>
            <h4 className="heading">Points Summary</h4>
            <Link to="/my-wallet">
              <div className="icon">
                <img src={icn} className="img-fluid" alt="....." />
                {nonPurchaseWalletOffer?.length > 0 && (
                  <span className="notification_count">
                    {nonPurchaseWalletOffer?.length}
                  </span>
                )}
              </div>
            </Link>
          </div>

          <div className="rewardpoint_table">
            <ul>
              <li>
                <h6 className="point">{UserProfileID?.rewardPoints} Points </h6>
                <p className="text">Your total earned points</p>
              </li>
              <li>
                <h6 className="point">{RedeemingPoints} Points </h6>
                <p className="text">Redeemed points</p>
              </li>
            </ul>
          </div>

          <div className="pointbreak_up">
            <h4 className="head">Your point break up</h4>

            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="d-flex">
                      <span className="icomimg1">
                        <i className="fa-solid fa-cart-shopping"></i>
                      </span>
                      <div>
                        <p>Neoo search Membership </p>
                        <span>Earned {MemberShipPoints} points</span>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    By purchasing the Neoo Search Business Pack, you become a
                    member of the Neoo Business Club and can earn 1500 points.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="d-flex">
                      <span className="icomimg1">
                        <i className="fa-solid fa-users"></i>
                      </span>
                      <div>
                        <p>Member to Member added points</p>
                        <span>Earned {MemberAddPoint} points</span>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    For each member you add to your Neoo directory, you will
                    earn 500 points each. which will help you bring your
                    business closer to your customers.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="d-flex">
                      <span className="icomimg1">
                        <i className="fa-solid fa-users-viewfinder"></i>
                      </span>
                      <div>
                        <p>Member to Non-member added points</p>
                        <span>Earned {NonMemberAddPoint} points</span>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    For each new business partner you add to your Neoo search
                    directory, you will earn 250 points each, which will help
                    you bring your business closer to your customers.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="d-flex">
                      <span className="icomimg1">
                        <i className="fa-solid fa-chart-line"></i>
                      </span>
                      <div>
                        <p>Neoo search profile activities</p>
                        <span>Earned {GetPointSummary} points</span>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Below are the rewards you can earn by visiting the profiles
                    of Neoo search members.
                  </p>
                  <ul>
                    <li>You can earn 250 points by liking each member.</li>
                    <li>Call through the neoo search profile: 50 points.</li>
                    <li>WhatsApp contact: 50 points.</li>
                    <li>Check website: 200 points.</li>
                    <li>Check social media links for each link: 100 points.</li>
                    <li>Visit products, fot each product view: 100 points.</li>
                    <li>Watch profile video: 150 points.</li>
                    <li>Download pdf profile: 200 points.</li>
                    <ul>
                      <li>Start 50 points</li>
                      <li>Starts 100 points</li>
                      <li>Stats 150 points</li>
                      <li>Stars 200 points</li>
                      <li>Starts 250 points</li>
                    </ul>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="d-flex">
                      <span className="icomimg1">
                        <i className="fa-solid fa-gift"></i>
                      </span>
                      <div>
                        <p>Redeem offers</p>
                        <span>Earned {RedeemEarnedPoint} points</span>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    You can earn 300 points back when you redeem each offer.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default RewardPointsSummary;
