import React, { useState } from 'react';
import crosssimgiocn from "../../Images/crosssimgiocn.png"
import Neoologoasjji from "../../Images/Neoologoasjji.png";
import postbanneimmhg from "../../Images/postbanneimmhg.png";
import prodlikeicon from "../../Images/prodlikeicon.png";
import commenytstproodiuificon from "../../Images/commenytstproodiuificon.png";
import msgiocn from "../../Images/msgiocn.png";
import Photography from "../../Images/Photography.png";
import logiiaihsui from "../../Images/logiiaihsui.png";
import cntnctimg3 from "../../Images/cntnctimg3.png";
import cmtprofiletwoimg from "../../Images/cmtprofiletwoimg.png"
import Ownpostpagemodal from '../../Modal/Ownpostpagemodal';
const Allpostownpage = () => {
    const [loadmtersdiumodal, setLoadmtersdiumodal] = useState(false);
    const [handleownpostmodal, sethandleownpostmodal] = useState(false)
    const loadmoresfgbtbnbclokj = () => {
        setLoadmtersdiumodal(!loadmtersdiumodal)
    }
    const [threedpotsspclmodal, setThreedpotsspclmodal] = useState(false);
    const [ownpostcommetclickmodal, setownpostcommetclickmodal] = useState(false)
    const threedotssettingclcil = () => {
        setThreedpotsspclmodal(!threedpotsspclmodal)
    }
    const ownposcommentclcik = () => {
        setownpostcommetclickmodal(!ownpostcommetclickmodal)
    }

    const handleopwmnpostmodal = () => {
        sethandleownpostmodal(!handleownpostmodal)
    }
    return (
        <>


            <div className='allpostowndmaimndivmain'>
                <div className='allpostowndmaimndiv' onClick={handleopwmnpostmodal}>
                    <div className='allpostowndmaimndivallelemnt'>
                        <div className='allpostimagedesuigfnatinob'>
                            <div className='allposttslodjjimg'>
                                <img src={Neoologoasjji} alt='...' />
                            </div>
                            <div className=''>
                                <h4 className='neeporoalposttsheadr'>Neoo Search FZE - Advertising & Marketing</h4>
                                <p className='cmskshhstdiifgysyttxtpop'> <span>Computer </span> |  <sapn> hardware </sapn> | <span>Digital marketing </span></p>
                            </div>
                        </div>
                        <div className='crosasddoproidikdsjbhidivflx'>
                            <div className='threediostyiicpnggsuc'>
                                <div className='threediostyiicpn' onClick={threedotssettingclcil}>
                                    <i className="fa-solid fa-ellipsis"></i>
                                </div>
                                {threedpotsspclmodal && <div className='threedotssettingdiv'>
                                    <ul className="userMenu">
                                        <li>
                                            <a href="/">
                                                <i className="fa-solid fa-link mr-2"></i>
                                                Copy post link
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/">

                                                <i className="fa-solid fa-share-nodes  mr-2"></i>
                                                Share post link
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/">

                                                <i className="fa-regular fa-bookmark mr-2"></i>
                                                Save post
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/">

                                                <i className="fa-solid fa-eye-slash mr-2"></i>
                                                Not interested
                                            </a>
                                        </li>
                                    </ul>
                                </div>}
                            </div>


                            <div className='crossidovpostimgdiv'>
                                <img src={crosssimgiocn} alt='...' />
                            </div>
                        </div>
                    </div>
                    <div className='postbannettimhdivflxmakflx'>
                        <div className='postbanneimmhgdivjakhjimh'>
                            <img src={postbanneimmhg} alt='...' />
                        </div>
                        <div className='poosttalouindtdettstctpopdoiv'>
                            <p className='postimagedesrcptoioponttxp'>
                                Neoo Search is a business network and online directory platbusiness with SME's and verified companies, drive more connections, and build new networks and leads. Neoo search business netwrok lets you discover and access a whole new world of business opportunities from
                            </p>
                        </div>
                    </div>
                    <div className=''>
                        <p className='htrrstrtdttgsgbtxttppgh'>3 hours ago</p>
                    </div>
                    <div className="allpostlikecommetsendenqeryudiv">
                        <div className="prodctlikecmntenquerypagediv">
                            <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                <div className="produucvtsdlikeinmncomnf">
                                    <img
                                        src={prodlikeicon}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">
                                    Like <span>100</span>
                                </p>
                            </div>

                            <div className="produucvtsdlikeinmncomnfdivvhjjd" onClick={ownposcommentclcik}>
                                <div className="produucvtsdlikeinmncomnf">
                                    <img
                                        src={commenytstproodiuificon}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">Comment </p>
                            </div>

                            {/* {ownpostcommetclickmodal &&
                                <div className='ownpostcommentdiv'>
                                    <div className='ownpostcommentdivmain'>
                                        <div className="cntnctimgdivnamin">
                                            <img src={cntnctimg3} alt='...' />
                                        </div>

                                        <div className='textareadibmain'>
                                            <textarea rows={4} cols={4} className='textareadibmainrerare' placeholder='Write a Comment' />
                                        </div>
                                    </div>
                                </div>
                            } */}


                            <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                <div className="produucvtsdlikeinmncomnf">
                                    <img
                                        src={msgiocn}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">Send Equiry </p>
                            </div>
                        </div>
                        {ownpostcommetclickmodal &&
                            <div className='ownpostcommentdiv'>
                                <div className='ownpostcommentdivmain'>
                                    <div className="cntnctimgdivnamin">
                                        <img src={cntnctimg3} alt='...' />
                                    </div>

                                    <div className='textareadibmainmainbrdr'>
                                        <div className='textareadibmain'>
                                            <textarea rows={4} cols={4} className='textareadibmainrerare' placeholder='Write a Comment' />
                                        </div>
                                        <div className="mesagsendidvmain">
                                            <i class="fa-solid fa-paper-plane"></i>
                                        </div>
                                    </div>

                                </div>

                                <div className='ownpostothercommentdivmain'>
                                    <div className='profikellcmnjhhrrddivflxttst'>
                                        <div className='profikellcmntimgdivflxttst'>
                                            <div className='commentproofilleimg'>
                                                <img src={cmtprofiletwoimg} alt='...' />
                                            </div>
                                            <p className='commporosttxttnamepp'>John Doe</p>
                                        </div>
                                        <p className='commporosttxttnamepp'>1 hour ago</p>
                                    </div>
                                    <p className='commsoprpodttctxppp'>
                                        I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                    </p>
                                </div>

                                <div className='ownpostothercommentdivmain'>
                                    <div className='profikellcmnjhhrrddivflxttst'>
                                        <div className='profikellcmntimgdivflxttst'>
                                            <div className='commentproofilleimg'>
                                                <img src={cmtprofiletwoimg} alt='...' />
                                            </div>
                                            <p className='commporosttxttnamepp'>John Doe</p>
                                        </div>
                                        <p className='commporosttxttnamepp'>1 hour ago</p>
                                    </div>
                                    <p className='commsoprpodttctxppp'>
                                        I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className='allpostowndmaimndiv'>
                    <div className='allpostowndmaimndivallelemnt'>
                        <div className='allpostimagedesuigfnatinob'>
                            <div className='allposttslodjjimg'>
                                <img src={logiiaihsui} alt='...' />
                            </div>
                            <div className=''>
                                <h4 className='neeporoalposttsheadr'>Eagle star furniture - Advertising & Marketing</h4>
                                <p className='cmskshhstdiifgysyttxtpop'> <span>Computer </span> |  <sapn> hardware </sapn> | <span>Digital marketing </span></p>
                            </div>
                        </div>
                        <div className='crosasddoproidikdsjbhidivflx'>
                            <div className='threediostyiicpn'>
                                <i className="fa-solid fa-ellipsis"></i>
                            </div>

                            <div className='crossidovpostimgdiv'>
                                <img src={crosssimgiocn} alt='...' />
                            </div>
                        </div>
                    </div>
                    <div className='postbannettimhdivflxmakflx'>
                        <div className='postbanneimmhgdivjakhjimh'>
                            <img src={Photography} alt='...' />
                        </div>
                        <div className='poosttalouindtdettstctpopdoiv'>
                            <p className='postimagedesrcptoioponttxp'>
                                Neoo Search is a business network and online directory platbusiness with SME's and verified companies, drive more connections, and build new networks and leads. Neoo search business netwrok lets you discover and access a whole new world of business opportunities from
                            </p>
                        </div>
                    </div>
                    <div className=''>
                        <p className='htrrstrtdttgsgbtxttppgh'>3 hours ago</p>
                    </div>
                    <div className="allpostlikecommetsendenqeryudiv">
                        <div className="prodctlikecmntenquerydiv">
                            <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                <div className="produucvtsdlikeinmncomnf" >
                                    <img
                                        src={prodlikeicon}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">
                                    Like <span>100</span>
                                </p>
                            </div>
                            <div className="produucvtsdlikeinmncomnfdivvhjjd" onClick={ownposcommentclcik}>
                                <div className="produucvtsdlikeinmncomnf">
                                    <img
                                        src={commenytstproodiuificon}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">Comment </p>
                            </div>
                            <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                <div className="produucvtsdlikeinmncomnf" >
                                    <img
                                        src={msgiocn}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">Send Equiry </p>
                            </div>
                        </div>

                        {ownpostcommetclickmodal &&
                            <div className='ownpostcommentdiv'>
                                <div className='ownpostcommentdivmain'>
                                    <div className="cntnctimgdivnamin">
                                        <img src={cntnctimg3} alt='...' />
                                    </div>

                                    <div className='textareadibmainmainbrdr'>
                                        <div className='textareadibmain'>
                                            <textarea rows={4} cols={4} className='textareadibmainrerare' placeholder='Write a Comment' />
                                        </div>
                                        <div className="mesagsendidvmain">
                                            <i class="fa-solid fa-paper-plane"></i>
                                        </div>
                                    </div>

                                </div>

                                <div className='ownpostothercommentdivmain'>
                                    <div className='profikellcmnjhhrrddivflxttst'>
                                        <div className='profikellcmntimgdivflxttst'>
                                            <div className='commentproofilleimg'>
                                                <img src={cmtprofiletwoimg} alt='...' />
                                            </div>
                                            <p className='commporosttxttnamepp'>John Doe</p>
                                        </div>
                                        <p className='commporosttxttnamepp'>1 hour ago</p>
                                    </div>
                                    <p className='commsoprpodttctxppp'>
                                        I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                    </p>
                                </div>

                                <div className='ownpostothercommentdivmain'>
                                    <div className='profikellcmnjhhrrddivflxttst'>
                                        <div className='profikellcmntimgdivflxttst'>
                                            <div className='commentproofilleimg'>
                                                <img src={cmtprofiletwoimg} alt='...' />
                                            </div>
                                            <p className='commporosttxttnamepp'>John Doe</p>
                                        </div>
                                        <p className='commporosttxttnamepp'>1 hour ago</p>
                                    </div>
                                    <p className='commsoprpodttctxppp'>
                                        I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className='allpostowndmaimndiv'>
                    <div className='allpostowndmaimndivallelemnt'>
                        <div className='allpostimagedesuigfnatinob'>
                            <div className='allposttslodjjimg'>
                                <img src={Neoologoasjji} alt='...' />
                            </div>
                            <div className=''>
                                <h4 className='neeporoalposttsheadr'>Neoo Search FZE - Advertising & Marketing</h4>
                                <p className='cmskshhstdiifgysyttxtpop'> <span>Computer </span> |  <sapn> hardware </sapn> | <span>Digital marketing </span></p>
                            </div>
                        </div>
                        <div className='crosasddoproidikdsjbhidivflx'>
                            <div className='threediostyiicpn'>
                                <i className="fa-solid fa-ellipsis"></i>
                            </div>

                            <div className='crossidovpostimgdiv'>
                                <img src={crosssimgiocn} alt='...' />
                            </div>
                        </div>
                    </div>
                    <div className='postbannettimhdivflxmakflx'>
                        <div className='postbanneimmhgdivjakhjimh'>
                            <img src={postbanneimmhg} alt='...' />
                        </div>
                        <div className='poosttalouindtdettstctpopdoiv'>
                            <p className='postimagedesrcptoioponttxp'>
                                Neoo Search is a business network and online directory platbusiness with SME's and verified companies, drive more connections, and build new networks and leads. Neoo search business netwrok lets you discover and access a whole new world of business opportunities from
                            </p>
                        </div>
                    </div>
                    <div className=''>
                        <p className='htrrstrtdttgsgbtxttppgh'>3 hours ago</p>
                    </div>
                    <div className="allpostlikecommetsendenqeryudiv">
                        <div className="prodctlikecmntenquerydiv">
                            <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                <div className="produucvtsdlikeinmncomnf">
                                    <img
                                        src={prodlikeicon}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">
                                    Like <span>100</span>
                                </p>
                            </div>
                            <div className="produucvtsdlikeinmncomnfdivvhjjd" onClick={ownposcommentclcik}>
                                <div className="produucvtsdlikeinmncomnf" >
                                    <img
                                        src={commenytstproodiuificon}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">Comment </p>
                            </div>
                            <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                <div className="produucvtsdlikeinmncomnf">
                                    <img
                                        src={msgiocn}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">Send Equiry </p>
                            </div>
                        </div>
                        {ownpostcommetclickmodal &&
                            <div className='ownpostcommentdiv'>
                                <div className='ownpostcommentdivmain'>
                                    <div className="cntnctimgdivnamin">
                                        <img src={cntnctimg3} alt='...' />
                                    </div>

                                    <div className='textareadibmainmainbrdr'>
                                        <div className='textareadibmain'>
                                            <textarea rows={4} cols={4} className='textareadibmainrerare' placeholder='Write a Comment' />
                                        </div>
                                        <div className="mesagsendidvmain">
                                            <i class="fa-solid fa-paper-plane"></i>
                                        </div>
                                    </div>

                                </div>

                                <div className='ownpostothercommentdivmain'>
                                    <div className='profikellcmnjhhrrddivflxttst'>
                                        <div className='profikellcmntimgdivflxttst'>
                                            <div className='commentproofilleimg'>
                                                <img src={cmtprofiletwoimg} alt='...' />
                                            </div>
                                            <p className='commporosttxttnamepp'>John Doe</p>
                                        </div>
                                        <p className='commporosttxttnamepp'>1 hour ago</p>
                                    </div>
                                    <p className='commsoprpodttctxppp'>
                                        I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                    </p>
                                </div>

                                <div className='ownpostothercommentdivmain'>
                                    <div className='profikellcmnjhhrrddivflxttst'>
                                        <div className='profikellcmntimgdivflxttst'>
                                            <div className='commentproofilleimg'>
                                                <img src={cmtprofiletwoimg} alt='...' />
                                            </div>
                                            <p className='commporosttxttnamepp'>John Doe</p>
                                        </div>
                                        <p className='commporosttxttnamepp'>1 hour ago</p>
                                    </div>
                                    <p className='commsoprpodttctxppp'>
                                        I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className='allpostowndmaimndiv'>
                    <div className='allpostowndmaimndivallelemnt'>
                        <div className='allpostimagedesuigfnatinob'>
                            <div className='allposttslodjjimg'>
                                <img src={logiiaihsui} alt='...' />
                            </div>
                            <div className=''>
                                <h4 className='neeporoalposttsheadr'>Eagle star furniture - Advertising & Marketing</h4>
                                <p className='cmskshhstdiifgysyttxtpop'> <span>Computer </span> |  <sapn> hardware </sapn> | <span>Digital marketing </span></p>
                            </div>
                        </div>
                        <div className='crosasddoproidikdsjbhidivflx'>
                            <div className='threediostyiicpn'>
                                <i className="fa-solid fa-ellipsis"></i>
                            </div>

                            <div className='crossidovpostimgdiv'>
                                <img src={crosssimgiocn} alt='...' />
                            </div>
                        </div>
                    </div>
                    <div className='postbannettimhdivflxmakflx'>
                        <div className='postbanneimmhgdivjakhjimh'>
                            <img src={Photography} alt='...' />
                        </div>
                        <div className='poosttalouindtdettstctpopdoiv'>
                            <p className='postimagedesrcptoioponttxp'>
                                Neoo Search is a business network and online directory platbusiness with SME's and verified companies, drive more connections, and build new networks and leads. Neoo search business netwrok lets you discover and access a whole new world of business opportunities from
                            </p>
                        </div>
                    </div>
                    <div className=''>
                        <p className='htrrstrtdttgsgbtxttppgh'>3 hours ago</p>
                    </div>
                    <div className="allpostlikecommetsendenqeryudiv">
                        <div className="prodctlikecmntenquerydiv">
                            <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                <div className="produucvtsdlikeinmncomnf">
                                    <img
                                        src={prodlikeicon}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">
                                    Like <span>100</span>
                                </p>
                            </div>
                            <div className="produucvtsdlikeinmncomnfdivvhjjd" onClick={ownposcommentclcik}>
                                <div className="produucvtsdlikeinmncomnf">
                                    <img
                                        src={commenytstproodiuificon}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">Comment </p>
                            </div>
                            <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                <div className="produucvtsdlikeinmncomnf">
                                    <img
                                        src={msgiocn}
                                        alt=""
                                    />
                                </div>
                                <p className="likkskjsbtcomnbvtyoipuxtt">Send Equiry </p>
                            </div>
                        </div>
                        {ownpostcommetclickmodal &&
                            <div className='ownpostcommentdiv'>
                                <div className='ownpostcommentdivmain'>
                                    <div className="cntnctimgdivnamin">
                                        <img src={cntnctimg3} alt='...' />
                                    </div>

                                    <div className='textareadibmainmainbrdr'>
                                        <div className='textareadibmain'>
                                            <textarea rows={4} cols={4} className='textareadibmainrerare' placeholder='Write a Comment' />
                                        </div>
                                        <div className="mesagsendidvmain">
                                            <i class="fa-solid fa-paper-plane"></i>
                                        </div>
                                    </div>

                                </div>

                                <div className='ownpostothercommentdivmain'>
                                    <div className='profikellcmnjhhrrddivflxttst'>
                                        <div className='profikellcmntimgdivflxttst'>
                                            <div className='commentproofilleimg'>
                                                <img src={cmtprofiletwoimg} alt='...' />
                                            </div>
                                            <p className='commporosttxttnamepp'>John Doe</p>
                                        </div>
                                        <p className='commporosttxttnamepp'>1 hour ago</p>
                                    </div>
                                    <p className='commsoprpodttctxppp'>
                                        I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                    </p>
                                </div>

                                <div className='ownpostothercommentdivmain'>
                                    <div className='profikellcmnjhhrrddivflxttst'>
                                        <div className='profikellcmntimgdivflxttst'>
                                            <div className='commentproofilleimg'>
                                                <img src={cmtprofiletwoimg} alt='...' />
                                            </div>
                                            <p className='commporosttxttnamepp'>John Doe</p>
                                        </div>
                                        <p className='commporosttxttnamepp'>1 hour ago</p>
                                    </div>
                                    <p className='commsoprpodttctxppp'>
                                        I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {loadmtersdiumodal && <div className=''>
                    <div className='allpostowndmaimndiv'>
                        <div className='allpostowndmaimndivallelemnt'>
                            <div className='allpostimagedesuigfnatinob'>
                                <div className='allposttslodjjimg'>
                                    <img src={Neoologoasjji} alt='...' />
                                </div>
                                <div className=''>
                                    <h4 className='neeporoalposttsheadr'>Neoo Search FZE - Advertising & Marketing</h4>
                                    <p className='cmskshhstdiifgysyttxtpop'> <span>Computer </span> |  <sapn> hardware </sapn> | <span>Digital marketing </span></p>
                                </div>
                            </div>
                            <div className='crosasddoproidikdsjbhidivflx'>
                                <div className='threediostyiicpn'>
                                    <i className="fa-solid fa-ellipsis"></i>
                                </div>

                                <div className='crossidovpostimgdiv'>
                                    <img src={crosssimgiocn} alt='...' />
                                </div>
                            </div>
                        </div>
                        <div className='postbannettimhdivflxmakflx'>
                            <div className='postbanneimmhgdivjakhjimh'>
                                <img src={postbanneimmhg} alt='...' />
                            </div>
                            <div className='poosttalouindtdettstctpopdoiv'>
                                <p className='postimagedesrcptoioponttxp'>
                                    Neoo Search is a business network and online directory platbusiness with SME's and verified companies, drive more connections, and build new networks and leads. Neoo search business netwrok lets you discover and access a whole new world of business opportunities from
                                </p>
                            </div>
                        </div>
                        <div className=''>
                            <p className='htrrstrtdttgsgbtxttppgh'>3 hours ago</p>
                        </div>
                        <div className="allpostlikecommetsendenqeryudiv">
                            <div className="prodctlikecmntenquerydiv">
                                <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                    <div className="produucvtsdlikeinmncomnf">
                                        <img
                                            src={prodlikeicon}
                                            alt=""
                                        />
                                    </div>
                                    <p className="likkskjsbtcomnbvtyoipuxtt">
                                        Like <span>100</span>
                                    </p>
                                </div>
                                <div className="produucvtsdlikeinmncomnfdivvhjjd" onClick={ownposcommentclcik} >
                                    <div className="produucvtsdlikeinmncomnf">
                                        <img
                                            src={commenytstproodiuificon}
                                            alt=""
                                        />
                                    </div>
                                    <p className="likkskjsbtcomnbvtyoipuxtt">Comment </p>
                                </div>
                                <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                    <div className="produucvtsdlikeinmncomnf">
                                        <img
                                            src={msgiocn}
                                            alt=""
                                        />
                                    </div>
                                    <p className="likkskjsbtcomnbvtyoipuxtt">Send Equiry </p>
                                </div>
                            </div>

                            {ownpostcommetclickmodal &&
                                <div className='ownpostcommentdiv'>
                                    <div className='ownpostcommentdivmain'>
                                        <div className="cntnctimgdivnamin">
                                            <img src={cntnctimg3} alt='...' />
                                        </div>

                                        <div className='textareadibmainmainbrdr'>
                                            <div className='textareadibmain'>
                                                <textarea rows={4} cols={4} className='textareadibmainrerare' placeholder='Write a Comment' />
                                            </div>
                                            <div className="mesagsendidvmain">
                                                <i class="fa-solid fa-paper-plane"></i>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='ownpostothercommentdivmain'>
                                        <div className='profikellcmnjhhrrddivflxttst'>
                                            <div className='profikellcmntimgdivflxttst'>
                                                <div className='commentproofilleimg'>
                                                    <img src={cmtprofiletwoimg} alt='...' />
                                                </div>
                                                <p className='commporosttxttnamepp'>John Doe</p>
                                            </div>
                                            <p className='commporosttxttnamepp'>1 hour ago</p>
                                        </div>
                                        <p className='commsoprpodttctxppp'>
                                            I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                        </p>
                                    </div>

                                    <div className='ownpostothercommentdivmain'>
                                        <div className='profikellcmnjhhrrddivflxttst'>
                                            <div className='profikellcmntimgdivflxttst'>
                                                <div className='commentproofilleimg'>
                                                    <img src={cmtprofiletwoimg} alt='...' />
                                                </div>
                                                <p className='commporosttxttnamepp'>John Doe</p>
                                            </div>
                                            <p className='commporosttxttnamepp'>1 hour ago</p>
                                        </div>
                                        <p className='commsoprpodttctxppp'>
                                            I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='allpostowndmaimndiv'>
                        <div className='allpostowndmaimndivallelemnt'>
                            <div className='allpostimagedesuigfnatinob'>
                                <div className='allposttslodjjimg'>
                                    <img src={logiiaihsui} alt='...' />
                                </div>
                                <div className=''>
                                    <h4 className='neeporoalposttsheadr'>Eagle star furniture - Advertising & Marketing</h4>
                                    <p className='cmskshhstdiifgysyttxtpop'> <span>Computer </span> |  <sapn> hardware </sapn> | <span>Digital marketing </span></p>
                                </div>
                            </div>
                            <div className='crosasddoproidikdsjbhidivflx'>
                                <div className='threediostyiicpn'>
                                    <i className="fa-solid fa-ellipsis"></i>
                                </div>

                                <div className='crossidovpostimgdiv'>
                                    <img src={crosssimgiocn} alt='...' />
                                </div>
                            </div>
                        </div>
                        <div className='postbannettimhdivflxmakflx'>
                            <div className='postbanneimmhgdivjakhjimh'>
                                <img src={Photography} alt='...' />
                            </div>
                            <div className='poosttalouindtdettstctpopdoiv'>
                                <p className='postimagedesrcptoioponttxp'>
                                    Neoo Search is a business network and online directory platbusiness with SME's and verified companies, drive more connections, and build new networks and leads. Neoo search business netwrok lets you discover and access a whole new world of business opportunities from
                                </p>
                            </div>
                        </div>
                        <div className=''>
                            <p className='htrrstrtdttgsgbtxttppgh'>3 hours ago</p>
                        </div>
                        <div className="allpostlikecommetsendenqeryudiv">
                            <div className="prodctlikecmntenquerydiv">
                                <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                    <div className="produucvtsdlikeinmncomnf">
                                        <img
                                            src={prodlikeicon}
                                            alt=""
                                        />
                                    </div>
                                    <p className="likkskjsbtcomnbvtyoipuxtt">
                                        Like <span>100</span>
                                    </p>
                                </div>
                                <div className="produucvtsdlikeinmncomnfdivvhjjd" onClick={ownposcommentclcik}>
                                    <div className="produucvtsdlikeinmncomnf">
                                        <img
                                            src={commenytstproodiuificon}
                                            alt=""
                                        />
                                    </div>
                                    <p className="likkskjsbtcomnbvtyoipuxtt">Comment </p>
                                </div>
                                <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                    <div className="produucvtsdlikeinmncomnf">
                                        <img
                                            src={msgiocn}
                                            alt=""
                                        />
                                    </div>
                                    <p className="likkskjsbtcomnbvtyoipuxtt">Send Equiry </p>
                                </div>
                            </div>
                            {ownpostcommetclickmodal &&
                                <div className='ownpostcommentdiv'>
                                    <div className='ownpostcommentdivmain'>
                                        <div className="cntnctimgdivnamin">
                                            <img src={cntnctimg3} alt='...' />
                                        </div>

                                        <div className='textareadibmainmainbrdr'>
                                            <div className='textareadibmain'>
                                                <textarea rows={4} cols={4} className='textareadibmainrerare' placeholder='Write a Comment' />
                                            </div>
                                            <div className="mesagsendidvmain">
                                                <i class="fa-solid fa-paper-plane"></i>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='ownpostothercommentdivmain'>
                                        <div className='profikellcmnjhhrrddivflxttst'>
                                            <div className='profikellcmntimgdivflxttst'>
                                                <div className='commentproofilleimg'>
                                                    <img src={cmtprofiletwoimg} alt='...' />
                                                </div>
                                                <p className='commporosttxttnamepp'>John Doe</p>
                                            </div>
                                            <p className='commporosttxttnamepp'>1 hour ago</p>
                                        </div>
                                        <p className='commsoprpodttctxppp'>
                                            I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                        </p>
                                    </div>

                                    <div className='ownpostothercommentdivmain'>
                                        <div className='profikellcmnjhhrrddivflxttst'>
                                            <div className='profikellcmntimgdivflxttst'>
                                                <div className='commentproofilleimg'>
                                                    <img src={cmtprofiletwoimg} alt='...' />
                                                </div>
                                                <p className='commporosttxttnamepp'>John Doe</p>
                                            </div>
                                            <p className='commporosttxttnamepp'>1 hour ago</p>
                                        </div>
                                        <p className='commsoprpodttctxppp'>
                                            I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                }

                <div className='lodmrebtnmainndivmaindib'>
                    <button className='lodmrebtnmainndiv' onClick={loadmoresfgbtbnbclokj}>
                        Load More
                    </button>
                </div>



            </div>

            {handleownpostmodal && <Ownpostpagemodal closemodal={sethandleownpostmodal}/>}
        </>
    )
}

export default Allpostownpage
